import { Component, Input } from '@angular/core';
import { FormControlError } from '../../../utils/types';

@Component({
  selector: 'listo-error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.scss'],
})
export class ErrorLabelComponent {
  @Input() error: FormControlError;
}
