import { environment } from '../environments/environment';
import { AutoRaterSteps } from './types';

const ENV = environment.env;

export const PERFORMANCE_LABEL = 'Desempeño';
export const MY_PERFORMANCE_LABEL = 'Mi desempeño';
export const STORE_PERFORMANCE_LABEL = 'Desempeño tienda';
export const TOOLS_LABEL = 'Herramientas';
export const PROFILE_LABEL = 'Perfil';
export const AUTO_INSURANCE_DASHBOARD_LABEL = 'Seguro de autos';
export const AURA_PASSWORD_NOTIFICATION = 'changeAuraPassword';
export const SEGMENT_API_KEY = '3K5AnU0SRXb2Nhbho69WvVLYn2UuWxQd';
export const AUTO_RATER_URL = {
  dev: 'http://local.listofin.com:3001',
  staging: 'https://auto.eje11.com',
  prod: 'https://segurodeauto.listofin.com',
}[ENV];
export const LIFE_RATER_URL = {
  dev: 'http://local.listofin.com:3003',
  staging: 'https://life.eje11.com',
  prod: 'https://segurodevida.listofin.com',
}[ENV];
export const LOAN_RATER_URL = {
  dev: 'http://local.listofin.com:3002',
  staging: 'http://prestamo.eje11.com',
  prod: 'https://prestamo.listofin.com',
}[ENV];
export const ADMIN_TOOLS_LABEL = 'Administrativas';
export const AUTO_TOOLS_LABEL = 'Seguros de Auto';
export const LOANS_TOOLS_LABEL = 'Préstamos';
export const LIFE_TOOLS_LABEL = 'Seguros de vida';
export const EXPANDED_RATER_LABEL = 'Cotizador';
export const AUTO_RATER_LABEL = 'Rapidito';
export const LOAN_TOOL_LABEL = 'Precalificador';
export const LIFE_TOOL_LABEL = 'Rapidito';
export const MY_LINKS_LABEL = 'Mis links';
export const OTHER_INSURANCES_TOOLS_LABEL = 'Otros seguros';
export const PAYMENTS_TOOLS_LABEL = 'Pagos';
export const SALES_TOOLS_LABEL = 'Registro de venta';
export const RELATED_DRIVERS = 'Conductores relacionados';
export const AGENT_LINKS = [
  {
    label: 'Seguros de auto',
    url: AUTO_RATER_URL,
  },
  {
    label: 'Préstamos',
    url: LOAN_RATER_URL,
  },
  {
    label: 'Seguros de vida',
    url: LIFE_RATER_URL,
  },
];
export const INDUSTRIES = [
  {
    value: '01',
    label: 'Amo o Ama de Casa(tiempo completo)',
  },
  {
    value: '02',
    label: 'Retirado(tiempo completo)',
  },
  {
    value: '03',
    label: 'Sin Empleo',
  },
  {
    value: '04',
    label: 'Estudiante(tiempo completo)',
  },
  {
    value: 'AA',
    label: 'Agricultura/Silvicultura/Pesca',
  },
  {
    value: 'AB',
    label: 'Arte/Diseño/Medios de Comunicación',
  },
  {
    value: 'AC',
    label: 'Bancario/Finanzas/Bienes Raíces',
  },
  {
    value: 'AD',
    label: 'Negocios/Ventas/Oficina',
  },
  {
    value: 'AE',
    label: 'Construcción/Energía/Minas',
  },
  {
    value: 'AF',
    label: 'Educación/Biblioteca',
  },
  {
    value: 'AG',
    label: 'Ingeniería/Arquitectura/Ciencia/Matemáticas',
  },
  {
    value: 'AH',
    label: 'Servicio de Comida/Servicio de Hoteles',
  },
  {
    value: 'AJ',
    label: 'Gobierno/Militar',
  },
  {
    value: 'AK',
    label: 'Tecnología Informatica',
  },
  {
    value: 'AL',
    label: 'Seguros',
  },
  {
    value: 'AM',
    label: 'Leyes/Cumplimiento de la Ley/Seguridad',
  },
  {
    value: 'AN',
    label: 'Medicina/Servicios Sociales/Religión',
  },
  {
    value: 'AP',
    label: 'Cuidado Personal/Servicio',
  },
  {
    value: 'AQ',
    label: 'Producción/Manufactura',
  },
  {
    value: 'AR',
    label: 'Reparación/Mantenimiento/Jardines',
  },
  {
    value: 'AS',
    label: 'Deportes/Recreación',
  },
  {
    value: 'AT',
    label: 'Viajes/Transportación/Almacenamiento',
  },
];
export const OCCUPATIONS = [
  {
    value: '010',
    label: 'Amo o Ama de Casas(tiempo completo)',
  },
  {
    value: '020',
    label: 'Retirado(tiempo completo)',
  },
  {
    value: '030',
    label: 'Sin Empleo',
  },
  {
    value: '040',
    label: 'Estudiante(tiempo completo)',
  },
  {
    value: 'AA0',
    label: 'Inspector/Calificador Agricultural',
  },
  {
    value: 'AA1',
    label: 'Arborista',
  },
  {
    value: 'AA2',
    label: 'Empleado',
  },
  {
    value: 'AA3',
    label: 'Operador de Equipo',
  },
  {
    value: 'AA4',
    label: 'Dueño de Granja/Rancho',
  },
  {
    value: 'AA5',
    label: 'Trabajador de Granja/Rancho',
  },
  {
    value: 'AA6',
    label: 'Pescador',
  },
  {
    value: 'AA7',
    label: 'Florista',
  },
  {
    value: 'AA8',
    label: 'Obrero/Trabajador',
  },
  {
    value: 'AA9',
    label: 'Paisajista',
  },
  {
    value: 'AAA',
    label: 'Trabajador de Paisajismo/Guardería',
  },
  {
    value: 'AAB',
    label: 'Leñador',
  },
  {
    value: 'AAC',
    label: 'Trabajador de Molino',
  },
  {
    value: 'AAD',
    label: 'Guardabosques',
  },
  {
    value: 'AAE',
    label: 'Supervisor',
  },
  {
    value: 'AAF',
    label: 'Escalador/Calificador de Madera',
  },
  {
    value: 'AAZ',
    label: 'Otro',
  },
  {
    value: 'AB0',
    label: 'Actor',
  },
  {
    value: 'AB1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AB2',
    label: 'Locutor',
  },
  {
    value: 'AB3',
    label: 'Artista/Animador',
  },
  {
    value: 'AB4',
    label: 'Autor/Escritor',
  },
  {
    value: 'AB5',
    label: 'Coreografía/Bailarín',
  },
  {
    value: 'AB6',
    label: 'Empleado',
  },
  {
    value: 'AB7',
    label: 'Compositor/Director',
  },
  {
    value: 'AB8',
    label: 'Curador',
  },
  {
    value: 'AB9',
    label: 'Diseñador',
  },
  {
    value: 'ABA',
    label: 'Editor',
  },
  {
    value: 'ABB',
    label: 'Periodista/Reportero',
  },
  {
    value: 'ABC',
    label: 'Músico/Cantante',
  },
  {
    value: 'ABD',
    label: 'Fotógrafo',
  },
  {
    value: 'ABE',
    label: 'Impresor',
  },
  {
    value: 'ABF',
    label: 'Productor',
  },
  {
    value: 'ABG',
    label: 'Equipo de Producción',
  },
  {
    value: 'ABH',
    label: 'Operador de Cine',
  },
  {
    value: 'ABJ',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ABK',
    label: 'Venta de Boletos/Ujier',
  },
  {
    value: 'ABZ',
    label: 'Otro',
  },
  {
    value: 'AC0',
    label: 'Contador/Auditor',
  },
  {
    value: 'AC1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AC2',
    label: 'Analista',
  },
  {
    value: 'AC3',
    label: 'Tasador-Bienes Raíces',
  },
  {
    value: 'AC4',
    label: 'Contable',
  },
  {
    value: 'AC5',
    label: 'Corredor',
  },
  {
    value: 'AC6',
    label: 'Gerente de Sucursal',
  },
  {
    value: 'AC7',
    label: 'Empleado',
  },
  {
    value: 'AC8',
    label: 'Colecciones',
  },
  {
    value: 'AC9',
    label: 'Consultor',
  },
  {
    value: 'ACA',
    label: 'Controlador',
  },
  {
    value: 'ACB',
    label: 'Representante del Servicio al Cliente/Cajero',
  },
  {
    value: 'ACC',
    label: 'Director/Administrador',
  },
  {
    value: 'ACD',
    label: 'Ejecutivo',
  },
  {
    value: 'ACE',
    label: 'Asesor Financiero',
  },
  {
    value: 'ACF',
    label: 'Banquero de Inversión',
  },
  {
    value: 'ACG',
    label: 'Inversor',
  },
  {
    value: 'ACH',
    label: 'Procesador de Préstamo/Garantía',
  },
  {
    value: 'ACJ',
    label: 'Gerente-Crédito/Préstamo',
  },
  {
    value: 'ACK',
    label: 'Gerente-Portafolio/Producción',
  },
  {
    value: 'ACL',
    label: 'Gerente-Propiedad',
  },
  {
    value: 'ACM',
    label: 'Corredor de Bienes Raíces',
  },
  {
    value: 'ACN',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ACP',
    label: 'Agente de Ventas/Representante',
  },
  {
    value: 'ACQ',
    label: 'Mecanismos Financieros del Comerciante',
  },
  {
    value: 'ACR',
    label: 'Asegurador',
  },
  {
    value: 'ACZ',
    label: 'Otro',
  },
  {
    value: 'AD0',
    label: 'Ejecutivo de Cuenta',
  },
  {
    value: 'AD1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AD2',
    label: 'Comprador',
  },
  {
    value: 'AD3',
    label: 'Cajero/Inspector',
  },
  {
    value: 'AD4',
    label: 'Empleado-Oficina',
  },
  {
    value: 'AD5',
    label: 'Consultor',
  },
  {
    value: 'AD6',
    label: 'Representante de Servicio al Cliente',
  },
  {
    value: 'AD7',
    label: 'Director/Administrador',
  },
  {
    value: 'AD8',
    label: 'Ejecutivo',
  },
  {
    value: 'AD9',
    label: 'Representante de R.R.H.H.',
  },
  {
    value: 'ADA',
    label: 'Gerente-Departamento/Tienda',
  },
  {
    value: 'ADB',
    label: 'Gerente-Distrito',
  },
  {
    value: 'ADC',
    label: 'Gerente-Finanzas/Aseguranzas',
  },
  {
    value: 'ADD',
    label: 'Gerente-Operaciones Generales',
  },
  {
    value: 'ADE',
    label: 'Gerente-R.R.H.H./Relaciones Publicas',
  },
  {
    value: 'ADF',
    label: 'Gerente-Marketing/Ventas',
  },
  {
    value: 'ADG',
    label: 'Gerente/Supervisor-Oficina',
  },
  {
    value: 'ADH',
    label: 'Investigador de Marketing',
  },
  {
    value: 'ADJ',
    label: 'Mensajero',
  },
  {
    value: 'ADK',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ADL',
    label: 'Ventas-Mostrador/Alquiler',
  },
  {
    value: 'ADM',
    label: 'Ventas-Desde Casa',
  },
  {
    value: 'ADN',
    label: 'Ventas-Representativo de Manufactura',
  },
  {
    value: 'ADP',
    label: 'Ventas-Al por Menor/por Mayor',
  },
  {
    value: 'ADQ',
    label: 'Ventas-Ruta/Vendedor',
  },
  {
    value: 'ADZ',
    label: 'Otro',
  },
  {
    value: 'AE0',
    label: 'Tasador-Bienes Raíces',
  },
  {
    value: 'AE1',
    label: 'Fabricante/Operador de Calderas',
  },
  {
    value: 'AE2',
    label: 'Albañil/Masón',
  },
  {
    value: 'AE3',
    label: 'Carpintero',
  },
  {
    value: 'AE4',
    label: 'Instalador de Alfombras',
  },
  {
    value: 'AE5',
    label: 'Trabajador de Concreto',
  },
  {
    value: 'AE6',
    label: 'Construcción-Proyecto Gerente',
  },
  {
    value: 'AE7',
    label: 'Trabajador de Construcción',
  },
  {
    value: 'AE8',
    label: 'Contratista',
  },
  {
    value: 'AE9',
    label: 'Operador de Grúa',
  },
  {
    value: 'AEA',
    label: 'Electricista/Liniero',
  },
  {
    value: 'AEB',
    label: 'Técnico/Instalador de Ascensores',
  },
  {
    value: 'AEC',
    label: 'Operador de Equipo',
  },
  {
    value: 'AED',
    label: 'Instalador/Acabador de Pisos',
  },
  {
    value: 'AEE',
    label: 'Capataz/Supervisor',
  },
  {
    value: 'AEF',
    label: 'Personal de Mantenimiento',
  },
  {
    value: 'AEG',
    label: 'Tecnico de Aire Acondicionado/Calefacción',
  },
  {
    value: 'AEH',
    label: 'Inspector',
  },
  {
    value: 'AEJ',
    label: 'Obrero/Trabajador',
  },
  {
    value: 'AEK',
    label: 'Obrero Metalúrgico',
  },
  {
    value: 'AEL',
    label: 'Minero',
  },
  {
    value: 'AEM',
    label: 'Perforador de petróleo/gas/Operador de Plataforma',
  },
  {
    value: 'AEN',
    label: 'Pintor',
  },
  {
    value: 'AEP',
    label: 'Yeso/Drywall/Estuco',
  },
  {
    value: 'AEQ',
    label: 'Plomero',
  },
  {
    value: 'AER',
    label: 'Techador',
  },
  {
    value: 'AES',
    label: 'Trabajador de Utilidades',
  },
  {
    value: 'AET',
    label: 'Soldador',
  },
  {
    value: 'AEZ',
    label: 'Otro',
  },
  {
    value: 'AF0',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AF1',
    label: 'Técnico Audio-Visual',
  },
  {
    value: 'AF2',
    label: 'Trabajador de Guardería',
  },
  {
    value: 'AF3',
    label: 'Empleado',
  },
  {
    value: 'AF4',
    label: 'Consejero',
  },
  {
    value: 'AF5',
    label: 'Asistentes Docentes Graduados',
  },
  {
    value: 'AF6',
    label: 'Instructor-Vocacional',
  },
  {
    value: 'AF7',
    label: 'Bibliotecario/Curador',
  },
  {
    value: 'AF8',
    label: 'Director',
  },
  {
    value: 'AF9',
    label: 'Profesor de Universidad',
  },
  {
    value: 'AFA',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'AFB',
    label: 'Superintendente',
  },
  {
    value: 'AFC',
    label: 'Instructor de Universidad',
  },
  {
    value: 'AFD',
    label: 'Profesor de K-12',
  },
  {
    value: 'AFE',
    label: 'Asistente/Ayudante de Profesor',
  },
  {
    value: 'AFF',
    label: 'Tutor',
  },
  {
    value: 'AFZ',
    label: 'Otro',
  },
  {
    value: 'AG0',
    label: 'Actuario',
  },
  {
    value: 'AG1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AG2',
    label: 'Analista',
  },
  {
    value: 'AG3',
    label: 'Arquitecto',
  },
  {
    value: 'AG4',
    label: 'Químico',
  },
  {
    value: 'AG5',
    label: 'Empleado',
  },
  {
    value: 'AG6',
    label: 'Coordinador de Información Clínica',
  },
  {
    value: 'AG7',
    label: 'Delineante',
  },
  {
    value: 'AG8',
    label: 'Ingeniero',
  },
  {
    value: 'AG9',
    label: 'Asistente de Laboratorio',
  },
  {
    value: 'AGA',
    label: 'Gerente-Proyecto',
  },
  {
    value: 'AGB',
    label: 'Gerente-Investigación y Desarrollo',
  },
  {
    value: 'AGC',
    label: 'Matemático',
  },
  {
    value: 'AGD',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'AGE',
    label: 'Director de Programa de Investigación',
  },
  {
    value: 'AGF',
    label: 'Investigador',
  },
  {
    value: 'AGG',
    label: 'Científico',
  },
  {
    value: 'AGH',
    label: 'Sociólogo',
  },
  {
    value: 'AGJ',
    label: 'Topógrafo/Cartógrafo',
  },
  {
    value: 'AGK',
    label: 'Técnico',
  },
  {
    value: 'AGZ',
    label: 'Otro',
  },
  {
    value: 'AH0',
    label: 'Panadero',
  },
  {
    value: 'AH1',
    label: 'Barman',
  },
  {
    value: 'AH2',
    label: 'Botones',
  },
  {
    value: 'AH3',
    label: 'Ayudante de Camarero',
  },
  {
    value: 'AH4',
    label: 'Caterer',
  },
  {
    value: 'AH5',
    label: 'Chef',
  },
  {
    value: 'AH6',
    label: 'Concesionario',
  },
  {
    value: 'AH7',
    label: 'Conserje',
  },
  {
    value: 'AH8',
    label: 'Cocinero-Restaurante/Cafeteria',
  },
  {
    value: 'AH9',
    label: 'Cocinero/Trabajador-Comida Rápida',
  },
  {
    value: 'AHA',
    label: 'Repartidor',
  },
  {
    value: 'AHB',
    label: 'Empleado de Oficina',
  },
  {
    value: 'AHC',
    label: 'Lavaplatos',
  },
  {
    value: 'AHD',
    label: 'Producción/Embalaje de Comida',
  },
  {
    value: 'AHE',
    label: 'Jefe de comedor',
  },
  {
    value: 'AHF',
    label: 'Mucamo o Mucama',
  },
  {
    value: 'AHG',
    label: 'Gerente',
  },
  {
    value: 'AHH',
    label: 'Valet',
  },
  {
    value: 'AHJ',
    label: 'Mesero/Mesera',
  },
  {
    value: 'AHK',
    label: 'Sumiller',
  },
  {
    value: 'AHZ',
    label: 'Otro',
  },
  {
    value: 'AJ0',
    label: 'Contador/Auditor',
  },
  {
    value: 'AJ1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AJ2',
    label: 'Analista',
  },
  {
    value: 'AJ3',
    label: 'Abogado',
  },
  {
    value: 'AJ4',
    label: 'Ejecutivo a Cargo',
  },
  {
    value: 'AJ5',
    label: 'Empleado',
  },
  {
    value: 'AJ6',
    label: 'Notario',
  },
  {
    value: 'AJ7',
    label: 'Miembro del Consejo',
  },
  {
    value: 'AJ8',
    label: 'Director/Administrador',
  },
  {
    value: 'AJ9',
    label: 'Personal Militar Alistado(E1-E4)',
  },
  {
    value: 'AJA',
    label: 'Legislador',
  },
  {
    value: 'AJB',
    label: 'Alcalde/Administrador de la Ciudad',
  },
  {
    value: 'AJC',
    label: 'Lector de Medidores',
  },
  {
    value: 'AJD',
    label: 'Oficial No-Comisionado (E5-9)',
  },
  {
    value: 'AJE',
    label: 'Oficial Comisionado',
  },
  {
    value: 'AJF',
    label: 'Suboficial',
  },
  {
    value: 'AJG',
    label: 'Guardabosques',
  },
  {
    value: 'AJH',
    label: 'Planificador',
  },
  {
    value: 'AJJ',
    label: 'Administrador de Correos',
  },
  {
    value: 'AJK',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'AJL',
    label: 'Regulador',
  },
  {
    value: 'AJM',
    label: 'Trabajador de la Oficina Postal',
  },
  {
    value: 'AJZ',
    label: 'Otro',
  },
  {
    value: 'AK0',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AK1',
    label: 'Analista',
  },
  {
    value: 'AK2',
    label: 'Empleado',
  },
  {
    value: 'AK3',
    label: 'Director/Administrador',
  },
  {
    value: 'AK4',
    label: 'Ingeniero-Hardware',
  },
  {
    value: 'AK5',
    label: 'Ingeniero-Software',
  },
  {
    value: 'AK6',
    label: 'Ingeniero-Sistemas',
  },
  {
    value: 'AK7',
    label: 'Ejecutivo',
  },
  {
    value: 'AK8',
    label: 'Gerente-Sistema',
  },
  {
    value: 'AK9',
    label: 'Administrador de Redes',
  },
  {
    value: 'AKA',
    label: 'Programador',
  },
  {
    value: 'AKB',
    label: 'Coordinador de Proyecto',
  },
  {
    value: 'AKC',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'AKD',
    label: 'Técnico de Soporte',
  },
  {
    value: 'AKE',
    label: 'Seguridad de Sistemas',
  },
  {
    value: 'AKF',
    label: 'Escritor Técnico',
  },
  {
    value: 'AKG',
    label: 'Desarrollador Web',
  },
  {
    value: 'AKZ',
    label: 'Otro',
  },
  {
    value: 'AL0',
    label: 'Contador/Auditor',
  },
  {
    value: 'AL1',
    label: 'Actuario',
  },
  {
    value: 'AL2',
    label: 'Empleado de Actuario',
  },
  {
    value: 'AL3',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AL4',
    label: 'Agente/Corredor',
  },
  {
    value: 'AL5',
    label: 'Analista',
  },
  {
    value: 'AL6',
    label: 'Abogado',
  },
  {
    value: 'AL7',
    label: 'Ajustador de Reclamos',
  },
  {
    value: 'AL8',
    label: 'Empleado',
  },
  {
    value: 'AL9',
    label: 'Notario',
  },
  {
    value: 'ALA',
    label: 'Representante de Servicio al Cliente',
  },
  {
    value: 'ALB',
    label: 'Director/Administrador',
  },
  {
    value: 'ALC',
    label: 'Ejecutivo',
  },
  {
    value: 'ALD',
    label: 'Representante de Aseguranzas para Servicio al Cliente',
  },
  {
    value: 'ALE',
    label: 'Gerente de Producto',
  },
  {
    value: 'ALF',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ALG',
    label: 'Representante de Ventas',
  },
  {
    value: 'ALH',
    label: 'Asegurador',
  },
  {
    value: 'ALZ',
    label: 'Otro',
  },
  {
    value: 'AM0',
    label: 'Oficial de Seguridad de Aeropuerto',
  },
  {
    value: 'AM1',
    label: 'Oficial de Control Animal',
  },
  {
    value: 'AM2',
    label: 'Abogado',
  },
  {
    value: 'AM3',
    label: 'Alguacil',
  },
  {
    value: 'AM4',
    label: 'Oficial de Correccional',
  },
  {
    value: 'AM5',
    label: 'Empleado de la Corte/Reportero',
  },
  {
    value: 'AM6',
    label: 'Ayudante del Sheriff',
  },
  {
    value: 'AM7',
    label: 'Despachador',
  },
  {
    value: 'AM8',
    label: 'Examinador',
  },
  {
    value: 'AM9',
    label: 'Agente Federal/Mariscal',
  },
  {
    value: 'AMA',
    label: 'Jefe de Bomberos',
  },
  {
    value: 'AMB',
    label: 'Bombero/Supervisor',
  },
  {
    value: 'AMC',
    label: 'Oficial/Investigador de Juegos',
  },
  {
    value: 'AMD',
    label: 'Oficial de Patrulla de Carreteras',
  },
  {
    value: 'AME',
    label: 'Juez/Oficial de Audiencia',
  },
  {
    value: 'AMF',
    label: 'Asistente/Secretario Legal',
  },
  {
    value: 'AMG',
    label: 'Paralegal/Empleado de Ley',
  },
  {
    value: 'AMH',
    label: 'Jefe de Policia',
  },
  {
    value: 'AMJ',
    label: 'Detective/Investigador de Policia',
  },
  {
    value: 'AMK',
    label: 'Oficial/Supervisor de Policia',
  },
  {
    value: 'AML',
    label: 'Investigador/Detective Privado',
  },
  {
    value: 'AMM',
    label: 'Agente Judicial',
  },
  {
    value: 'AMN',
    label: 'Guardia de Seguridad',
  },
  {
    value: 'AMP',
    label: 'Alguacil',
  },
  {
    value: 'AMZ',
    label: 'Otro',
  },
  {
    value: 'AN0',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AN1',
    label: 'Asistente-Medico/Dental/Veterinario',
  },
  {
    value: 'AN2',
    label: 'Quiropráctico',
  },
  {
    value: 'AN3',
    label: 'Clero',
  },
  {
    value: 'AN4',
    label: 'Empleado',
  },
  {
    value: 'AN5',
    label: 'Trabajador de Cuidado al Cliente',
  },
  {
    value: 'AN6',
    label: 'Higienista Dental',
  },
  {
    value: 'AN7',
    label: 'Dentista',
  },
  {
    value: 'AN8',
    label: 'Nutricionista',
  },
  {
    value: 'AN9',
    label: 'Doctor',
  },
  {
    value: 'ANA',
    label: 'Voluntario en un Hospicio',
  },
  {
    value: 'ANB',
    label: 'Asistente de Laboratorio',
  },
  {
    value: 'ANC',
    label: 'Funerario',
  },
  {
    value: 'AND',
    label: 'Enfermero-C.N.A.',
  },
  {
    value: 'ANE',
    label: 'Enfermero-LPN',
  },
  {
    value: 'ANF',
    label: 'Enfermero-RN',
  },
  {
    value: 'ANG',
    label: 'Practicante de Enfermería',
  },
  {
    value: 'ANH',
    label: 'Optometrista',
  },
  {
    value: 'ANJ',
    label: 'Ortodoncista',
  },
  {
    value: 'ANK',
    label: 'Paramedico/Técnico de Medicina de Emergencia',
  },
  {
    value: 'ANL',
    label: 'Farmacéutico',
  },
  {
    value: 'ANM',
    label: 'Fisioterapeuta',
  },
  {
    value: 'ANN',
    label: 'Psicólogo',
  },
  {
    value: 'ANP',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ANQ',
    label: 'Trabajador Social',
  },
  {
    value: 'ANR',
    label: 'Servicios de Soporte',
  },
  {
    value: 'ANS',
    label: 'Tecnico',
  },
  {
    value: 'ANT',
    label: 'Terapeuta',
  },
  {
    value: 'ANU',
    label: 'Veterinario',
  },
  {
    value: 'ANZ',
    label: 'Otro',
  },
  {
    value: 'AP0',
    label: 'Cuidador',
  },
  {
    value: 'AP1',
    label: 'Tintorero/Lavadero',
  },
  {
    value: 'AP2',
    label: 'Estilista/Barbero',
  },
  {
    value: 'AP3',
    label: 'Mucamo o Mucama',
  },
  {
    value: 'AP4',
    label: 'Manicuro',
  },
  {
    value: 'AP5',
    label: 'Masajista',
  },
  {
    value: 'AP6',
    label: 'Niñero',
  },
  {
    value: 'AP7',
    label: 'Servicios de Mascota',
  },
  {
    value: 'AP8',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'APZ',
    label: 'Otro',
  },
  {
    value: 'AQ0',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AQ1',
    label: 'Empleado',
  },
  {
    value: 'AQ2',
    label: 'Trabajador de Fábrica',
  },
  {
    value: 'AQ3',
    label: 'Capataz/Supervisor',
  },
  {
    value: 'AQ4',
    label: 'Acabador de Muebles',
  },
  {
    value: 'AQ5',
    label: 'Inspector',
  },
  {
    value: 'AQ6',
    label: 'Joyero',
  },
  {
    value: 'AQ7',
    label: 'Maquinista',
  },
  {
    value: 'AQ8',
    label: 'Envasador',
  },
  {
    value: 'AQ9',
    label: 'Gerente de Planta',
  },
  {
    value: 'AQA',
    label: 'Impresor/Encuadernador',
  },
  {
    value: 'AQB',
    label: 'Control de Calidad',
  },
  {
    value: 'AQC',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'AQD',
    label: 'Operador de Refineria',
  },
  {
    value: 'AQE',
    label: 'Zapatero',
  },
  {
    value: 'AQF',
    label: 'Sastre/Custorero',
  },
  {
    value: 'AQG',
    label: 'Trabajador Textil',
  },
  {
    value: 'AQH',
    label: 'Tapicero',
  },
  {
    value: 'AQZ',
    label: 'Otro',
  },
  {
    value: 'AR0',
    label: 'Ingeniero de Mantenimiento',
  },
  {
    value: 'AR1',
    label: 'Custodio/Conserje',
  },
  {
    value: 'AR2',
    label: 'Electricista',
  },
  {
    value: 'AR3',
    label: 'Tecnico de Servicio de Campo',
  },
  {
    value: 'AR4',
    label: 'Personal de Mantenimiento',
  },
  {
    value: 'AR5',
    label: 'Reparador de Aire Acondicionade y Calefaccion',
  },
  {
    value: 'AR6',
    label: 'Mucamo/Mucama',
  },
  {
    value: 'AR7',
    label: 'Paisajita/Mantenimiento de Jardines',
  },
  {
    value: 'AR8',
    label: 'Mecánico de Mantenimiento',
  },
  {
    value: 'AR9',
    label: 'Mecánico',
  },
  {
    value: 'ARZ',
    label: 'Otro',
  },
  {
    value: 'AS0',
    label: 'Asistente de Actividades/Recreaciónal',
  },
  {
    value: 'AS1',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AS2',
    label: 'Agente',
  },
  {
    value: 'AS3',
    label: 'Atleta',
  },
  {
    value: 'AS4',
    label: 'Consejero/Lider de Campamento',
  },
  {
    value: 'AS5',
    label: 'Empleado',
  },
  {
    value: 'AS6',
    label: 'Entrenador',
  },
  {
    value: 'AS7',
    label: 'Concesionario',
  },
  {
    value: 'AS8',
    label: 'Director de Programa',
  },
  {
    value: 'AS9',
    label: 'Gerente/Promotor de Eventos',
  },
  {
    value: 'ASA',
    label: 'Salvavidas',
  },
  {
    value: 'ASB',
    label: 'Gerente-Club de Fitness',
  },
  {
    value: 'ASC',
    label: 'Guardabosques',
  },
  {
    value: 'ASD',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ASE',
    label: 'Ventas-Ticket/Membresia',
  },
  {
    value: 'ASF',
    label: 'Locutor/Periodista de Deportes',
  },
  {
    value: 'ASG',
    label: 'Entrenador/Instructor',
  },
  {
    value: 'ASH',
    label: 'Árbitro',
  },
  {
    value: 'ASZ',
    label: 'Otro',
  },
  {
    value: 'AT0',
    label: 'Asistente Administrativo',
  },
  {
    value: 'AT1',
    label: 'Controlador de Tráfico Aereo',
  },
  {
    value: 'AT2',
    label: 'Equipo de Operaciones del Aeropuerto',
  },
  {
    value: 'AT3',
    label: 'Botones/Portero',
  },
  {
    value: 'AT4',
    label: 'Empleado',
  },
  {
    value: 'AT5',
    label: 'Cargador/Operador de Grúa',
  },
  {
    value: 'AT6',
    label: 'Despachador',
  },
  {
    value: 'AT7',
    label: 'Conductor-Bus/Tranvía',
  },
  {
    value: 'AT8',
    label: 'Conductor-Taxi/Limo',
  },
  {
    value: 'AT9',
    label: 'Conductor-Camión/Distribución',
  },
  {
    value: 'ATA',
    label: 'Auxiliar de Vuelo',
  },
  {
    value: 'ATB',
    label: 'Obrero',
  },
  {
    value: 'ATC',
    label: 'Estibador',
  },
  {
    value: 'ATD',
    label: 'Gerente-Almacén/Distrito',
  },
  {
    value: 'ATE',
    label: 'CoPiloto/Marinero',
  },
  {
    value: 'ATF',
    label: 'Asistente de Estacionamiento',
  },
  {
    value: 'ATG',
    label: 'Piloto/Capitan/Ingeniero',
  },
  {
    value: 'ATH',
    label: 'Trabajador de Tren',
  },
  {
    value: 'ATJ',
    label: 'Recepcionista/Secretario',
  },
  {
    value: 'ATK',
    label: 'Empleado de Envíos/Recepción',
  },
  {
    value: 'ATL',
    label: 'Operador Subterraneo/Tren Ligero',
  },
  {
    value: 'ATM',
    label: 'Agente de Boletos',
  },
  {
    value: 'ATN',
    label: 'Especialista de Transportación',
  },
  {
    value: 'ATP',
    label: 'Agente de Viajes',
  },
  {
    value: 'ATZ',
    label: 'Otro',
  },
];
export const SR22_REASONS = [
  {
    value: 'N',
    label: 'No Aseguranza',
  },
  {
    value: 'A',
    label: 'Accidente con Muerte',
  },
  {
    value: 'M',
    label: 'Multiple Infracciones de Reckless/Majors',
  },
  {
    value: 'D',
    label: 'Relacionado con Drogas/Alcohol',
  },
  {
    value: 'O',
    label: 'Otro',
  },
];
export const STATES = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AS',
    label: 'Samoa Americana',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'Distrito de Columbia',
  },
  {
    value: 'FM',
    label: 'Estados Federados de Micronesia',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MH',
    label: 'Islas Marshall',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];
export const LICENSE_TYPES = [
  {
    value: 'us_license',
    label: 'Licencia americana',
  },
  {
    value: 'mx_license',
    label: 'Licencia mexicana',
  },
  {
    value: 'ca_license',
    label: 'Licencia canadiense',
  },
  {
    value: 'int_license',
    label: 'Licencia internacional',
  },
  {
    value: 'mat_license',
    label: 'Matrícula',
  },
  {
    value: 'oth_license',
    label: 'Otra',
  },
];
export const LICENSE_STATUSES = [
  {
    value: 'E',
    label: 'Expirada',
  },
  {
    value: 'R',
    label: 'Revocada',
  },
  {
    value: 'S',
    label: 'Suspendida',
  },
  {
    value: 'V',
    label: 'Válida',
  },
];
export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const GENDERS = [
  {
    value: 'M',
    label: 'Hombre',
  },
  {
    value: 'F',
    label: 'Mujer',
  },
  {
    value: 'X',
    label: 'Otro',
  },
];
export const CIVIL_STATUSES = [
  {
    value: 'M',
    label: 'Casado',
  },
  {
    value: 'S',
    label: 'Soltero',
  },
  {
    value: '',
    label: 'Otro',
  },
];
export const OTHER_CIVIL_STATUSES = [
  {
    value: 'D',
    label: 'Divorciado',
  },
  {
    value: 'E',
    label: 'Separado',
  },
  {
    value: 'W',
    label: 'Viudo',
  },
];
export const YESNO = [
  {
    value: true,
    label: 'Sí',
  },
  {
    value: false,
    label: 'No',
  },
];
export const DMV_CERTIFICATIONS = [
  {
    value: '',
    label: 'Ninguna',
  },
  {
    value: 'sr22',
    label: 'SR-22',
  },
];
export const DMV_CERTIFICATION_REASONS = [
  {
    value: 'N',
    label: 'No Aseguranzas',
  },
  {
    value: 'A',
    label: 'Accidente con Muerte',
  },
  {
    value: 'M',
    label: 'Multiple Infracciones de Reckless/Majors',
  },
  {
    value: 'D',
    label: 'Relacionado con Drogas/Alcohol',
  },
  {
    value: 'O',
    label: 'Otro',
  },
];
export const VEHICLE_USAGES = [
  {
    value: 'A',
    label: 'Uso Artesanal',
  },
  {
    value: 'B',
    label: 'Uso de Negocios',
  },
  {
    value: 'F',
    label: 'Granja',
  },
  {
    value: 'P',
    label: 'Placer',
  },
  {
    value: 'W',
    label: 'Trabaja/Escuela',
  },
];
export const COVERAGE_LIMITS = [
  {
    value: '15/30',
    label: '15/30',
  },
  {
    value: '25/50',
    label: '25/50',
  },
  {
    value: '50/100',
    label: '50/100',
  },
  {
    value: '100/300',
    label: '100/300',
  },
  {
    value: '250/500',
    label: '250/500',
  },
];
export const UNINS_COVERAGE_LIMITS = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  {
    value: '15/30',
    label: '15/30',
  },
  {
    value: '25/50',
    label: '25/50',
  },
  {
    value: '50/100',
    label: '50/100',
  },
  {
    value: '100/300',
    label: '100/300',
  },
  {
    value: '250/500',
    label: '250/500',
  },
];
export const LIABILITY_PD_COVERAGES = [
  5,
  10,
  15,
  25,
  50,
  100,
];
export const MED_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  500,
  1000,
  2000,
  5000,
  10000,
];
export const DEATH_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  1000,
  2000,
  3000,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
];
export const COMPREHENSIVE_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  100,
  200,
  250,
  400,
  500,
  600,
  750,
  1000,
  1500,
  2000,
  2500,
  5000,
];
export const COLLISSION_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  100,
  200,
  250,
  400,
  500,
  600,
  750,
  1000,
  1500,
  2000,
  2500,
  5000,
];
export const TOWING_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  20,
  30,
  35,
  40,
  50,
  75,
  100,
];
export const RENTAL_COVERAGES = [
  {
    value: null,
    label: 'Sin cobertura',
  },
  20,
  30,
  35,
  40,
];
export const VEHICLE_COVERAGE_TYPES = [
  {
    value: 'basic',
    label: 'Básica',
  },
  {
    value: 'medium',
    label: 'Mediana',
  },
  {
    value: 'max',
    label: 'Completa',
  },
  {
    value: null,
    label: 'Otro',
  },
];
export const AUTO_INSURANCE_RATE_TERMS = [
  {
    value: null,
    label: 'Todos',
  },
  {
    value: 3,
    label: '3 meses',
  },
  {
    value: 6,
    label: '6 meses',
  },
  {
    value: 12,
    label: '12 meses',
  },
];
export const AUTO_INSURANCE_SOURCES = [
  {
    value: 'Referral',
    label: 'Referido',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Internet (except Facebook)',
    label: 'Internet (excepto Facebook)',
  },
  {
    value: 'Store/Walk-In',
    label: 'Tienda',
  },
  {
    value: 'TV',
    label: 'TV',
  },
  {
    value: 'Grassroots',
    label: 'Ventas a la comunidad',
  },
  {
    value: 'Direct Mail',
    label: 'Correo directo',
  },
];
export const RIDE_SHARE_OPTIONS = [
  {
    value: '',
    label: 'Ninguno',
  },
  {
    value: 'ride_share',
    label: 'Ride share',
  },
  {
    value: 'commercial',
    label: 'Comercial',
  },
];
export const DRIVER_RELATIONSHIPS = [
  {
    value: 'AUNT',
    label: 'Tía',
  },
  {
    value: 'BROTH',
    label: 'Hermano',
  },
  {
    value: 'DAUGH',
    label: 'Hija',
  },
  {
    value: 'DOMES',
    label: 'Unión libre',
  },
  {
    value: 'FATHR',
    label: 'Padre',
  },
  {
    value: 'GDAUG',
    label: 'Nieta',
  },
  {
    value: 'GFTHR',
    label: 'Abuelo',
  },
  {
    value: 'GMTHR',
    label: 'Abuela',
  },
  {
    value: 'GSON',
    label: 'Nieto',
  },
  {
    value: 'INLAW',
    label: 'Consuegro',
  },
  {
    value: 'MOTHR',
    label: 'Madre',
  },
  {
    value: 'NEPHW',
    label: 'Sobrino',
  },
  {
    value: 'NIECE',
    label: 'Sobrina',
  },
  {
    value: 'NREL',
    label: 'No familiar',
  },
  {
    value: 'REL',
    label: 'Familiar',
  },
  {
    value: 'SELF',
    label: 'Self',
  },
  {
    value: 'SIGNF',
    label: 'Pareja',
  },
  {
    value: 'SISTR',
    label: 'Hermana',
  },
  {
    value: 'SON',
    label: 'Hijo',
  },
  {
    value: 'SPSE',
    label: 'Esposo(a)',
  },
  {
    value: 'UNCLE',
    label: 'Tío',
  },
];
export const RACES = [
  {
    value: 'AA',
    label: 'Afroamericano',
  },
  {
    value: 'AI',
    label: 'Indio americano o nativo de Alaska',
  },
  {
    value: 'AO',
    label: 'Asiático (Oriental)',
  },
  {
    value: 'AOTH',
    label: 'Asiático (Otro)',
  },
  {
    value: 'LA',
    label: 'Latino (No Brasileño ni Portugués)',
  },
  {
    value: 'ME',
    label: 'Mediooriental',
  },
  {
    value: 'PI',
    label: 'Isleño del pacifico',
  },
  {
    value: 'WH',
    label: 'Blanco',
  },
  {
    value: 'OTH',
    label: 'Otro',
  },
];
export const RELATED_DRIVERS_OPTIONS = [
  {
    value: 'unknown',
    label: 'Desconocido'
  },
  {
    value: 'duplicated',
    label: 'Duplicado'
  },
  {
    value: 'excluded',
    label: 'Excluido'
  },
  {
    value: 'driver',
    label: 'Condcutor'
  },
  {
    value: 'no-driver',
    label: 'No habita en el domicilio y no conductor'
  },
  {
    value: 'deceased',
    label: 'Fallecido'
  }
];
export const ANTI_THEFT_DEVICES = [
  {
    value: 'ADD',
    label: 'Dispositivo de desactivación activo',
  },
  {
    value: 'AO',
    label: 'Solo alarma',
  },
  {
    value: 'PSD',
    label: 'Dispositivo de desactivación pasivo',
  },
  {
    value: 'VRS',
    label: 'Sistema de recuperación de vehículos',
  },
  {
    value: '',
    label: 'Ninguno',
  },
];
export const AUTO_INSURANCE_STRINGS = {
  excluded: 'Excluir',
  excludeDriver: 'Excluir conductor',
  excludeVehicle: 'Excluir vehículo',
  firstName: 'Nombre',
  lastName: 'Apellido',
  phoneNumber: 'Teléfono',
  mobileNumber: 'Celular',
  email: 'Correo',
  address: 'Domicilio',
  zipCode: 'Código Postal',
  state: 'Estado',
  city: 'Ciudad',
  source: '¿Cómo se enteró de nosotros?',
  vin: 'VIN',
  year: 'Año de vehículo',
  maker: 'Marca',
  model: 'Modelo',
  purchaseDate: 'Fecha de compra',
  annualMiles: 'Millas al año',
  workMiles: 'Millas al trabajo',
  usage: 'Uso principal',
  homingDevice: 'Dispositivo de monitoreo',
  salvaged: 'Salvaged',
  rideShare: '¿El vehículo es utillizado para alguno de los siguientes fines?',
  primaryOperator: 'Propietario del vehículo',
  birthdate: 'Fecha de nacimiento',
  age: 'Edad',
  sex: 'Género',
  marital: 'Estado civil',
  marital2: 'Especifica el estado civil',
  industry: 'Industria donde trabaja',
  occupation: 'Ocupación',
  hasTraining: '¿Ha tomado algún curso de manejo?',
  priorInsurance: 'Seguro previo',
  priorInsuranceTime: 'Duración con seguro anterior',
  priorInsuranceYears: 'Años con seguro anterior',
  priorInsuranceMonths: 'Meses con seguro anterior',
  priorInsuranceExpDate: 'Fecha de vencimiento seguro anterior',
  dmvCertification: 'Certificación de responsabildad para el DMV',
  dmvCertificationState: 'Estado donde se registró',
  dmvCertificationReason: 'Motivo',
  licenseType: 'Tipo de licencia o ID',
  licenseStatus: 'Estatus de la licencia o ID',
  licenseNumber: 'Número de la licencia',
  years: 'Años',
  months: 'Meses',
  licenseTime: 'Experiencia con licencia o ID',
  licenseYears: 'Años con licencia americana',
  licenseMonths: 'Meses con licencia americana',
  totalLicenseTime: 'Experiencia total con licencia americana y/o extranjera',
  totalLicenseYears: 'Total de años con licencia',
  totalLicenseMonths: 'Total de meses con licencia',
  liability: 'Daños a terceros',
  liabBILimit: 'Daños a personas',
  liabPDLimit: 'Daños a la propiedad',
  uninsBILimit: 'UMBI',
  uninsPD: 'UMPD/CDW',
  accDeathLimit: 'Muerte accidental',
  medPayLimit: 'Gastos médicos',
  compDed: 'Comprehensive',
  collDed: 'Collision',
  rentalDed: 'Renta',
  towingDed: 'Grúa',
  coverageType: 'Tipo de cobertura',
  nonOwner: 'Cotización para Non-owner',
  monthToMonthFilter: 'Mostrar sólo planes con pagos mensuales',
  county: 'Condado',
  race: 'Raza u origen',
  primaryDriverRelationship: 'Parentesco con conductor principal',
  hasMatureDriverCourse: 'Descuento por curso de conductores mayores',
  matureDriverCourseDate: 'Fecha de finalización del curso',
  isGoodStudent: 'Descuento por buen estudiante',
  antiTheftDevice: 'Dispositivo antirrobo',
  odometer: 'Odómetro',
  effectiveDate: 'Fecha efectiva de la póliza',
  hasMailingAddress: 'Agregar otro domicilio para mailing',
  mailingAddress: 'Domicilio',
  mailingCity: 'Ciudad',
  mailingState: 'Estado',
  mailingZipCode: 'Código Postal',
  hasLossPayee: 'Agregar Loss Payee',
  lossPayeeType: 'Tipo de Loss Payee',
  institutionName: 'Nombre de la institución financiera',
  institutionAddress: 'Domicilio de la institución financiera',
  institutionZipCode: 'Código Postal',
  institutionState: 'Estado',
  institutionCity: 'Ciudad',
};
export const AUTO_INSURANCE_PLACEHOLDERS = {
  email: 'correo@ejemplo.com',
  address: '123 Street Name, City, St',
  city: 'Selecciona la ciudad',
  option: 'Selecciona una opción',
  state: 'California',
  zipCode: '12345',
};
export const AUTO_INSURANCE_TOOLTIPS = {
  hasMatureDriverCourse: 'Este descuento aplica cuando el conductor tiene 55 o más años de edad y cuenta con un certificado de finalización exitosa de un curso de prevención de accidentes. Su validez y cantidad del descuento depende de cada aseguradora.',
  isGoodStudent: 'Las compañías de seguros reconocen a un "buen estudiante" como un estudiante menor de 25 años que cuente con un promedio sobresaliente y/o que esté en la lista de honor de su escuela. Tipo de comprobante y cantidad del descuento depende de cada aseguradora.',
  antiTheftDevice: 'Un dispositivo antirrobo es cualquier dispositivo instalado en el vehículo que reduzca o prevenga las posibilidades de robo de algún artículo de valor del vehículo.',
  odometer: 'El odómetro es el lector de la distancia total recorrida por un vehículo. La distancia recorrida suele aparecer en el tablero del vehículo.',
  excluded: 'Un conductor excluido es alguien que viene nombrado en la póliza de seguro pero que no manejará el vehículo cubierto. Si esa persona utiliza el vehículo, con o sin consentimiento del conductor principal, y tiene un accidente, la aseguradora no lo cubrirá.',
  primaryOperator: 'Es la persona nombrada como dueño(a) legal del vehiculo.  Es necesario incluir o excluir a dichas persona(s) en la póliza.\t',
  medPayLimit: 'Cubre el pago de la asistencia médica por lesiones corporales del asegurado y/o los demás ocupantes del vehículo.',
  rentalDed: 'Cubre los costos del alquiler de un vehículo para reemplazar temporalmente el vehículo asegurado afectado en algún incidente vial.',
  total: 'Costo total de la poliza por el plazo del contrato (Down Payment + Pago x #Pagos).',
  annualTotal: 'Estimacion del costo total por 12 meses de cobertura.',
  liabBILimit: 'Es la porción del seguro que paga el portador de la póliza por gastos médicos ocasionados a las personas que van en el auto (conductor y pasajeros). En general, esta cobertura incluye los gastos médicos por tratamiento, hospitalización, honorarios generados por los daños sufridos en el accidente, y servicios que necesiten las personas afectadas en un accidente. También puede pagar por los costos de funerales.',
  liabPDLimit: 'Es la porción del seguro que cubre de responsabilidad civil por daños a la propiedad de terceros.',
  nonOwner: 'Aplica cuando el asegurado no es dueño de un carro y quiere cobertura para conducir el carro de otra persona. Es un tipo de póliza que cubre al conductor por responsabilidad civil cuando no posee un vehículo. Este tipo de póliza es individual por lo que solo cubre al conductor principal.',
  uninsBILimit: 'Cobertura que reembolsa los gastos incurridos tras un choque con un conductor sin seguro. Dichos gastos cubren solo daños a las personas dentro del vehiculo. El conductor/propietario del vehículo no asegurado debe ser identificado para hacer aplicable esta cobertura.',
  uninsPD: 'Absuelve el deducible y paga por los daños al vehículo causados ​​por un conductor sin seguro. UMPD paga por los daños al vehículo causados ​​por un conductor sin seguro cuando el asegurado NO tiene cobertua de colision. El conductor/propietario del vehículo no asegurado debe ser identificado para hacer aplicable esta cobertura.',
  compDed: 'Deducible a pagar por gastos de otro tipo de pérdidas diferente a choques como: robo, fuego, terremoto, explosión, inundación, huracanes, vandalismo e inclusive impactos contra animales.',
  collDed: 'Deducible a pagar por gastos por daños ocasionados al vehículo en caso de un choque con otro vehículo, estructura o si se vuelca.',
};
export const AUTO_INSURANCE_STEPS: {
  label: string;
  value: AutoRaterSteps;
}[] = [
  {
    value: AutoRaterSteps.contact,
    label: 'Datos de contacto',
  },
  {
    value: AutoRaterSteps.drivers,
    label: 'Conductores',
  },
  {
    value: AutoRaterSteps.vehicles,
    label: 'Vehículos',
  },
  {
    value: AutoRaterSteps.coverages,
    label: 'Cotizaciones',
  },
];
export const CONTACT_STRING = 'Datos de contacto';
export const DRIVER_STRING = 'Conductor';
export const DRIVERS_STRING = 'Conductores';
export const VEHICLE_STRING = 'Vehículo';
export const VEHICLES_STRING = 'Vehículos';
export const COVERAGES_STRING = 'Coberturas';
export const EXCLUDED_STRING = 'Excluido';
export const CONFIRM_CHANGES_TITLE = 'Tus últimos cambios no han sido guardados.';
export const CONFIRM_CHANGES_BODY = '¿Qué deseas hacer?';
export const CONFIRM_CHANGES_CONFIRM = 'Guardar cambios';
export const CONFIRM_CHANGES_CANCEL = 'Salir sin guardar cambios';
export const CONFIRM_DELETE_DRIVER_TITLE = '¿Estás seguro que deseas eliminar este conductor?';
export const CONFIRM_DELETE_VEHICLE_TITLE = '¿Estás seguro que deseas eliminar este vehículo?';
export const CONFIRM_DELETE = 'Eliminar';
export const REQUIRED_FOR_BRIDGE_ERROR = {
  type: 'bridgeRequired',
};
export const CONFIRM_ERROR_TITLE = '¡Oh, no!\nHubo un error inesperado.';
export const CARRIER_ABORT_INSTRUCTIONS = 'Cómo cargar la cotización';
export const CARRIER_ABORT_DESC = 'Intenta nuevamente en unos minutos o continúa la cotización en el sitio de la aseguradora.';
export const CARRIER_ABORT_READY = 'Continuando en el sitio de la aseguradora';
export const CARRIER_ABORT_READY_DESC = 'La cotización debe estar abierta en el sitio de la aseguradora. Puede usar el siguiente botón si necesita abrirla de nuevo.';
export const CARRIER_ABORT_BUTTON = 'Ir al sitio de la aseguradora';
export const SAVE_AND_EXIT = 'Guardar y salir';
export const SAVE_AND_CONTINUE = 'Guardar y continuar';
export const MOMENT_PLEASE = 'Un momento por favor';
export const LOADING = 'Cargando...';
export const CARRIER_CONNECTION = 'Realizando conexión con la aseguradora';
export const CARRIER_DRIVERS_STEP = 'Conductores relacionados';
export const CARRIER_DRIVERS_MODAL_TITLE = 'Se agregaron nuevos conductores.';
export const CARRIER_DRIVERS_MODAL_DESC = 'Es necesario completar su información en la sección de “Conductores”, correr el MVR y recalcular las tarifas.';
export const CARRIER_DRIVERS_MODAL_BUTTON_OK = 'Enterado';
export const CARRIER_DRIVERS_MODAL_BUTTON_CANCEL = 'Cancelar';
export const CARRIER_QUESTIONS_STEP = 'Cuestionario';
export const CARRIER_CONFIRM_STEP = 'Confirmación';
export const CARRIER_SALE_STEP = 'Venta';
export const PAYMENT_DETAILS = 'Detalles del pago';
export const DOWN_PAYMENT_BREAKDOWN = 'Desglose de down payment';
export const DOWN_PAYMENT_TOTAL_PREMIUM = 'Total de la Póliza Premium';
export const PRIMARY_FEES = {
  antiFraudFee: 'Tarifa Anti-Fraude',
  sr22Fee: 'Tarifa por SR22',
  brokerFee: 'Cuota del Broker',
};
export const OTHER_FEES = 'Tarifa de la aseguradora';
export const DOWN_PAYMENT_TOTAL = 'Total Down Payment';
export const FINISH_IN_CARRIER = 'Finalizar en el sitio de la aseguradora';
export const NO_COVERAGE = 'Sin cobertura';
export const LOSS_PAYEE_TYPES = [
  {
    value: 'leased',
    label: 'Leased',
  },
  {
    value: 'financed',
    label: 'Financed',
  },
];
export const DROPDOWN_NO_OPTIONS = 'No hay más opciones.';
export const QUESTIONS_LABEL = 'Cuestionario';
export const QUESTIONS = {
  isAddressPrincipal: '¿El domicilio estipulado en esta solicitud es el domicilio principal y resguarda los vehículos agregados a esta cotización en el mismo domicilio?',
  areAllDriversReported: '¿Se han incluido todos los residentes mayores de 14 años que habitan en este domicilio y/o todos los posibles conductores de los vehículos incluidos en esta solicitud?',
  hasCommittedWorkmensFraud: '¿El conductor principal o cualquier otro conductor de esta solicitud tiene alguna condena previa por fraude de seguro o Workmen\'s le ha restringido, cancelado o no renovado su póliza dado a mal representación de datos en conexión a una solicitud of reclamo?',
  hasRentedVehicle: '¿Alguno de los vehículos en esta solicitud es alquilado o prestado a alguien que no esté incluido en esta solicitud?',
  hasRideShareVehicle: '¿Alguno de los vehículos es utilizado con fines comerciales como Uber, Lyft, etc.?',
  hasServicesVehicle: '¿Alguno de los vehículos es utilizado con fines de entrega de comida, paquetes o periódico?',
  hasDifferentAddressVehicle: '¿Alguno de los vehículos en esta solicitud se resguarda en alguna locación diferente al domicilio de conductor principal?',
  hasAnotherVehicles: '¿El conductor principal o los demás conductores es propietario de otro(s) vehículo(s) que no estén incluidos en esta solicitud?',
  hasPriorInsurance: 'El conductor principal / cónyuge tiene un seguro de responsabilidad civil continua durante los últimos 6 meses con un lapso de no más de 31 días.',
  priorInsuranceCompany: 'Compañía de seguros de automóviles anterior',
  isPrimaryResidenceOwned: 'Residencia principal',
  priorInsuranceBILimit: '¿Cuál era el límite en Daños a Personas en su póliza más reciente?',
  priorInsuranceExpDate: 'Fecha de vencimiento del plazo de la póliza anterior',
  qualifiesForAffinityProgram: '¿El conductor principal califica para el programa de afiliados?',
  wantsDocumentsViaEmail: '¿El conductor principal desea recibir sus documentos de póliza via e-mail?',
  hasAnotherNonVehiclePolicy: '¿El conductor principal o algún otro de los conductores cuenta con otra póliza de seguro con esta compañía? (Otra póliza de auto no aplica)',
  areAllDriversOlderThan15Reported: '¿Hay personas (con licencia o sin licencia) con edad para conducir (15 años o más) que viven en el domicilio del aplicante y que no están listados como conductores o excluidos en esta solicitud ?',
  hasNonIncludedDriver: '¿Alguna otra persona (conductores nuevos, residentes, compañeros de cuarto, parientes, empleados, ayuda doméstica, etc.) no incluida en esta solicitud, maneja alguno de los vehículos incluidos en esta cotización?',
  hasVehicleInDifferentAddress: '¿Alguno de los vehículos incluidos en la cotización se resguarda en el colegio o alguna otra ubicación que no sea el domicilio estipulado en esta cotización?',
  hasVehicleInDifferentRegister: '¿Alguno de los vehículos en esta solicitud no es propiedad o no está registrado a nombre de las personas en esta solicitud?',
  hasModifiedVehicle: '¿Alguno de los vehículos ha sido reconstruido, modificado o personalizado?',
  hasDamagedVehicle: '¿Alguno de los vehículos tiene algún daño existente (incluye daños en el vidrio)?',
  hasWorkVehicle: '¿Alguno de los vehículos es utilizado en el negocio u ocupación del conductor?',
  areQuestionsConfirmed: 'Le he preguntado al solicitante todas las preguntas en esta solicitud y son las respuestas del solicitante. Que yo sepa, toda la información en esta solicitud es verdadera, correcta y completa.',
  areAllVehiclesInSameAddress: '¿Todos los vehículos agregados a esta cotización se resguardan en el mismo domicilio y es el domicilio estipulado en esta solicitud?',
  agreesToIntLicenseConfirm: 'Las licencias internacionales nunca deben haber sido autorizadas en los Estados Unidos. He enlistado cualquier violación / accidente, y recopilaré la Certificación de Conductor Internacional firmada y la documentación de respaldo (el número de documento figura en esta solicitud), si así se requiere.',
  hasProhibitedRisks: '¿Alguno de los siguientes escenarios prohibidos de riesgo aplica?',
  areAllFrecuentDriversReported: '¿Ha enlistado a todas las personas que usan con frecuencia los vehículos incluidos en esta solicitud?',
  areAllVehicleRegisterDriversReported: '¿Ha enlistado todos los nombres que aparecen actualmente en el registro de los vehículos incluidos en esta solicitud?',
  hasPreviouslyRentedVehicle: '¿Se ha arrendado alguno de los vehículos incluidos en esta aplicación por un período de 6 meses o menos?',
  hasExtendedServicesVehicle: '¿Alguno de los vehículos es utilizado para carreras o para transportar / entregar bienes como pizza, verduras, periódicos, material explosivo / bioquímico o para transporte de emergencia o se utiliza como furgoneta / residente o para cualquier otro propósito comercial / comercial inaceptable ?',
  hasCommercialVehicle: '¿Su vehículo está registrado a nombre comercial?',
  hasExtremeSportsVehicle: '¿Alguno de los vehículos corresponde a alguno de los siguientes tipos: campistas, buggies de dunas, vehículos de carreras, autocaravanas, vehículos con más o menos de 4 ruedas, automóviles eléctricos, kit de automóviles, taxis, limusinas, jitneys, vehículos de emergencia, vehículos agrícolas o vehículos comerciales?',
  hasHeavyLiftingVehicle: '¿Alguno de los vehículos está clasificado como camioneta o vehículo utilitario con una capacidad de carga superior a una tonelada o una clasificación de peso bruto del vehículo de más de 10,000 libras?',
  hasSalvagedVehicle: '¿Alguno de los vehículos está clasificado como salvaged, de alto rendimiento, personalizado, reconstruido, modificado, en condiciones mecánicas inseguras o tiene daños existentes?',
  hasCalifornianData: '¿El conductor principal y todos los conductores incluidos residen actualmente (si son militares, estacionados) en el estado de California y los vehículos incluidos han estado en resguardo y operación en el estado de California durante al menos 10 meses por año?',
  ownsAllVehicles: '¿El conductor principal es el propietario registrado de todos los vehículos en la póliza?',
  hasCommittedFraud: '¿El conductor principal o cualquier otro conductor de esta solicitud tiene alguna condena previa por fraude de seguro?',
  hasAnotherPolicy: '¿El conductor principal o algún otro de los conductores cuenta con otra póliza de seguro con esta compañía?',
  hasUnpaidOrDeclinedPolicy: '¿El conductor principal o algún otro de los conductores cuenta con otra póliza de seguro con esta compañía con saldo no pagado, cancelada por falta de pagos en 36 meses, cancelada por mal representación de los datos, rechazada o no renovada?',
  reason: 'Explique la razón',
  anotherPolicyNumber: 'Número de póliza',
};
export const INSURANCE_COMPANIES = [
  '21ST CENTURY',
  'AAA/AUTO CLUB',
  'ACCESS INS CO',
  'ALLIANCE UNITED',
  'ALLIED',
  'ALLSTATE',
  'ANCHOR GENERAL',
  'BRISTOL WEST',
  'CALIFORNIA STATE ASSOCIATES',
  'DRIVE INSURANCE',
  'ESURANCE',
  'FARMERS',
  'GEICO',
  'INFINITY',
  'KEMPER',
  'LIBERTY MUTUAL',
  'LOYA GRP',
  'MERCURY',
  'NATIONAL GENERAL',
  'PROGRESSIVE',
  'SAFECO',
  'STATE FARM',
  'TRAVELLERS',
  'USAA',
  'WAWNESA INS',
  'OTHER',
];
export const RESIDENCE_OWNERSHIP = [
  'Propia Casa/Condominio',
  'Propia Fabricada / Casa móvil: 10 años o más reciente',
  'Rentada',
  'Compartida con familiares',
  'Otro',
];
export const INSURANCE_LIMITS = [
  {
    value: 'none',
    label: 'Límites mínimos del estado',
  },
  {
    value: '50/100',
    label: 'Más que el mínimo del estado, menos que 50/100',
  },
  {
    value: '100',
    label: 'Más o igual a 50/100, menos que 100/300 o 100 CSL',
  },
  {
    value: '100/300',
    label: 'Igual o más que 100/300 o 100 CSL',
  },
];
export const AFFILIATE_PROGRAMS = [
  {
    value: 'none',
    label: 'Ningúno',
  },
  {
    value: 'drive',
    label: 'Cliente de Zubie',
  },
  {
    value: 'zubie',
    label: 'Empleado Drive/PGR',
  },
];
export const INT_LICENSE_OPTIONS = [
  {
    value: 'not_required',
    label: 'No se requiere',
  },
  {
    value: false,
    label: 'No',
  },
  {
    value: true,
    label: 'Si',
  },
];
