<ng-container *ngIf="!loading">
  <img class="image" src="./assets/images/listo-circular.svg">
  <h1 class="header" i18n>Welcome!</h1>
  <p class="subheader" i18n>Through this site, you can access all your work tools.</p>
  <listo-button class="button" (click)="login()" i18n>Log in with your listo! account</listo-button>
</ng-container>
<ng-container *ngIf="loading">
  <listo-spinner></listo-spinner>
  <p class="title">Iniciando sesión</p>
</ng-container>
<listo-modal *ngIf="errorModalOpen" class="error-modal" (close)="errorModalOpen = false">
  <img src="../../assets/images/warning.svg">
  <p class="title">¡Oh no!</p>
  <p>Hubo un error al iniciar sesión. Por favor inténtalo nuevamente.</p>
  <listo-button class="button" (click)="login()">Iniciar sesión</listo-button>
</listo-modal>
<listo-modal *ngIf="unAuthErrorModalOpen" class="error-modal" (close)="unAuthErrorModalOpen = false">
  <img src="../../assets/images/warning.svg">
  <p class="title">Lo sentimos, no cuentas con los permisos necesarios para ingresar a este sitio.</p>
  <p>Si tienes dudas sobre los productos de listo!, llámanos al <a href="tel:(800) 442-9331">(800) 442-9331</a> o conoce mas sobre nosotros en nuestra pagina web
    <a href="https://listofin.com.">https://listofin.com.</a></p>
</listo-modal>
