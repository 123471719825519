import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoInsuranceRate } from '../../../utils/types';

@Component({
  selector: 'app-rates-table-row-small',
  templateUrl: './rates-table-row-small.component.html',
  styleUrls: ['./rates-table-row-small.component.scss'],
})
export class RatesTableRowSmallComponent {
  @Input() rate: AutoInsuranceRate;
  @Output() bridge = new EventEmitter();
  open = false;
}
