import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { ChangesGuard } from './changes.guard';
import { AppRoute } from '../utils/types';
import { ToolsComponent } from './tools/tools.component';
import { AutoInsuranceDashboardComponent } from './auto-insurance-dashboard/auto-insurance-dashboard.component';
import { EXPANDED_RATER_LABEL, PROFILE_LABEL, TOOLS_LABEL } from '../utils/constants';
import { LogoutComponent } from './logout/logout.component';
import { AutoRaterComponent } from './auto-rater/auto-rater.component';
import { AgentLinksComponent } from './agent-links/agent-links.component';
import { ProfileComponent } from './profile/profile.component';

export const dashboardRoutes: AppRoute[] = [
  // {
  //   label: PERFORMANCE_LABEL,
  //   path: 'performance',
  //   icon: 'goals',
  //   showInSidebar: true,
  //   children: [
  //     {
  //       label: MY_PERFORMANCE_LABEL,
  //       path: '',
  //       component: MyPerformanceComponent,
  //       showInSidebar: true,
  //     },
  //     {
  //       label: STORE_PERFORMANCE_LABEL,
  //       path: 'store',
  //       component: StorePerformanceComponent,
  //       showInSidebar: true,
  //     },
  //   ],
  // },
  {
    label: TOOLS_LABEL,
    path: 'tools',
    icon: 'tools',
    showInSidebar: true,
    children: [
      {
        path: '',
        component: ToolsComponent,
      },
      {
        path: 'links',
        component: AgentLinksComponent,
      },
    ],
  },
  {
    label: EXPANDED_RATER_LABEL,
    path: 'autorater',
    icon: 'car',
    showInSidebar: true,
    children: [
      {
        path: '',
        component: AutoInsuranceDashboardComponent,
      },
      {
        path: 'quote/:quoteApplicationId',
        component: AutoRaterComponent,
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'quote',
        component: AutoRaterComponent,
        canDeactivate: [ChangesGuard],
      },
    ],
  },
  {
    label: PROFILE_LABEL,
    component: ProfileComponent,
    path: 'profile',
    icon: 'profile',
    showInSidebar: true,
  },
];

const routes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      ...dashboardRoutes as Route[],
      {
        path: '**',
        redirectTo: 'tools',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
