<listo-modal (close)="close.emit()">
  <h2 class="header" i18n>Compare coverages</h2>
  <div class="container">
    <div class="coverage">
      <img src="../../assets/images/basica.svg">
      <h2 i18n>Basic</h2>
      <p i18n>Minimum required by law.</p>
      <b i18n>What's included?</b>
      <p i18n>Medical expenses or protection for personal damage to third parties</p>
      <p><span class="icon-add"></span></p>
      <p i18n>Coverage of civil liability for damage to third-party property</p>
      <listo-button [flat]="true" (click)="current = current === 'basic' ? undefined : 'basic'">
        <ng-container *ngIf="current === 'basic'" i18n>Hide details</ng-container>
        <ng-container *ngIf="current !== 'basic'" i18n>Show details</ng-container>
        <span class="icon-dropdown-{{current === 'basic' ? 'open' : 'closed'}}"></span>
      </listo-button>
    </div>
    <div class="coverage" [class.closed]="current === 'basic'">
      <img src="../../assets/images/mediana.svg">
      <h2 i18n>Medium</h2>
      <b i18n>What's included?</b>
      <p i18n>Basic coverage</p>
      <p ><span class="icon-add"></span></p>
      <p i18n>Collision</p>
      <p><span class="icon-add"></span></p>
      <p i18n>Comprehensive</p>
      <p class="small" i18n>(Deductible: $1,000)</p>
      <listo-button [flat]="true" (click)="current = current === 'medium' ? undefined : 'medium'">
        <ng-container *ngIf="current === 'medium'" i18n>Hide details</ng-container>
        <ng-container *ngIf="current !== 'medium'" i18n>Show details</ng-container>
        <span class="icon-dropdown-{{current === 'medium' ? 'open' : 'closed'}}"></span>
      </listo-button>
    </div>
    <div class="coverage" [class.closed]="current !== 'max'">
      <img src="../../assets/images/completa.svg">
      <h2 i18n>Full</h2>
      <b i18n>What's included?</b>
      <p i18n>Basic coverage</p>
      <p><span class="icon-add"></span></p>
      <p i18n>Collision</p>
      <p><span class="icon-add"></span></p>
      <p i18n>Comprehensive</p>
      <p class="small" i18n>(Deductible: $500)</p>
      <listo-button [flat]="true" (click)="current = current === 'max' ? undefined : 'max'">
        <ng-container *ngIf="current === 'max'" i18n>Hide details</ng-container>
        <ng-container *ngIf="current !== 'max'" i18n>Show details</ng-container>
        <span class="icon-dropdown-{{current === 'max' ? 'open' : 'closed'}}"></span>
      </listo-button>
    </div>
    <div *ngIf="current" class="detail"  i18n>
      <h3>Medical expenses or protection of personal damages to third parties</h3>
      <p>
        Portion of insurance policy that covers medical expenses for drivers & passengers.
      </p>
      <p>
        In general, this coverage includes medical expenses for treatment, hospitalization, fees generated by the
        damages
        suffered in the accident and services needed by people affected in an accident. It can also pay for funeral
        costs.
      </p>
    </div>
    <div *ngIf="current" class="detail" i18n>
      <h3>Coverage of civil liability for damage to third-party property</h3>
      <p>
        Generally covers damage to third party’s property such as car, poles, buildings, public roads and other
        structures.
      </p>
    </div>
    <div *ngIf="current === 'medium' || current === 'max'" class="detail" i18n>
      <h3>Collision coverage</h3>
      <p>
        It is the portion of your policy that covers the damage caused to your vehicle when it collides with another
        vehicle or structure (such as lighting por post) or if it is overturned.
        Generally, this part of the insurance is deductible that you choose associated which can be between between 250
        dollars and 1,000 dollars; and the higher your deductible, the more you will reduce the cost of your annual
        policy.
        Even if you are responsible for the crash, this coverage will pay for the cost of repairing your vehicle, less
        the
        deductible, of course.
        In the event that the crash was not your fault, the insurance company will try to have the insurance of the
        other
        party involved pay for the cost of repairing your vehicle.
        If you win the lawsuit against the other insurance company, then you will also be reimbursed the portion of the
        deductible you must pay to have your vehicle repaired.
      </p>
    </div>
    <div *ngIf="current === 'medium' || current === 'max'" class="detail" i18n>
      <h3>Extensive coverage or against risks</h3>
      <p>
        It is the portion of the insurance that reimburses you for expenses in case of other types of losses other than
        crashes. That is, in case of theft, fire, earthquake, explosion, flood, hurricanes, vandalism, street
        disturbances
        and even impact on the road against animals such as birds, deer or cattle.
        This part of the coverage is quite extensive and also carries a deductible that can be increased from 100 to 300
        dollars or even more, if you decide that it favors the price of your insurance and in relation to your vehicle.
        It can also compensate you for the cost of replacing your windshield if it breaks or breaks. Some companies
        offer
        windshield coverage with or without a deductible.
        State laws do not require you to obtain Collision or other risks (Comprehensive), but if you are financing your
        vehicle, the lender will insist that you have this type of insurance until you have finished paying the loan of
        the car.
      </p>
    </div>
  </div>
  <listo-button (click)="close.emit()">
    <span class="icon-arrow-left"></span>
    <ng-container i18n>Back</ng-container>
  </listo-button>
</listo-modal>
