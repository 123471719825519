<!-- Table is duplicated because it is needed to render the whole body to get an aligned header -->
<!-- first one will show header only, the second one will show body only -->
<div class="only-header-table">
  <ng-container [ngTemplateOutlet]="tableTemp"></ng-container>
</div>
<div class="table-scrollable-wrapper">
  <ng-container [ngTemplateOutlet]="tableTemp"></ng-container>
</div>

<ng-template #tableTemp>
  <table>
    <thead>
      <tr>
        <th i18n>Company</th>
        <th i18n>Term</th>
        <th i18n>Down payment</th>
        <th i18n>Quote</th>
        <th i18n>#Quotes</th>
        <th>
          Total
          <listo-tooltip>
            {{TOOLTIPS.total}}
          </listo-tooltip>
        </th>
        <th>
          <ng-container i18n>Annual Total</ng-container>
          <listo-tooltip>
            {{TOOLTIPS.annualTotal}}
          </listo-tooltip>
        </th>
        <th>
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let rate of rates">
        <app-rates-table-row [rate]="rate" (bridge)="bridge.emit(rate)"></app-rates-table-row>
        <app-rates-table-row-small [rate]="rate" (bridge)="bridge.emit(rate)"></app-rates-table-row-small>
      </ng-container>
    </tbody>
  </table>
</ng-template>
