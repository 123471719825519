import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { fromEvent } from 'rxjs';
import { Agent, Store } from 'src/utils/types';

@Component({
  selector: 'app-agent-session-button',
  templateUrl: './agent-session-button.component.html',
  styleUrls: ['./agent-session-button.component.scss'],
})
export class AgentSessionButtonComponent {
  open = false;

  constructor(private auth: AuthService) { }

  get agent() {
    return this.auth.currentAgent;
  }

  get store() {
    return this.auth.currentStore;
  }

  onClick() {
    this.open = !this.open;
    if (this.open) {
      setTimeout(() => {
        const onClick = fromEvent(document, 'click').subscribe(() => {
          onClick.unsubscribe();
          this.open = false;
        });
      });
    }
  }

  logout() {
    this.auth.logout();
  }
}
