import { Injectable } from '@angular/core';
import { UserData } from '../utils/types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  private data = null;
  changes = new Subject<UserData>();

  constructor() {
    this.readFromStorage();
  }

  readFromStorage() {
    try {
      const storedData = sessionStorage.getItem('listo-user-data');
      if (storedData) {
        this.data = JSON.parse(storedData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getData(): UserData {
    return this.data;
  }

  setData(data: UserData) {
    this.data = data;
    this.changes.next(this.data);
    try {
      sessionStorage.setItem('listo-user-data', JSON.stringify(this.data));
    } catch (e) {
      console.log(e);
    }
    return this.data;
  }

  patchData(data: UserData) {
    return this.setData({
      ...this.data,
      ...data,
    });
  }

  clearData() {
    this.setData(null);
  }
}
