import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { formHasWarning, markFormAsTouched } from '../../utils/utils';
import { WebServicesService } from '../web-services.service';
import { AutoInsuranceRate, AutoRaterSteps, Option } from '../../utils/types';
import {
  AUTO_INSURANCE_RATE_TERMS,
  AUTO_INSURANCE_STEPS,
  AUTO_INSURANCE_STRINGS,
  CARRIER_ABORT_BUTTON,
  CARRIER_ABORT_DESC,
  CONFIRM_CHANGES_BODY,
  CONFIRM_CHANGES_CANCEL,
  CONFIRM_CHANGES_CONFIRM,
  CONFIRM_CHANGES_TITLE,
  CONFIRM_DELETE,
  CONFIRM_DELETE_DRIVER_TITLE,
  CONFIRM_DELETE_VEHICLE_TITLE,
  CONFIRM_ERROR_TITLE,
  DRIVER_STRING,
  EXCLUDED_STRING,
  SAVE_AND_EXIT,
  VEHICLE_STRING,
} from '../../utils/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../modal.service';
import { AutoRaterDataService } from './auto-rater-data.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, mapTo, switchMap, tap } from 'rxjs/operators';
import { VehicleForm } from './forms';
import { AbortModalComponent } from './abort-modal/abort-modal.component';

@Component({
  selector: 'app-auto-rater',
  templateUrl: './auto-rater.component.html',
  styleUrls: ['./auto-rater.component.scss'],
  providers: [AutoRaterDataService],
})
export class AutoRaterComponent implements OnInit {
  steps = AUTO_INSURANCE_STEPS;
  AutoRaterSteps = AutoRaterSteps;
  currentStep: AutoRaterSteps = AutoRaterSteps.contact;
  currentDriverIndex = 0;
  currentVehicleIndex = 0;
  terms = AUTO_INSURANCE_RATE_TERMS;
  currentTerm = null;
  rates: AutoInsuranceRate[] = [];
  fetchQuoteLoading = false;
  saveQuoteLoading = false;
  ratesLoading = false;
  STRINGS = AUTO_INSURANCE_STRINGS;
  fieldWarningsModalOpen = false;
  isCoveragesModalOpen = false;
  isSavingChangesLoading = false;
  monthToMonthFilter = false;
  maritalWarningModalOpen = false;
  ratesProgress = 0;
  fetchRatesTrigger$ = new Subject();
  DRIVER_STRING = DRIVER_STRING;
  VEHICLE_STRING = VEHICLE_STRING;
  EXCLUDED_STRING = EXCLUDED_STRING;

  constructor(
    private webServices: WebServicesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private dataService: AutoRaterDataService,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.pipe(
      first(),
      filter(params => params.has('quoteApplicationId')),
      tap((params) => {
        this.dataService.id = params.get('quoteApplicationId');
        this.fetchQuoteLoading = true;
      }),
      switchMap(() =>
        this.webServices.getQuoteApplication(this.dataService.id),
      ),
      switchMap((quoteApplication) =>
        this.dataService.setValue(quoteApplication),
      ),
      tap(() => {
        this.fetchQuoteLoading = false;
      }),
    ).subscribe({
      next: () => {
        if (this.dataService.contact.valid) {
          if (!this.dataService.drivers.valid) {
            this.changeStep(AutoRaterSteps.drivers);
          } else if (!this.dataService.vehicles.valid) {
            this.changeStep(AutoRaterSteps.vehicles);
          } else if (this.dataService.rateId) {
            this.changeStep(AutoRaterSteps.carrier);
          } else {
            this.changeStep(AutoRaterSteps.coverages);
          }
        }
      },
      error: (e) => {
        this.modalService.quoteNotFound();
        throw e;
      },
    });

    this.fetchRatesTrigger$.pipe(
      tap(() => {
        this.rates = [];
        this.ratesProgress = 0;
        this.ratesLoading = true;
      }),
      switchMap(() =>
        this.webServices.getAutoInsuranceRates(
          this.dataService.id,
        ).pipe(
          tap({
            complete: () => {
              this.ratesLoading = false;
            },
          }),
        ),
      ),
    ).subscribe({
      next: ({ rates, progress }) => {
        this.rates = rates;
        this.ratesProgress = progress;
      },
      error: (e) => {
        this.ratesLoading = false;
        this.modalService.error();
        throw e;
      },
    });

  }

  @HostListener('window:beforeunload', ['$event']) onBeforeUnload($event) {
    if (this.dataService.dirty) {
      $event.returnValue = true;
    }
  }

  saveAndContinue() {
    this.showMaritalWarning();
    if (this.maritalWarningModalOpen) {
      return;
    }

    if (this.currentStep === AutoRaterSteps.contact && this.dataService.contact.invalid) {
      markFormAsTouched(this.dataService.contact);
      return;
    }
    if (this.currentStep === AutoRaterSteps.drivers && this.dataService.drivers.invalid) {
      markFormAsTouched(this.dataService.drivers);
      this.currentDriverIndex = this.dataService.drivers.controls.findIndex(
        driverForm => driverForm.invalid,
      );
      return;
    }
    if (this.currentStep === AutoRaterSteps.vehicles && this.dataService.vehicles.invalid) {
      markFormAsTouched(this.dataService.vehicles);
      this.currentVehicleIndex = this.dataService.vehicles.controls.findIndex(
        driverForm => driverForm.invalid,
      );
      return;
    }
    this.saveQuoteApplication().subscribe(() => {
      switch (this.currentStep) {
        case AutoRaterSteps.contact:
          this.changeStep(AutoRaterSteps.drivers);
          break;
        case AutoRaterSteps.drivers:
          this.changeStep(AutoRaterSteps.vehicles);
          break;
        case AutoRaterSteps.vehicles:
          this.changeStep(AutoRaterSteps.coverages);
          break;
        case AutoRaterSteps.carrier:
          break;
      }
    });
  }

  saveAndExit() {
    this.saveQuoteApplication().subscribe(() => {
      this.router.navigate(['autorater']);
    });
  }

  saveQuoteApplication() {
    const data = this.dataService.getValue();
    this.saveQuoteLoading = true;
    return this.webServices.saveQuoteApplication(data).pipe(tap({
      next: (id) => {
        this.dataService.id = id;
        this.dataService.markAsPristine();
        this.location.replaceState(`/autorater/quote/${id}`);
      },
      error: (e) => {
        this.modalService.error();
        throw e;
      },
      complete: () => {
        this.saveQuoteLoading = false;
      },
    }));
  }

  onRateInsuranceClick() {
    this.saveQuoteApplication().subscribe(() => {
      this.fetchRatesTrigger$.next();
    });
  }

  showMaritalWarning() {
    const marriedDrivers = this.dataService.drivers.value.filter(driver => driver.marital === 'M');
    this.maritalWarningModalOpen = !this.maritalWarningModalOpen && marriedDrivers.length % 2 === 1;
  }

  changeStep(nextStep: AutoRaterSteps) {
    if (this.currentStep === nextStep) {
      return;
    }

    if (nextStep === AutoRaterSteps.vehicles && this.dataService.nonOwner.value) {
      this.changeStep(AutoRaterSteps.coverages);
      return;
    }

    switch (this.currentStep) {
      case AutoRaterSteps.contact:
        markFormAsTouched(this.dataService.contact);
        break;
      case AutoRaterSteps.drivers:
        markFormAsTouched(this.dataService.drivers);
        break;
      case AutoRaterSteps.vehicles:
        markFormAsTouched(this.dataService.vehicles);
        break;
      case AutoRaterSteps.coverages:
        markFormAsTouched(this.dataService.coverages);
        break;
    }

    switch (nextStep) {
      case AutoRaterSteps.drivers:
        this.dataService.drivers.markAsTouched();
        break;
      case AutoRaterSteps.vehicles:
        this.dataService.vehicles.markAsTouched();
        break;
      case AutoRaterSteps.coverages:
        this.dataService.coverages.markAsTouched();
        this.dataService.effectiveDate.markAsTouched();
        this.fetchRatesTrigger$.next();
        break;
    }

    this.currentStep = nextStep;
    window.scrollTo(0, 0);
    this.fieldWarningsModalOpen = false;
  }

  canOpenStep(step: AutoRaterSteps) {
    switch (step) {
      case AutoRaterSteps.drivers:
        if (
          this.dataService.dirty
          && this.currentStep === AutoRaterSteps.contact
        ) {
          return false;
        }
        return this.dataService.drivers.touched;
      case AutoRaterSteps.vehicles:
        if (
          this.dataService.dirty
          && (
            this.currentStep === AutoRaterSteps.contact
            || this.currentStep === AutoRaterSteps.drivers
          )
        ) {
          return false;
        }

        return (
          !this.dataService.nonOwner.value
          && this.dataService.vehicles.touched
        );
      case AutoRaterSteps.coverages:
        if (
          this.dataService.dirty
          && (
            this.currentStep === AutoRaterSteps.contact
            || this.currentStep === AutoRaterSteps.drivers
            || this.currentStep === AutoRaterSteps.vehicles
          )
        ) {
          return false;
        }
        return this.dataService.coverages.touched;
      default:
        return true;
    }
  }

  stepHasWarning(step: AutoRaterSteps) {
    const contact = this.dataService.contact;
    const drivers = this.dataService.drivers;
    const vehicles = this.dataService.vehicles;

    switch (step) {
      case AutoRaterSteps.contact:
        return contact.touched && formHasWarning(contact);
      case AutoRaterSteps.drivers:
        return drivers.touched && formHasWarning(drivers);
      case AutoRaterSteps.vehicles:
        return vehicles.touched && formHasWarning(vehicles);
      default:
        return false;
    }
  }

  getStepStatus(step: AutoRaterSteps) {
    if (this.currentStep === step) {
      return 'current';
    }
    if (!this.canOpenStep(step)) {
      return 'disabled';
    }
    if (this.stepHasWarning(step)) {
      return 'warning';
    }

    return 'complete';
  }

  getDriverSelectOptions(): Option[] {
    return this.dataService.drivers.controls.map((_, i) => ({
      label: `${DRIVER_STRING} ${i + 1}`,
      value: i,
    }));
  }

  getVehicleSelectOptions(): Option[] {
    return this.dataService.vehicles.controls.map((_, i) => ({
      label: `${VEHICLE_STRING} ${i + 1}`,
      value: i,
    }));
  }

  getDriverDisplayIndex(driverControl) {
    const index = this.dataService.drivers.controls.filter(
      control => control.excluded.value === driverControl.excluded.value,
    ).findIndex(
      control => control === driverControl,
    );
    return index + 1;
  }

  getDriverFormStatus(index: number) {
    if (this.currentDriverIndex === index) {
      return 'current';
    }
    if (formHasWarning(this.dataService.drivers.controls[index])) {
      return 'warning';
    }
    return 'complete';
  }

  getVehicleFormStatus(index: number) {
    if (this.currentVehicleIndex === index) {
      return 'current';
    }
    if (formHasWarning(this.dataService.vehicles.controls[index])) {
      return 'warning';
    }
    return 'complete';
  }

  addDriver(initialData = {}) {
    window.scrollTo(0, 0);
    this.dataService.addDriver(initialData);
    this.currentDriverIndex = this.dataService.drivers.length - 1;
    this.changeStep(AutoRaterSteps.drivers);
  }

  addDriverSpouse() {
    this.addDriver({marital: 'M'});
    this.maritalWarningModalOpen = false;
  }

  deleteDriver(index) {
    this.modalService.confirm({
      title: CONFIRM_DELETE_DRIVER_TITLE,
      confirmText: CONFIRM_DELETE,
    }).pipe(
      catchError(() => of(false)),
      filter(Boolean),
    ).subscribe(() => {
      this.dataService.deleteDriver(index);
      this.currentDriverIndex--;
    });
  }

  changeDriverIndex(index) {
    this.currentDriverIndex = index;
    this.changeStep(AutoRaterSteps.drivers);
  }

  addVehicle() {
    window.scrollTo(0, 0);
    this.dataService.nonOwner.setValue(false);
    this.dataService.addVehicle();
    this.currentVehicleIndex = this.dataService.vehicles.length - 1;
    this.changeStep(AutoRaterSteps.vehicles);
  }

  deleteVehicle(index) {
    this.modalService.confirm({
      title: CONFIRM_DELETE_VEHICLE_TITLE,
      confirmText: CONFIRM_DELETE,
    }).pipe(
      catchError(() => of(false)),
      filter(Boolean),
    ).subscribe(() => {
      this.dataService.deleteVehicle(index);
      this.currentVehicleIndex--;
    });
  }

  changeVehicleIndex(index) {
    this.currentVehicleIndex = index;
    this.changeStep(AutoRaterSteps.vehicles);
  }

  getRates() {
    return this.rates.filter(
      ({ term, numberOfPayments }) =>
        (!this.currentTerm || this.currentTerm === term)
        && (!this.monthToMonthFilter || term === numberOfPayments + 1),
    );
  }

  selectRate(rate: AutoInsuranceRate) {
    if (this.dataService.warnings) {
      this.fieldWarningsModalOpen = true;
      return true;
    }

    this.dataService.carrierId = rate.carrier.id;
    this.dataService.rateId = rate.id;
    this.dataService.rateHasAbortInstructions = rate.hasAbortInstructions;
    this.changeStep(AutoRaterSteps.carrier);
  }

  canDeactivate(): Observable<boolean> {
    if (this.dataService.pristine) {
      return of(true);
    }
    return this.modalService.confirm({
      title: CONFIRM_CHANGES_TITLE,
      body: CONFIRM_CHANGES_BODY,
      confirmText: CONFIRM_CHANGES_CONFIRM,
      cancelText: CONFIRM_CHANGES_CANCEL,
    }).pipe(
      switchMap((shouldSave) => {
        if (!shouldSave) {
          return of(true);
        }

        this.isSavingChangesLoading = true;
        return this.saveQuoteApplication().pipe(
          tap(() => {
            this.isSavingChangesLoading = false;
          }),
          mapTo(true),
        );
      }),
      catchError(() => of(false)),
    );
  }

  getQuoteApplicationName() {
    const firstName = this.dataService.contact.firstName.value || '';
    const lastName = this.dataService.contact.lastName.value || '';
    if (!firstName && !lastName) {
      return '';
    }
    return `${firstName} ${lastName}`.trim();
  }

  getQuoteApplicationCreatedBy() {
    if (this.dataService.createdBy) {
      const firstName = this.dataService.createdBy.first_name || '';
      const lastName = this.dataService.createdBy.last_name || '';
      return `${firstName} ${lastName}.`.trim();
    } else {
      return null;
    }

  }

  getQuoteApplicationLastModifiedBy() {
    if (this.dataService.lastModifiedBy) {
      const firstName = this.dataService.lastModifiedBy.first_name || '';
      const lastName = this.dataService.lastModifiedBy.last_name || '';
      return `${firstName} ${lastName}.`.trim();
    } else {
      return null;
    }
  }

  isInfoAgentInQuote() {
    return this.dataService.createdBy || this.dataService.lastModifiedBy;
  }


  canDeleteVehicle(vehicleForm: VehicleForm) {
    if (vehicleForm.excluded.value) {
      return true;
    }
    return this.dataService.vehicles.controls.filter(
      vehicle => !vehicle.excluded.value,
    ).length > 1;
  }

  openCoveragesModal(event) {
    event.stopPropagation();
    this.isCoveragesModalOpen = true;
  }

  openCarrierErrorModal() {
    this.modalService.confirm({
      title: CONFIRM_ERROR_TITLE,
      body: CARRIER_ABORT_DESC,
      confirmText: CARRIER_ABORT_BUTTON,
      cancelText: SAVE_AND_EXIT,
    }).subscribe({
      next: () => {
        this.modalService.custom(AbortModalComponent, {
          hasAbortInstructions: this.dataService.rateHasAbortInstructions,
          quoteApplicationId: this.dataService.id,
          carrierId: this.dataService.carrierId,
          rateId: this.dataService.rateId,
        });
      },
      error: () => {
        this.saveAndExit();
      },
    });
  }

}
