import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DriverForm } from '../forms';
import { AUTO_INSURANCE_STRINGS } from '../../../utils/constants';

@Component({
  selector: 'app-driver-small-form',
  templateUrl: './driver-small-form.component.html',
  styleUrls: ['./driver-small-form.component.scss'],
})
export class DriverSmallFormComponent {
  @Input() form: DriverForm;
  @Input() canDelete = false;
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  STRINGS = AUTO_INSURANCE_STRINGS;

  getName() {
    const firstName = this.form.firstName.value;
    const lastName = this.form.lastName.value;
    return `${firstName} ${lastName}`;
  }

  getAge() {
    return this.form.age.value;
  }
}
