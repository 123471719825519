<listo-checkbox
  [label]="STRINGS.excludeDriver"
  [control]="form.excluded"
  [disabled]="!canDelete"
  [tooltip]="TOOLTIPS.excluded"
></listo-checkbox>
<listo-input
  [label]="STRINGS.firstName"
  [control]="form.firstName"
></listo-input>
<listo-input
  [label]="STRINGS.lastName"
  [control]="form.lastName"
></listo-input>
<div class="three-column">
  <listo-date-picker
    class="input birthdate"
    [label]="STRINGS.birthdate"
    [control]="form.birthdate"
  ></listo-date-picker>
  <listo-input
    [label]="STRINGS.age"
    [control]="form.age"
  ></listo-input>
</div>
<listo-radios
  [label]="STRINGS.sex"
  [control]="form.sex"
  [options]="genders"
  cols="3"
></listo-radios>
<listo-radios
  [label]="STRINGS.marital"
  [control]="form.marital"
  [options]="civilStatuses"
  cols="3"
></listo-radios>
<listo-select
  *ngIf="shouldShowOtherCivilStatuses()"
  [label]="STRINGS.marital2"
  [options]="otherCivilStatuses"
  [control]="form.otherMarital"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<listo-select
  *ngIf="canDelete"
  [label]="STRINGS.primaryDriverRelationship"
  [options]="relationships"
  [control]="form.primaryDriverRelationship"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<ng-container *ngIf="!form.excluded.value">
  <listo-select
    [label]="STRINGS.race"
    [options]="races"
    [control]="form.race"
    [placeholder]="PLACEHOLDERS.option"
  ></listo-select>
  <listo-dropdown
    [label]="STRINGS.industry"
    [options]="industries"
    [control]="form.industry"
    [placeholder]="PLACEHOLDERS.option"
  ></listo-dropdown>
  <listo-dropdown
    [label]="STRINGS.occupation"
    [options]="getOccupations()"
    [control]="form.occupation"
    [placeholder]="PLACEHOLDERS.option"
  ></listo-dropdown>
  <hr>
  <listo-radios
    [label]="STRINGS.priorInsurance"
    [control]="form.priorInsurance"
    [options]="yesno"
  ></listo-radios>
  <ng-container *ngIf="hasPriorInsurance()">
    <div class="small-inputs-container">
      <span class="label">{{STRINGS.priorInsuranceTime}}</span>
      <listo-input [control]="form.priorInsuranceYears"></listo-input>
      <span class="small-label">{{STRINGS.years}}</span>
      <listo-input [control]="form.priorInsuranceMonths"></listo-input>
      <span class="small-label">{{STRINGS.months}}</span>
    </div>
    <listo-date-picker
      [label]="STRINGS.priorInsuranceExpDate"
      [control]="form.priorInsuranceExpDate"
    ></listo-date-picker>
  </ng-container>
  <hr>
  <listo-radios
    [label]="STRINGS.dmvCertification"
    [control]="form.dmvCertification"
    [options]="dmvCertifications"
    cols="2"
    tooltip="Certificate of financial responsibility required to some drivers by the Department of Motor Vehicles (DMV)."
    i18n-tooltip
  ></listo-radios>
  <ng-container *ngIf="hasDmv()">
    <listo-select
      [label]="STRINGS.dmvCertificationState"
      [options]="states"
      [control]="form.dmvCertificationState"
    ></listo-select>
    <listo-select
      [label]="STRINGS.dmvCertificationReason"
      [options]="dmvCertificationReasons"
      [control]="form.dmvCertificationReason"
    ></listo-select>
  </ng-container>
  <hr>
  <listo-select
    [label]="STRINGS.licenseType"
    [options]="types"
    [control]="form.licenseType"
    [placeholder]="PLACEHOLDERS.option"
  ></listo-select>
  <listo-select
    [label]="STRINGS.licenseStatus"
    [options]="statuses"
    [control]="form.licenseStatus"
    [placeholder]="PLACEHOLDERS.option"
  ></listo-select>
  <div *ngIf="shouldShowNumber()" class="row">
    <listo-input
      [label]="STRINGS.licenseNumber"
      [control]="form.licenseNumber"
      placeholder="A1234567"
    ></listo-input>
    <listo-select
      [label]="STRINGS.state"
      [control]="form.licenseState"
      [options]="states"
      [placeholder]="PLACEHOLDERS.state"
    ></listo-select>
  </div>
  <hr>
  <div class="small-inputs-container">
    <span class="label">{{STRINGS.licenseTime}}</span>
    <listo-input [control]="form.licenseYears"></listo-input>
    <span class="small-label">{{STRINGS.years}}</span>
    <listo-input [control]="form.licenseMonths"></listo-input>
    <span class="small-label">{{STRINGS.months}}</span>
  </div>
  <div class="small-inputs-container">
    <span class="label">{{STRINGS.totalLicenseTime}}</span>
    <listo-input [control]="form.totalLicenseYears"></listo-input>
    <span class="small-label">{{STRINGS.years}}</span>
    <listo-input [control]="form.totalLicenseMonths"></listo-input>
    <span class="small-label">{{STRINGS.months}}</span>
  </div>
  <hr>
  <listo-radios
    [label]="STRINGS.hasMatureDriverCourse"
    [control]="form.hasMatureDriverCourse"
    [options]="yesno"
    cols="2"
    [tooltip]="TOOLTIPS.hasMatureDriverCourse"
  ></listo-radios>
  <listo-date-picker
    *ngIf="form.hasMatureDriverCourse.value"
    [label]="STRINGS.matureDriverCourseDate"
    [control]="form.matureDriverCourseDate"
  ></listo-date-picker>
  <listo-radios
    [label]="STRINGS.isGoodStudent"
    [control]="form.isGoodStudent"
    [options]="yesno"
    cols="2"
    [tooltip]="TOOLTIPS.isGoodStudent"
  ></listo-radios>
  <hr>
  <listo-checkbox
    *ngIf="!canDelete"
    [control]="dataService.nonOwner"
    [label]="STRINGS.nonOwner"
    [tooltip]="TOOLTIPS.nonOwner"
  ></listo-checkbox>
</ng-container>
<div *ngIf="canDelete" class="delete-button-container">
  <listo-button [flat]="true" (click)="delete.emit()" i18n>Delete</listo-button>
</div>
