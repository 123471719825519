import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';
import * as CONSTANTS from '../../../utils/constants';
import { WebServicesService } from '../../web-services.service';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'auto-rater-abort-modal',
  templateUrl: './abort-modal.component.html',
  styleUrls: ['./abort-modal.component.scss'],
})
export class AbortModalComponent implements OnInit {

  CONSTANTS = CONSTANTS;
  loading = true;
  abortData: {url: string, instructions?: string};
  newWindow: Window;

  @Input() hasAbortInstructions: boolean;
  @Input() quoteApplicationId: string;
  @Input() carrierId: string;
  @Input() rateId: string;
  @Output() close = new EventEmitter();

  constructor(
    private webServices: WebServicesService,
    private modalService: ModalService,
  ) {}

  async ngOnInit() {
    if (!this.hasAbortInstructions) {
      this.createWindow();
    }
    this.getAbortData();
  }

  getAbortData() {
    this.webServices.abortQuoteApplication(
      this.quoteApplicationId,
      this.carrierId,
      this.rateId,
    ).subscribe(
      (abortResults) => {
        this.loading = false;
        this.abortData = abortResults;

        if (!this.hasAbortInstructions) {
          this.openAbortUrl();
        }
      },
      e => {
        this.loading = false;
        this.close.emit();
        this.modalService.error();
        throw e;
      },
    );
  }

  createWindow() {
    this.newWindow = window.open('', '_blank');
    this.newWindow.document.write(CONSTANTS.LOADING);
  }

  openAbortUrl() {
    if (!this.newWindow) {
      this.createWindow();
    }
    this.newWindow.location.href = this.abortData.url;
  }

}
