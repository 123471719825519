import {
  AutoInsuranceDriver,
  AutoInsuranceRate,
  AutoInsuranceQuoteApplication,
  AutoInsuranceVehicle,
  Option,
  UserStatistic,
  AgentInQuote,
  LoadQuoteApplicationResponse,
} from './types';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { getOptionLabel } from './options';
import { conformToMask } from 'angular2-text-mask';
import { phoneMask } from './maks';
import CustomFormControl from '../app/auto-rater/CustomFormControl';

export const appendAutoInsuranceTotalData = (statistic): UserStatistic => ({
  ...statistic,
  autoInsurance: {
    ...statistic.autoInsurance,
    total: {
      current: statistic.autoInsurance.reactivations.current + statistic.autoInsurance.new.current,
      monthlyGoal: statistic.autoInsurance.reactivations.monthlyGoal + statistic.autoInsurance.new.monthlyGoal,
      dayGoal: statistic.autoInsurance.reactivations.dayGoal + statistic.autoInsurance.new.dayGoal,
      incentivesGoal: statistic.autoInsurance.reactivations.incentivesGoal + statistic.autoInsurance.new.incentivesGoal,
    },
  },
});

export const markFormAsTouched = (form: AbstractControl) => {
  form.markAsTouched();
  if ((<FormGroup>form).controls) {
    Object.values((<FormGroup>form).controls).forEach(markFormAsTouched);
  }
};

export const formHasWarning = (form: AbstractControl) => {
  if (form instanceof CustomFormControl) {
    return form.warnings;
  }
  if (form instanceof FormArray) {
    return form.controls.some(formHasWarning);
  }
  if (form instanceof FormGroup) {
    return Object.values(form.controls).some(formHasWarning);
  }
  return false;
};

export const getKeysWithWarnings = (form: FormGroup) => {
  return Object.entries(
    form.controls,
  ).filter(([, control]) =>
    !control.disabled && (<CustomFormControl>control).warnings,
  ).map(([key]) => key);
};

export const getKeysWithErrors = (form: FormGroup) => {
  return Object.entries(
    form.controls,
  ).filter(([, control]) =>
    !control.disabled && (<CustomFormControl>control).errors,
  ).map(([key]) => key);
};

export const clearFormArray = (formArray: FormArray) => {
  formArray.controls.forEach((_, i) => {
    formArray.removeAt(i);
  });
};

export const setFormArrayItems = (formArray: FormArray, items: any[]) => {
  clearFormArray(formArray);
  items.forEach(item => {
    formArray.push(item);
  });
};

export const getYearsDiff = (date: Date): number => {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  let diff = currentYear - year;

  if (currentMonth < month || (currentMonth === month && currentDay < day)) {
    diff--;
  }

  return diff;
};

export const getMonthsDiff = (date: Date): number => {
  const currentDate = new Date();

  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const month = date.getMonth();
  const day = date.getDate();

  let diff = currentMonth - month;

  if (month > currentMonth) {
    diff += 12;
  }

  if (!diff && currentDay >= day) {
    diff++;
  }

  return diff;
};

const dateToSeconds = (dateField: Date): number => {
  return dateField ? Math.floor(dateField.getTime() / 1000) : null;
};

const secondsToDate = (seconds: number): Date => {
  return seconds ? new Date(seconds * 1000) : null;
};

const FTBMapInsuranceDriver = (driver) => {
  const mappedDriver = {
    ...driver,
    excluded: Boolean(driver.excluded),
    birthdate: dateToSeconds(driver.birthdate),
    age: Number(driver.age) || null,
    priorInsuranceExpDate: dateToSeconds(driver.priorInsuranceExpDate),
    priorInsuranceMonths: (Number(driver.priorInsuranceMonths) + Number(driver.priorInsuranceYears) * 12) || null,
    licenseMonths: (Number(driver.licenseMonths) + Number(driver.licenseYears) * 12) || null,
    totalLicenseMonths: (Number(driver.totalLicenseMonths) + Number(driver.totalLicenseYears) * 12) || null,
    matureDriverCourseDate: dateToSeconds(driver.matureDriverCourseDate),
  };

  if (mappedDriver.marital !== 'M' && mappedDriver.marital !== 'S') {
    mappedDriver.marital = mappedDriver.otherMarital;
  }

  return mappedDriver;
};

const BTFMapInsuranceDriver = ({ birthdate, age, excluded, ...driver }) => {
  const mappedDriver: AutoInsuranceDriver = {
    ...driver,
    priorInsuranceExpDate: secondsToDate(driver.priorInsuranceExpDate),
    priorInsuranceYears: driver.priorInsuranceMonths ? Math.floor(driver.priorInsuranceMonths / 12) : 0,
    priorInsuranceMonths: driver.priorInsuranceMonths ? driver.priorInsuranceMonths % 12 : null,
    licenseStatus: driver.licenseStatus,
    licenseType: driver.licenseType,
    licenseNumber: driver.licenseNumber,
    licenseState: driver.licenseState,
    licenseYears: driver.licenseMonths ? Math.floor(driver.licenseMonths / 12) : 0,
    licenseMonths: driver.licenseMonths ? driver.licenseMonths % 12 : null,
    totalLicenseYears: driver.totalLicenseMonths ? Math.floor(driver.totalLicenseMonths / 12) : 0,
    totalLicenseMonths: driver.totalLicenseMonths ? driver.totalLicenseMonths % 12 : null,
    age,
    birthdate: secondsToDate(birthdate),
    matureDriverCourseDate: secondsToDate(driver.matureDriverCourseDate),
    excluded,
  };

  if (mappedDriver.marital !== 'M' && mappedDriver.marital !== 'S') {
    mappedDriver.otherMarital = mappedDriver.marital;
    mappedDriver.marital = '';
  }

  return mappedDriver;
};

const FTBMapInsuranceVehicle = (vehicle: AutoInsuranceVehicle) => {
  return {
    ...vehicle,
    excluded: Boolean(vehicle.excluded),
    workMiles: Number(vehicle.workMiles) || null,
    annualMiles: Number(vehicle.annualMiles) || null,
    towingDed: vehicle.towingDed ? 100 : null,
    purchaseDate: dateToSeconds(vehicle.purchaseDate),
  };
};

const BTFMapInsuranceVehicle = ({ coverageType, ...vehicle }): AutoInsuranceVehicle => {
  return {
    ...vehicle,
    excluded: vehicle.excluded,
    primaryOperator: vehicle.primaryOperator || 0,
    coverageType,
    towingDed: Boolean(vehicle.towingDed),
    purchaseDate: secondsToDate(vehicle.purchaseDate),
  };
};

const FTBMapInsuranceCoverages = (coverages) => {
  const [liabBILimit0, liabBILimit1] = coverages.liabBILimit ? coverages.liabBILimit.split('/') : [null, null];
  const [uninsBILimit0, uninsBILimit1] = coverages.uninsBILimit ? coverages.uninsBILimit.split('/') : [null, null];
  return {
    ...coverages,
    liabBILimit: undefined,
    uninsBILimit: undefined,
    liabBILimit0,
    liabBILimit1,
    uninsBILimit0,
    uninsBILimit1,
    uninsPD: Boolean(coverages.uninsPD),
  };
};

const BTFMapInsuranceCoverages = (coverages) => {
  let liabBILimit = null;
  let uninsBILimit = null;

  if (coverages.liabBILimit0 && coverages.liabBILimit1) {
    liabBILimit = `${coverages.liabBILimit0}/${coverages.liabBILimit1}`;
  }

  if (coverages.uninsBILimit0 && coverages.uninsBILimit1) {
    uninsBILimit = `${coverages.uninsBILimit0}/${coverages.uninsBILimit1}`;
  }

  return {
    ...coverages,
    liabBILimit,
    uninsBILimit,
  };
};

export const formatPhone = (phone) => {
  if (!phone) {
    return '';
  }
  return conformToMask(phone, phoneMask, {}).conformedValue;
};

const BTFMapInsuranceContact = (contact) => {
  return {
    ...contact,
    phoneNumber: contact.phoneNumber && formatPhone(contact.phoneNumber),
  };
};

const FTBMapInsuranceQuestions = (questions) => {
  return Object.entries(questions).map(([question, answer]) => ({
    question,
    answer: String(answer),
  }));
};

const BTFMapInsuranceQuestions = (questions = []) => {
  const formattedQuestions = {};
  questions.forEach(({ question, answer }) => {
    formattedQuestions[question] = answer;
  });
  return formattedQuestions as any;
};

export const FTBMapInsuranceData = (data: AutoInsuranceQuoteApplication) => {
  return {
    id: data.id,
    contact: data.contact,
    drivers: data.drivers.map(FTBMapInsuranceDriver),
    vehicles: data.vehicles.map(FTBMapInsuranceVehicle),
    coverages: FTBMapInsuranceCoverages(data.coverages),
    nonOwner: data.nonOwner,
    effectiveDate: dateToSeconds(data.effectiveDate),
    carrierId: data.carrierId,
    rateId: data.rateId,
    rateHasAbortInstructions: data.rateHasAbortInstructions || false,
    createdAt: data.createdAt,
    relatedDrivers: data.relatedDrivers,
    incidents: [],
    underwritingQuestions: FTBMapInsuranceQuestions(data.underwritingQuestions),
  };
};

export const BTFMapInsuranceData = (data, agents): AutoInsuranceQuoteApplication => {
  return {
    id: data.id,
    contact: BTFMapInsuranceContact(data.contact),
    drivers: data.drivers.map(BTFMapInsuranceDriver),
    vehicles: data.vehicles.map(BTFMapInsuranceVehicle),
    coverages: BTFMapInsuranceCoverages(data.coverages),
    nonOwner: Boolean(data.nonOwner),
    effectiveDate: secondsToDate(data.effectiveDate),
    carrierId: data.carrierId,
    rateId: data.rateId,
    rateHasAbortInstructions: data.rateHasAbortInstructions || false,
    createdBy: agents[0],
    lastModifiedBy: agents[1],
    underwritingQuestions: BTFMapInsuranceQuestions(data.questions)
  };
};

export const BTFMapAgentData = (data): AgentInQuote => {
  return {
    lendify_account: data.lendify_account,
     email: data.email,
    store_id: data.store_id,
    agent_uid: data.agent_uid,
    last_name: data.last_name,
    first_name: data.first_name,
    image: data.image
  };
};


export const sortOptionsAlph = (a: Option, b: Option) => {
  const aLabel = String(getOptionLabel(a)).toLowerCase();
  const bLabel = String(getOptionLabel(b)).toLowerCase();
  return aLabel.localeCompare(bLabel);
};

export const sortRates = (a: AutoInsuranceRate, b: AutoInsuranceRate): number => {
  // Order first by annual total and then by initial payment
  if (a.annualTotal < b.annualTotal) return -1;
  if (a.annualTotal > b.annualTotal) return 1;
  if (a.downPayment < b.downPayment) return -1;
  if (a.downPayment > b.downPayment) return 1;
  return 0;
};

export const copyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.top = '100%';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const getLimitFirstValue = (limit: string): number => {
  if (!limit) {
    return 0;
  }
  return Number(limit.split('/')[0]) || 0;
};

export const startOfDay = (date: Date = new Date()): Date => {
  const newDate = new Date(date.getTime());
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getSegmentPage = () => {
  const page = window.location.pathname;
  switch (page) {
    case '/tools':
      return 'AutoInsuranceRater ToolsPage';
    case '/autorater':
      return 'AutoInsuranceRater AutoRaterPage';
    case '/profile':
      return 'AutoInsuranceRater ProfilePage';
    default:
      return page;
  }
};
