<h1 class="header" i18n>Tools</h1>
<listo-card
  *ngFor="let section of sections; index as i"
  [header]="section.sectionTitle"
  class="card"
  [collapsible]="true"
  [collapsed]="collapsed[i]"
  (toggleCollapseChange)="collapsed[i] = !collapsed[i]"
>
  <div class="body" *ngIf="!collapsed[i]">
    <ng-container *ngFor="let tool of section.tools">
      <a *ngIf="tool.external" class="tool" [href]="tool.url" target="_blank">
        <ng-container [ngTemplateOutlet]="toolTemp" [ngTemplateOutletContext]="{ tool:tool }"></ng-container>
      </a>
      <a *ngIf="!tool.external" class="tool" [routerLink]="tool.url">
        <ng-container [ngTemplateOutlet]="toolTemp" [ngTemplateOutletContext]="{ tool:tool }"></ng-container>
      </a>
    </ng-container>
  </div>
</listo-card>

<ng-template #toolTemp let-tool="tool">
  <div class="icon-wrapper">
    <img [src]="tool.icon" />
  </div>
  <div>{{ tool.title }}</div>
</ng-template>
