import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyPerformanceComponent } from './my-performance/my-performance.component';
import { StorePerformanceComponent } from './store-performance/store-performance.component';
import { ToolsComponent } from './tools/tools.component';
import { AutoInsuranceDashboardComponent } from './auto-insurance-dashboard/auto-insurance-dashboard.component';
import { ListoCommonModule } from './listo-common/listo-common.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab/tab.component';
import { TopbarComponent } from './topbar/topbar.component';
import { IconMeaningsComponent } from './icon-meanings/icon-meanings.component';
import { UserProgressLabelComponent } from './user-progress-label/user-progress-label.component';
import { MonthlyProgressBarComponent } from './monthly-progress-bar/monthly-progress-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserStatisticModalComponent } from './user-statistic-modal/user-statistic-modal.component';
import { UserStatisticComponent } from './user-statistic/user-statistic.component';
import { AgentSessionButtonComponent } from './agent-session-button/agent-session-button.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoutComponent } from './logout/logout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoRaterModule } from './auto-rater/auto-rater.module';
import { ConfirmModalComponent } from './listo-common/confirm-modal/confirm-modal.component';
import { AgentLinksComponent } from './agent-links/agent-links.component';
import { ProfileComponent } from './profile/profile.component';
import { ErrorModalComponent } from './listo-common/error-modal/error-modal.component';
import { NotFoundModalComponent } from './listo-common/not-found-modal/not-found-modal.component';
import { NotificationsButtonComponent } from './notifications-button/notifications-button.component';
import { ListoErrorHandlerService } from './listo-common/listo-error-handler.service';

@NgModule({
  declarations: [
    AgentSessionButtonComponent,
    AppComponent,
    AutoInsuranceDashboardComponent,
    DashboardComponent,
    IconMeaningsComponent,
    LoadingComponent,
    LoginComponent,
    LogoutComponent,
    MonthlyProgressBarComponent,
    MyPerformanceComponent,
    SidebarComponent,
    StorePerformanceComponent,
    TabComponent,
    ToolsComponent,
    TopbarComponent,
    UserProgressLabelComponent,
    UserStatisticComponent,
    UserStatisticModalComponent,
    AgentLinksComponent,
    ProfileComponent,
    NotificationsButtonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ListoCommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoRaterModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: ListoErrorHandlerService }],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmModalComponent,
    ErrorModalComponent,
    NotFoundModalComponent,
  ],
})
export class AppModule {}
