import { Component } from '@angular/core';
import { AppRoute } from '../../utils/types';
import { dashboardRoutes } from '../app-routing.module';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  routes: AppRoute[] = dashboardRoutes.filter(({ showInSidebar }) => showInSidebar);

  constructor(private auth: AuthService) {}

  onLogoutClick() {
    this.auth.logout();
  }
}
