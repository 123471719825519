import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as STRINGS from '../../../utils/constants';
import { AutoRaterDataService } from '../auto-rater-data.service';
import { AutoRaterRelatedDriver } from 'src/utils/types';

@Component({
  selector: 'auto-rater-carrier-drivers',
  templateUrl: './carrier-drivers.component.html',
  styleUrls: ['./carrier-drivers.component.scss']
})
export class CarrierDriversComponent implements OnInit {
  @Output() newDrivers = new EventEmitter();
  STRINGS = STRINGS;
  relatedDrivers: any[] = [];

  constructor(
    private dataService: AutoRaterDataService
  ) { }

  ngOnInit() {
    const relatedDrivers = this.dataService.lastSelectedRate.relatedDrivers || [];
    this.relatedDrivers = relatedDrivers.map(driver => ({
      name: driver,
    }));
  }

  changeRelatedDriverType(event, index) {
    const driver = this.relatedDrivers[index];
    driver.type = event;

    if (this.isDriverAdded(index)) {
      this.changeDriverType(index);
      return;
    }

    const driverData = {
      firstName: driver.name.split(' ')[0],
      lastName: driver.name.split(' ')[1],
    };

    switch (driver.type) {
      case AutoRaterRelatedDriver.driver:
        this.dataService.addDriver({
          ...driverData,
          relatedDriver: driver.name,
          excluded: false
        });
        this.newDrivers.emit(true);
        break;
      case AutoRaterRelatedDriver.excluded:
        this.dataService.addDriver({
          ...driverData,
          relatedDriver: driver.name,
          excluded: true
        });
        break;
      default:
        this.addRelatedDriver(driver);
    }
  }

  isDriverAdded(index) {
    return this.dataService.drivers.controls.some(driverInQuote => {
      return driverInQuote.firstName.value + ' ' + driverInQuote.lastName.value === this.relatedDrivers[index].name;
    });
  }

  addRelatedDriver(driver) {
    const data = this.dataService.getValue();
    // TODO there should be an addRelatedDriver method in the service
    this.dataService.setValue({
      ...data,
      relatedDrivers: [
        ...data.relatedDrivers,
        {
          name: driver.name,
          relationshipType: driver.type,
        }
      ]
    });
  }

  changeDriverType(i) {
    const index = this.dataService.drivers.controls.findIndex(driverInQuote => {
      return driverInQuote.firstName.value + ' ' + driverInQuote.lastName.value === this.relatedDrivers[i].name;
    });
    const driver = this.relatedDrivers[index];
    switch (driver.type) {
      case 'excluded':
        this.dataService.drivers.controls[index].excluded.setValue(true);
        break;
      case 'driver':
        this.dataService.drivers.controls[index].excluded.setValue(false);
        break;
      default:
        this.dataService.deleteDriver(i);
        this.addRelatedDriver(driver);
    }
  }

}

