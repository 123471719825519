<div class="label">
  <span *ngIf="getError()" class="icon-focus error"></span>
  <span *ngIf="getWarning()" class="icon-focus warning"></span>
  {{label}}
  <img *ngIf="isRequired()" class="required" src="../../../assets/images/required.svg">
  <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip" (click)="tooltipClick.emit()"></listo-tooltip>
  <listo-tooltip *ngIf="hasTemplateTooltip()" (click)="tooltipClick.emit()">
    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </listo-tooltip>
</div>
<div class="options" [ngStyle]="{ 'grid-template-columns' : 'repeat('+cols+', 1fr)' }">
  <button
    *ngFor="let option of options"
    class="option"
    (click)="onOptionClick(getOptionValue(option))"
    [class.selected]="isOptionSelected(option)"
    [class.error]="getError()"
    [class.warning]="getWarning()"
    [disabled]="isDisabled()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect
        width="15"
        height="15"
        x=".5"
        y=".5"
        fill="#FFF"
        [attr.stroke]="isOptionSelected(option) && !isDisabled() ? '#F58D2E' :'#E1E5E9'"
        rx="7.5"
      />
      <circle
        *ngIf="isOptionSelected(option)"
        cx="8"
        cy="8"
        r="4"
        [attr.fill]="isDisabled() ? '#E1E5E9' :'#F58D2E'"
      />
    </svg>
    {{getOptionLabel(option)}}
  </button>
</div>
<listo-error-label [error]="getError()"></listo-error-label>
<listo-error-label *ngIf="getWarning() && !getError()" [error]="getWarning()" class="warning-label"></listo-error-label>

