import { Option, OptionObject } from './types';

const isObject = (option: Option): option is OptionObject => {
  return typeof option !== 'string' && typeof option !== 'number';
};

export const getOptionLabel = (option: Option): string => {
  if (isObject(option)) {
    return option.label;
  }
  return String(option);
};

export const getOptionValue = (option: Option): any => {
  if (isObject(option)) {
    return option.value;
  }
  return option;
};
