import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { AutoInsuranceIncident, AutoInsuranceRate } from '../../../utils/types';
import * as CONSTANTS from '../../../utils/constants';

@Component({
  selector: 'auto-rater-rate-change-modal',
  templateUrl: './rate-change-modal.component.html',
  styleUrls: ['./rate-change-modal.component.scss'],
})
export class RateChangeModalComponent {

  CONSTANTS = CONSTANTS;

  @Input() newRate: AutoInsuranceRate;
  @Output() close = new EventEmitter();
  @Output() continueInCarrier = new EventEmitter();
  @Output() backToCoverages = new EventEmitter();

  rateHasIncidents(): boolean {
    return this.newRate.incidents && this.newRate.incidents.length > 0;
  }

  incidentsByDriver() {
    const incidentGroups = Object.entries(
        this.newRate.incidents.reduce((accum, incident) => {
          incident.driverName = incident.driverName || null;
          if (!accum[incident.driverName]) {
            accum[incident.driverName] = [];
          }
          accum[incident.driverName].push(incident);
          return accum;
        }, {})
      )
      .map(entry => ({driverName: entry[0] === 'null' ? null : entry[0], incidents: entry[1]}));
    return incidentGroups as {driverName: string, incidents: AutoInsuranceIncident[]}[];
  }

  // TODO we should make an Angular Pipe out of this
  formatDate(date) {
    if (typeof date === 'number') {
      date = new Date(date * 1000);
    }
    return String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getDate() + '/' + date.getFullYear();
  }
}
