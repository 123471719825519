<td>
  {{rate.carrier.name}}
  <ng-container *ngIf="rate.program">{{rate.program}}</ng-container>
  <div class="small">{{rate.plan}}</div>
</td>
<td>{{rate.term}} <span i18n>months</span></td>
<td>${{rate.downPayment | currency:'':'':'1.0-0':'en-US'}}</td>
<td>${{rate.paymentAmount | currency:'':'':'1.0-0':'en-US'}}</td>
<td>{{rate.numberOfPayments}}</td>
<td>${{rate.total | currency:'':'':'1.0-0':'en-US'}}</td>
<td>${{rate.annualTotal | currency:'':'':'1.0-0':'en-US'}}</td>
<td>
  <listo-button [invertColor]="true" (click)="bridge.emit()" i18n>Continue</listo-button>
</td>
