import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

declare const google;

interface GooglePlace {
  address_components: {
    long_name: string;
    short_name: string;
    types: 'locality'
      | 'street_number'
      | 'route'
      | 'locality'
      | 'country'
      | 'postal_code'
      | 'administrative_area_level_1'
      | 'administrative_area_level_2';
  }[];
}

export interface Address {
  street?: string;
  number?: string;
  city?: string;
  county?: string;
  state?: string;
  country?: string;
  zipCode?: string;
}

@Directive({
  selector: '[listoAddressAutoComplete]',
})
export class AddressAutoCompleteDirective implements OnInit {
  @Output() addressChanged = new EventEmitter<Address>();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const input = this.el.nativeElement.querySelector('input');
    if (!input) {
      throw Error('Input element not found for autocomplete');
    }

    const autocomplete = new google.maps.places.Autocomplete(input);

    autocomplete.addListener('place_changed', () => {
      const place: GooglePlace = autocomplete.getPlace();
      const address = formatAddress(place);
      this.addressChanged.emit(address);
    });
  }
}

function formatAddress(place: GooglePlace): Address {
  let street;
  let number;
  let city;
  let county;
  let state;
  let country;
  let zipCode;

  for (const { types, short_name, long_name } of place.address_components) {
    if (types.includes('street_number')) {
      number = long_name;
    }

    if (types.includes('route')) {
      street = long_name;
    }

    if (types.includes('locality')) {
      city = long_name;
    }

    if (types.includes('administrative_area_level_1')) {
      state = short_name;
    }

    if (types.includes('administrative_area_level_2')) {
      county = long_name;
    }

    if (types.includes('country')) {
      country = short_name;
    }

    if (types.includes('postal_code')) {
      zipCode = long_name;
    }
  }

  return {
    street,
    number,
    city,
    county,
    state,
    country,
    zipCode,
  };
}
