import { Component } from '@angular/core';
import {
  AUTO_INSURANCE_STRINGS,
  AUTO_INSURANCE_TOOLTIPS,
  COVERAGE_LIMITS,
  LIABILITY_PD_COVERAGES,
  MED_COVERAGES,
  YESNO,
} from '../../../utils/constants';
import { CoveragesForm } from '../forms';
import { AutoRaterDataService } from '../auto-rater-data.service';
import { getLimitFirstValue } from '../../../utils/utils';

@Component({
  selector: 'app-coverages-form',
  templateUrl: './coverages-form.component.html',
  styleUrls: ['./coverages-form.component.scss'],
})
export class CoveragesFormComponent {
  form: CoveragesForm = this.dataService.coverages;
  coverageLimits = COVERAGE_LIMITS;
  liabPDCoverages = LIABILITY_PD_COVERAGES;
  medCoverages = MED_COVERAGES;
  yesno = YESNO;
  STRINGS = AUTO_INSURANCE_STRINGS;
  TOOLTIPS = AUTO_INSURANCE_TOOLTIPS;

  constructor(private dataService: AutoRaterDataService) {}

  getUninsLimits() {
    const liabBILimit = this.form.liabBILimit.value;
    if (!liabBILimit) {
      return this.coverageLimits;
    }

    return this.coverageLimits.filter(limit => {
      return getLimitFirstValue(limit.value) <= getLimitFirstValue(liabBILimit);
    });
  }
}
