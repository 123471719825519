<listo-select
  (click)="openSearchVinDialog()"
  [label]="STRINGS.year"
  [options]="years"
  [control]="form.year"
  [pending]="yearsLoading"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<listo-select
  (click)="openSearchVinDialog()"
  [label]="STRINGS.maker"
  [options]="makers"
  [control]="form.maker"
  [pending]="makersLoading"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<listo-select
  (click)="openSearchVinDialog()"
  [label]="STRINGS.model"
  [options]="models"
  [control]="form.model"
  [pending]="modelsLoading"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<listo-input
  [label]="STRINGS.vin"
  [control]="form.vin"
  tooltip="The Vehicle Identification Number is the sequence of digits that identifies vehicles of any kind. It is usually found in the lower left corner of the dashboard and in the vehicle registration."
  i18n-tooltip
  placeholder="1G6AY5SS0H"
></listo-input>
<button class="search-vin" (click)="openSearchVinDialog()" i18n>Search VIN</button>
<listo-date-picker
  [label]="STRINGS.purchaseDate"
  [control]="form.purchaseDate"
></listo-date-picker>
<div class="auto-columns">
  <listo-input
    [label]="STRINGS.annualMiles"
    [control]="form.annualMiles"
    placeholder="12,500"
    tooltip="Average number of miles traveled per year. A vehicle that is used in a common way oscillates between the 12,000 and 15,000 annual miles."
    i18n-tooltip
  ></listo-input>
  <listo-input
    [label]="STRINGS.workMiles"
    [control]="form.workMiles"
    placeholder="20"
    tooltip="It is the number of one way miles to work."
    i18n-tooltip
  ></listo-input>
  <listo-input
    [label]="STRINGS.odometer"
    [control]="form.odometer"
    [tooltip]="TOOLTIPS.odometer"
    placeholder="12,500"
  ></listo-input>
</div>
<listo-select
  [label]="STRINGS.usage"
  [options]="usages"
  [control]="form.usage"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
<listo-radios
  [label]="STRINGS.homingDevice"
  [control]="form.homingDevice"
  [options]="yesno"
  tooltip="Any electronic device installed in the vehicle that tracks, evaluates and reports about the vehicle's location and/or activity."
  i18n-tooltip
></listo-radios>
<listo-select
  [label]="STRINGS.antiTheftDevice"
  [options]="antiTheftDevices"
  [control]="form.antiTheftDevice"
  [placeholder]="PLACEHOLDERS.option"
  [tooltip]="TOOLTIPS.antiTheftDevice"
></listo-select>
<listo-radios
  [label]="STRINGS.salvaged"
  [control]="form.salvaged"
  [options]="yesno"
  tooltip="Are the vehicles cataloged by the DMV, department of vehicles, as &quot;Salvaged&quot;. The vehicle title includes the word &quot;Salvaged&quot;."
  i18n-tooltip
></listo-radios>
<listo-radios
  [label]="STRINGS.rideShare"
  [control]="form.rideShare"
  [options]="rideShareOptions"
  [cols]="3"
  tooltip="It refers to those vehicles meant for commercial purposes such as Uber, Lyft or similar tranportation services"
  i18n-tooltip
></listo-radios>
<listo-select
  [label]="STRINGS.primaryOperator"
  [options]="getDrivers()"
  [control]="form.primaryOperator"
  [tooltip]="TOOLTIPS.primaryOperator"
></listo-select>
<span class="small-label"><b>Importante</b>: El propietario del vehículo debe incluirse en la aplicación como conductor o excluido.</span>
<div *ngIf="canDelete" class="delete-button-container">
  <listo-button [flat]="true" (click)="delete.emit()" i18n>Delete</listo-button>
</div>

<listo-modal class="seach-vin-modal" *ngIf="searchVinDialogOpen" (close)="closeSearchVinDialogOpen()">
  <h1 i18n>Search VIN</h1>
  <div class="search-vin-form">
    <listo-dropdown
      [label]="STRINGS.year"
      [options]="years"
      [control]="form.year"
      [pending]="yearsLoading"
      [placeholder]="PLACEHOLDERS.option"
    ></listo-dropdown>
    <listo-dropdown
      [label]="STRINGS.maker"
      [options]="makers"
      [control]="form.maker"
      [pending]="makersLoading"
      [placeholder]="PLACEHOLDERS.option"
    ></listo-dropdown>
    <listo-dropdown
      [label]="STRINGS.model"
      [options]="models"
      [control]="form.model"
      [pending]="modelsLoading"
      [placeholder]="PLACEHOLDERS.option"
    ></listo-dropdown>
  </div>

  <table>
    <thead>
      <tr>
        <th></th>
        <th>VIN</th>
        <th i18n>Type</th>
        <th i18n>Fuel</th>
        <th i18n>Cylinders</th>
        <th i18n>Displacement</th>
        <th>4wd</th>
        <th>Fwd</th>
        <th i18n>Ton Rating</th>
        <th i18n>MSRP</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let vin of availableVins" class="vin-row" (click)="selectVin(vin.vin)">
        <td>
          <button class="select-vin">Select</button>
        </td>
        <td>{{vin.vin}}</td>
        <td>{{vin.info.BodyType}}</td>
        <td>{{vin.info.FuelType}}</td>
        <td>{{vin.info.NumCylinders}}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{{vin.info.TonRating}}</td>
        <td>${{vin.info.MSRP | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
    </tbody>
  </table>

  <listo-spinner *ngIf="searchVinLoading" class="loading" [size]="20"></listo-spinner>

  <p i18n>Note. Remember to complete the VIN with the last digits corresponding to the vehicle.</p>

</listo-modal>
