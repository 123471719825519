<button class="button" (click)="onClick()">
  <span class="container">
    <span class="name">¡Hola, {{agent.firstName}}!</span>
    <img class="image" [src]="agent.imageUrl">
    <span class="store">{{store.name}}</span>
  </span>
</button>
<div *ngIf="open" class="menu">
  <button class="logout-button" (click)="logout()" i18n>Logout</button>
</div>
