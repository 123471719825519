import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  Agent,
  AutoInsuranceQuoteApplication,
  AutoInsuranceRate,
  AutoLatest,
  AutoSearch,
  Location,
  Store,
  UserLeaderBoard,
  UserStatistic,
  AgentInQuote,
} from '../utils/types';
import { appendAutoInsuranceTotalData, BTFMapInsuranceData, FTBMapInsuranceData, sortRates } from '../utils/utils';
import { Observable, of, timer } from 'rxjs';
import { cachePromise } from '../utils/cache';
import { delay, first, map, switchMap, takeWhile } from 'rxjs/operators';

const API_URL = environment.apiUrl;
const AGENT_SERVICE_URL = environment.agentServiceUrl;
const STORE_SERVICE_URL = environment.storeServiceUrl;
const AUTO_SERVICE_URL = environment.autoServiceUrl;

@Injectable({
  providedIn: 'root',
})
export class WebServicesService {
  constructor(
    private http: HttpClient,
  ) {}

  getVehicleYears = cachePromise(() => {
    return this.http.get<{ years: string[] }>(
      `${AUTO_SERVICE_URL}/vehicle_information/years`,
      this.getOptions(),
    ).toPromise();
  });

  getVehicleMakers = cachePromise((year: string) => {
    return this.http.post<{ makers: string[] }>(
      `${AUTO_SERVICE_URL}/vehicle_information/makers`,
      { year },
      this.getOptions(),
    ).toPromise();
  });

  getVehicleModels = cachePromise((year: string, maker: string) => {
    return this.http.post<{ models: string[] }>(
      `${AUTO_SERVICE_URL}/vehicle_information/models`, { year, maker },
      this.getOptions()).toPromise();
  });

  validateVin = cachePromise((vin: string) => {
    return this.http.post<{ year: string, model: string, maker: string }>(
      `${AUTO_SERVICE_URL}/vehicle_information/validate_vin`,
      { vin }, this.getOptions(),
    ).toPromise();
  });

  getVins = cachePromise((year: string, maker: string, model: string) => {
    return this.http.post<any[]>(
      `${AUTO_SERVICE_URL}/vehicle_information/vins`,
      { year, maker, model }, this.getOptions(),
    ).toPromise();
  });

  private asyncFetch<T>(baseUrl: string , limit: number = 30, interval: number = 5000) {
    interface Response {
      status: 'complete' | 'processing' | 'pending' | 'cancelled' | 'error';
      progress?: number;
      message?: string;
      data?: T;
    }
    let ratesLength = 0;

    return switchMap((res: { resultsUrl: string }) =>
      timer(0, interval).pipe(
        switchMap(() =>
          this.http.get<Response>(baseUrl + res.resultsUrl, {
            params: {
              noCache: String(Date.now()),
            },
            ...this.getOptions(),
          }),
        ),
        takeWhile(({ status, data }, index) => {
          if (data['rates'] && data['rates'].length > ratesLength) {
            ratesLength = data['rates'].length;
            limit += 20;
          }
          if (index + 1 >= limit) {
            return false;
          }
          return status === 'processing' || status === 'pending';
        }, true),
      ),
    );
  }

  getAutoInsuranceRates(quoteApplicationId) {
    return this.http.post(
      `${AUTO_SERVICE_URL}/rates`,
      { id: quoteApplicationId },
      this.getOptions(),
    ).pipe(
      this.asyncFetch<{
        rates: AutoInsuranceRate[],
      }>(AUTO_SERVICE_URL),
      map(({ data, progress, status }) => ({
        rates: data.rates.sort(sortRates),
        progress,
        status,
      })),
    );
  }

  getOptions() {
    const headers = {
      'Content-Type': 'text/plain',
    };
    return {
      headers,
      withCredentials: true,
    };
  }

  async getAgentInfo(sid?: string): Promise<Agent> {
    const params: any = {};
    if (sid) {
      params.sid = sid;
    }
    const agentData = await this.http.get<any>(`${AGENT_SERVICE_URL}/agents/`, {
      ...this.getOptions(),
      params,
    }).toPromise();

    return {
      id: agentData.agent_uid,
      sid,
      firstName: agentData.first_name,
      lastName: agentData.last_name,
      email: agentData.email,
      imageUrl: agentData.image,
      storeId: agentData.store_id,
    };
  }

  searchAutoQuotes(term: string, page?: number): Observable<AutoSearch> {
    const params: any = {
      q: term,
    };
    if (page) {
      params.page = page;
    }
    return this.http.get<any>(`${AUTO_SERVICE_URL}/search`, {
      ...this.getOptions(),
      params,
    });
  }

  latestAutoQuotes(count?: number): Observable<AutoLatest> {
    const params: any = {
      count,
    };
    return this.http.get<any>(`${AUTO_SERVICE_URL}/quote-applications/latest`, {
      ...this.getOptions(),
      params,
    });
  }

  async getStoreInfo(storeId: string): Promise<Store> {
    const storeData = await this.http.get<any>(`${STORE_SERVICE_URL}/storefront/stores/`, {
      ...this.getOptions(),
      params: { id: storeId },
    }).toPromise();

    return {
      id: storeData.id,
      name: storeData.short_name,
      address: storeData.address,
    };
  }

  async getCities(zipcode: string): Promise<Location> {
    const [location] = await this.http.get<any>(`${STORE_SERVICE_URL}/cities/${zipcode}`).toPromise();

    return {
      state: location.stateCode,
      city: location.city,
      county: location.county,
    };
  }

  async getStatistics(params?: { agentId?: string, storeId?: string }): Promise<UserStatistic> {
    const { statistics } = await this.http.get<{ statistics: UserStatistic }>(
      `${API_URL}/data-service/statistics`,
      {
        ...this.getOptions(),
        // todo remove hardcoded param
        params: { agentId: 'vtest' },
      },
    ).toPromise();
    return appendAutoInsuranceTotalData(statistics);
  }

  async getLeaderBoards(params?: { storeId: string }): Promise<UserLeaderBoard[]> {
    const { leaderboards } = await this.http.get<{ leaderboards: { agent_id: string, progress: number }[] }>(
      `${API_URL}/data-service/store/leaderboard`,
      {
        ...this.getOptions(),
        // todo remove hardcoded param
        params: { storeId: '2' },
      },
    ).toPromise();

    return await Promise.all(leaderboards.map(async leaderboard => {
      const agent = await this.getAgentInfo(leaderboard.agent_id);
      return {
        progress: leaderboard.progress,
        agent,
      };
    }));
  }

  saveQuoteApplication(data: AutoInsuranceQuoteApplication) {
    return this.http.post<{ id: string }>(
      `${AUTO_SERVICE_URL}/quote-applications`,
      FTBMapInsuranceData(data),
      this.getOptions(),
    ).pipe(
      map(({ id }) => id),
    );
  }

  getQuoteApplication(policyId): Observable<AutoInsuranceQuoteApplication> {
    return this.http.get<{ policy: AutoInsuranceQuoteApplication, agents: AgentInQuote[] }>(
      `${AUTO_SERVICE_URL}/quote-applications/${policyId}`,
      this.getOptions(),
    ).pipe(
      map(({ policy, agents }) => BTFMapInsuranceData(policy, agents)),
    );
  }

  abortQuoteApplication(quote_application_id: string, carrier_id: string, rate_id: string) {
    return this.http.post(
      `${AUTO_SERVICE_URL}/carrier-abort`,
      {
        quote_application_id,
        carrier_id: String(carrier_id),
        rate_id,
      },
      this.getOptions(),
    ).pipe(
      this.asyncFetch<{
        type: string,
        url: string
      }>(AUTO_SERVICE_URL),
      map(({ data, status }) => ({
        ...data,
        status,
      })),
      first(({ status }) => status === 'complete'),
    );
  }

  selectRate(rateId: string) {
    return this.http.post(
      `${AUTO_SERVICE_URL}/select-rate`,
      {
        rate_id: rateId,
      },
      this.getOptions(),
    ).pipe(
      this.asyncFetch<{
        rate: AutoInsuranceRate,
      }>(AUTO_SERVICE_URL),
      map(({ data, status }) => ({
        rate: data.rate,
        status,
      })),
      first(({ status }) => status === 'complete'),
    );
  }

  updateAuraPassword(password: string) {
    return this.http.post<void>(
      `${AGENT_SERVICE_URL}/agents/update_password`,
      { password },
      this.getOptions(),
    ).toPromise();
  }

  async getStores(): Promise<Store[]> {
    const stores = await this.http.get<{
      id: string;
      short_name: string;
      address: string;
    }[]>(
      `${STORE_SERVICE_URL}/storefront/stores`,
    ).toPromise();

    return stores.map(store => ({
      ...store,
      name: store.short_name,
    }));
  }

  updateAgentStore(storeId: string): Promise<void> {
    return this.http.post<void>(
      `${AGENT_SERVICE_URL}/agents/update_store`,
      { store_id: storeId },
      this.getOptions(),
    ).toPromise();
  }

  async validateAuraCredentials(): Promise<boolean> {
    const { is_valid_aura } = await this.http.get<{ is_valid_aura: boolean }>(
      `${AGENT_SERVICE_URL}/validate_aura_credentials`,
      {
        ...this.getOptions(),
        params: {
          noCache: Date.now().toString(),
        },
      },
    ).toPromise();

    return is_valid_aura;
  }
}
