import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  Injector,
} from '@angular/core';
import { ConfirmModalComponent } from './listo-common/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './listo-common/error-modal/error-modal.component';
import { NotFoundModalComponent } from './listo-common/not-found-modal/not-found-modal.component';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface ConfirmParams {
  title: string;
  body?: string;
  confirmText?: string;
  cancelText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
  ) {}

  appendComponent<T>(component: new () => T): ComponentRef<T> {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    this.applicationRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    return componentRef as ComponentRef<T>;
  }

  confirm({ title, body, confirmText, cancelText }: ConfirmParams): Observable<boolean> {
    const subject = new Subject<boolean>();
    const confirm = new EventEmitter();
    const cancel = new EventEmitter();
    const exit = new EventEmitter();

    confirm.subscribe(() => {
      subject.next(true);
      subject.complete();
    });
    cancel.subscribe(() => {
      subject.next(false);
      subject.complete();
    });
    exit.subscribe(() => {
      subject.error('cancelled');
    });

    const confirmModal = this.appendComponent(ConfirmModalComponent);

    confirmModal.instance.title = title;
    confirmModal.instance.body = body;
    if (confirmText) {
      confirmModal.instance.confirmText = confirmText;
    }
    if (cancelText) {
      confirmModal.instance.cancelText = cancelText;
    }

    confirmModal.instance.confirm = confirm;
    confirmModal.instance.cancel = cancel;
    confirmModal.instance.exit = exit;

    return subject.pipe(
      finalize(() => {
        this.applicationRef.detachView(confirmModal.hostView);
        confirmModal.destroy();
      }),
    );
  }

  error() {
    const modal = this.appendComponent(ErrorModalComponent);
    const close = new EventEmitter();
    close.subscribe(() => {
      this.applicationRef.detachView(modal.hostView);
      modal.destroy();
    });
    modal.instance.close = close;
  }

  quoteNotFound() {
    const modal = this.appendComponent(NotFoundModalComponent);
    const close = new EventEmitter();
    close.subscribe(() => {
      this.applicationRef.detachView(modal.hostView);
      modal.destroy();
    });
    modal.instance.close = close;
  }

  custom(modalComponent, instance: Record<string, any> = {}) {
    const modal = this.appendComponent(modalComponent);

    const close = new EventEmitter();
    close.subscribe(() => {
      this.applicationRef.detachView(modal.hostView);
      modal.destroy();
    });
    modal.instance['close'] = close;

    for (const [key, value] of Object.entries(instance)) {
      modal.instance[key] = value;
    }
  }

}

