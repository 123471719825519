<listo-card>
  <ng-container header>
    <span class="icon icon-error"></span>
    <ng-container i18n>Fields are missing to bridge</ng-container>
  </ng-container>
  <ng-container body>
    <p i18n>Complete fields indicated below to bridge:</p>
    <ng-container *ngIf="getContactKeysWithWarnings().length">
      <button class="form-name" (click)="contactClick.emit()">
        {{CONTACT_STRING}}
      </button>
      <ul>
        <li *ngFor="let key of getContactKeysWithWarnings()">
          {{STRINGS[key] || key}}
        </li>
      </ul>
    </ng-container>
    <ng-container *ngFor="let driver of drivers; let i = index">
      <ng-container *ngIf="getDriverKeysWithWarnings(driver).length">
        <button class="form-name" (click)="driverClick.emit(getDriverIndex(driver))">
          {{DRIVER_STRING}} {{i + 1}}
        </button>
        <ul>
          <li *ngFor="let key of getDriverKeysWithWarnings(driver)">
            {{STRINGS[key] || key}}
          </li>
        </ul>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let driver of excludedDrivers; let i = index">
      <ng-container *ngIf="getDriverKeysWithWarnings(driver).length">
        <button class="form-name" (click)="driverClick.emit(getDriverIndex(driver))">
          {{EXCLUDED_STRING}} {{i + 1}}
        </button>
        <ul>
          <li *ngFor="let key of getDriverKeysWithWarnings(driver)">
            {{STRINGS[key] || key}}
          </li>
        </ul>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let vehicle of vehicles; let i = index">
      <ng-container *ngIf="getVehicleKeysWithWarnings(vehicle).length">
        <button class="form-name" (click)="vehicleClick.emit(i)">
          {{VEHICLE_STRING}} {{i + 1}}
        </button>
        <ul>
          <li *ngFor="let key of getVehicleKeysWithWarnings(vehicle)">
            {{STRINGS[key] || key}}
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
</listo-card>
