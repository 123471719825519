<div class="header-container">
  <h1 class="header" i18n>Car insurances</h1>
  <listo-button routerLink="quote" *ngIf="hasSearched" [invertColor]="true" i18n>Quote in portal</listo-button>
  <a *ngIf="hasSearched" [href]="getAutoRaterLink()" target="_blank">
    <listo-button i18n>Quote in auto rater</listo-button>
  </a>
</div>

<div class="search">
  <img class="image" src="../../assets/images/search.svg">
  <input
    i18n-placeholder
    type="text"
    placeholder="Search quotes by name, phone number or address"
    (keyup)="onSearchChange.emit($event)"
  />
</div>

<div class="main-container">
  <listo-spinner *ngIf="showLoading" class="loading"></listo-spinner>

  <ng-container *ngIf="!hasSearched  && !showLoading">
    <h2 i18n>Recent quotes</h2>
    <div class="latest-quotes">
      <div *ngFor="let latest of latestQuotes" class="quote">
        <img src="/assets/images/car.svg" width="24" height="24" />
        <p>{{ latest.contact_name }}</p>
        <button class="open-quote" routerLink="quote/{{latest.id}}" i18n>Open quote</button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="totalQuotes > 0" [class.hidden]="showLoading">
    <small i18n>Showing {{ quotes.length }} results out of {{ totalQuotes }}</small>
    <table>
      <thead>
        <tr>
          <th i18n>Name</th>
          <th i18n>Last name</th>
          <th i18n>Phone number</th>
          <th i18n>Address</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let quote of quotes" routerLink="quote/{{quote.policy_id}}">
          <td>{{ quote.first_name }} {{ quote.middle_name }}</td>
          <td>{{ quote.last_name }}</td>
          <td>{{ formatPhone(quote.phone) }}</td>
          <td>{{ quote.address }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="hasSearched && totalQuotes === 0 && !showLoading" class="no-results">
    <p i18n>No quotes matches your search.</p>
    <p i18n>Try other search criteria such as name, phone, address or create a New quote</p>
  </div>
</div>

<ul *ngIf="totalQuotes > quotes.length" class="page-buttons">
  <li>
    <button class="page" (click)="prevPage()">&lt;</button>
  </li>
  <ng-container *ngIf="getPages()[0] !== 1">
    <li>
      <button class="page" (click)="queryPage(1)">1</button>
    </li>
    <li>...</li>
  </ng-container>
  <li *ngFor="let page of getPages()">
    <button class="page" [class.active]="page === currentPage" (click)="queryPage(page)">{{page}}</button>
  </li>
  <ng-container *ngIf="getPages().pop() !== getLastPage()">
    <li>...</li>
    <li>
      <button class="page" (click)="queryPage(getLastPage())">{{getLastPage()}}</button>
    </li>
  </ng-container>
  <li>
    <button class="page" (click)="nextPage()">&gt;</button>
  </li>
</ul>
