<div class="container" *ngIf="error" [ngSwitch]="error.type">
  <span class="icon-error"></span>
  <ng-container *ngSwitchCase="'required'" i18n>This field is required</ng-container>
  <ng-container *ngSwitchCase="'min'" i18n>The min value is {{error.value.min}}</ng-container>
  <ng-container *ngSwitchCase="'number'" i18n>This field must be a number</ng-container>
  <ng-container *ngSwitchCase="'email'" i18n>This field must be a valid email</ng-container>
  <ng-container *ngSwitchCase="'bridgeRequired'" i18n>Required to bridge</ng-container>
  <ng-container *ngSwitchCase="'phone'" i18n>Invalid phone number</ng-container>
  <ng-container *ngSwitchCase="'passwordsDontMatch'" i18n>Passwords don't match</ng-container>
  <ng-container *ngSwitchCase="'invalidAuraPassword'" i18n>
    Invalid password. Try again or request a password change to Aura
  </ng-container>
  <ng-container *ngSwitchCase="'pattern'">
    <ng-container *ngIf="error.value.requiredPattern === '/^\\(\\d{3}\\) \\d{3}-\\d{4}$/'" i18n>
      The format must be (nnn) nnn-nnnn
    </ng-container>
    <ng-container *ngIf="error.value.requiredPattern === '/^\\d{3}-\\d{2}-\\d{4}$/'" i18n>
      The format must be nnn-nn-nnnn
    </ng-container>
    <ng-container *ngIf="error.value.requiredPattern === '/^(\\d{5}|\\d{5}-\\d{4})$/'" i18n>
      The format must be nnnnn
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'afterToday'" i18n>
    You can't choose a date before today
  </ng-container>
  <ng-container *ngSwitchDefault i18n>Invalid field</ng-container>
</div>
