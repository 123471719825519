<listo-modal (close)="close.emit()">
  <img src="../../assets/images/warning.svg"/>
  <p><ng-container *ngIf="rateHasIncidents()">Se encontraron otros incidentes después de correr el MVR. </ng-container>Se ha realizado un ajuste en la tarifa previamente seleccionada.</p>

  <listo-card *ngIf="rateHasIncidents()" header="Otros Incidentes">
    <ng-container body>
      <div *ngFor="let incidentGroup of incidentsByDriver()" class="incident-group">
        <h3><listo-svg-icon icon="profile"></listo-svg-icon> {{incidentGroup.driverName || 'Conductor desconocido'}}</h3>
        <table>
          <tbody>
            <ng-container *ngFor="let incident of incidentGroup.incidents ; index as i">
              <tr>
                <th>Incidente {{i + 1}}</th>
                <th>Fecha</th>
              </tr>
              <tr>
                <td>{{incident.type || 'Tipo de incidente desconocido'}}</td>
                <td>{{formatDate(incident.date)}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </listo-card>

  <listo-card header="Nueva Tarifa">
    <ng-container body>
      <span class="rate-plan">{{newRate.plan}}</span>
      <ul class="rate-details">
        <li><h4>Down Payment</h4> <span>${{newRate.downPayment}}</span></li>
        <li><h4>Pago</h4> <span>${{newRate.monthlyPayment}}</span></li>
        <li><h4># Pagos</h4> <span>{{newRate.numberOfPayments}}</span></li>
        <li><h4>Total</h4> <strong>${{newRate.total}}</strong></li>
      </ul>
    </ng-container>
  </listo-card>

  <listo-button (click)="close.emit()">Continuar con la nueva tarifa</listo-button>
  <listo-button (click)="continueInCarrier.emit()" [invertColor]="true">{{CONSTANTS.CARRIER_ABORT_BUTTON}}</listo-button>
  <listo-button (click)="backToCoverages.emit()" [flat]="true">Regresar a cotizaciones</listo-button>
</listo-modal>
