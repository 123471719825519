import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'listo-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Output() close = new EventEmitter();
}
