import { Injectable } from '@angular/core';
import auth0 from 'auth0-js';
import { Subscription, timer } from 'rxjs';
import { WebServicesService } from './web-services.service';
import { Agent, AuthResult, Store } from '../utils/types';
import jwtDecode from 'jwt-decode';
import { clearCookie, getCookie, setCookie } from 'src/utils/cookies';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AURA_PASSWORD_NOTIFICATION } from '../utils/constants';

const DOMAIN = 'listo.auth0.com';
const CLIENT_ID = 'y1p1DH519UL5DEgkJflqZf2rkpR441di';
const URL = `${window.location.origin}`;
const AUD = 'https://api.listofin.com/';
const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: DOMAIN,
  redirectUri: `${URL}/login`,
  responseType: 'token id_token',
  scope: 'openid email profile',
});
const AUTH_COOKIE_NAME = 'listofin_authorization';
const COOKIE_DOMAIN = environment.cookieDomain;

const parseHash = (): Promise<AuthResult> => new Promise((resolve, reject) => {
  auth.parseHash((err, authResult) => {
    window.location.hash = '';
    if (authResult && authResult.accessToken) {
      resolve(authResult);
    } else {
      reject(Error(err && err.error));
    }
  });
});

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated = false;
  private _accessToken: string;
  private _currentAgent: Agent;
  private _currentStore: Store;
  private refreshSubscription: Subscription;
  notifications: string[] = [];

  get accessToken(): string {
    return this._accessToken;
  }

  get currentAgent(): Agent {
    return this._currentAgent;
  }

  get currentStore(): Store {
    return this._currentStore;
  }

  constructor(
    private router: Router,
    private webServices: WebServicesService,
  ) {}

  login(): void {
    auth.authorize({
      connection: 'google-oauth2',
      prompt: 'select_account',
      audience: AUD,
    });
  }

  logout(): void {
    this.doLogout();
    this.router.navigate(['login']);
  }

  expire(): void {
    this.doLogout();
    this.router.navigate(['logout']);
  }

  async parseHash(): Promise<void> {
    try {
      const { accessToken } = await parseHash();
      await this.doLogin(accessToken);
    } catch (e) {
      this.onError(e);
    }
  }

  async isAuthenticated(): Promise<boolean> {
    if (this._isAuthenticated) {
      return true;
    }

    const accessToken = getCookie(AUTH_COOKIE_NAME);
    if (!accessToken) {
      return false;
    }

    try {
      await this.doLogin(accessToken);
      return true;
    } catch (e) {
      return false;
    }
  }

  async fetchAgentInfo() {
    const agent = await this.webServices.getAgentInfo() as any;
    const store = await this.webServices.getStoreInfo(agent.storeId);
    const [sid] = agent.email.split('@');

    this._currentAgent = {
      ...agent,
      imageUrl: agent.imageUrl,
      sid,
    };
    this._currentStore = store;
    this.validateAuraCredentials();
  }

  async validateAuraCredentials(): Promise<boolean> {
    const valid = await this.webServices.validateAuraCredentials();
    if (valid) {
      this.notifications = this.notifications.filter(
        notification => notification !== AURA_PASSWORD_NOTIFICATION,
      );
    } else if (!this.notifications.includes(AURA_PASSWORD_NOTIFICATION)) {
      this.notifications.push(AURA_PASSWORD_NOTIFICATION);
    }
    return valid;
  }

  private async doLogin(accessToken: string) {
    try {
      const { exp } = jwtDecode(accessToken);
      const expiresIn = (exp * 1000) - Date.now();
      setCookie(AUTH_COOKIE_NAME, accessToken, COOKIE_DOMAIN, expiresIn / 1000);

      await this.fetchAgentInfo();
      this._isAuthenticated = true;
      this._accessToken = accessToken;
      this.scheduleTimeout(expiresIn);
    } catch (e) {
      this.onError(e);
      throw e;
    }
  }

  private doLogout(): void {
    clearCookie(AUTH_COOKIE_NAME, COOKIE_DOMAIN);
    this._accessToken = undefined;
    this._isAuthenticated = false;
    this._currentAgent = undefined;
    this._currentStore = undefined;
    this.unscheduleTimeout();
  }

  private onError(e): void {
    this.doLogout();
    throw e;
  }

  private scheduleTimeout(time: number) {
    const expirationTime = Math.max(time, 1);
    this.refreshSubscription = timer(expirationTime).subscribe(() => {
      this.expire();
    });
  }

  private unscheduleTimeout() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
