<div *ngIf="fetchQuoteLoading" class="loading-container">
  <h1 i18n>Loading policy</h1>
  <listo-spinner></listo-spinner>
</div>

<!--Main container-->
<ng-container *ngIf="!fetchQuoteLoading" [ngSwitch]="currentStep">
  <h1 class="header">
    <ng-container *ngIf="getQuoteApplicationName()">{{getQuoteApplicationName()}}</ng-container>
    <ng-container *ngIf="!getQuoteApplicationName()">
      <ng-container *ngIf="!dataService.id" i18n>New quote</ng-container>
      <ng-container *ngIf="dataService.id" i18n>Edit quote</ng-container>
    </ng-container>
  </h1>


  <div class="agent-info-container">
    <h5 class="created-by" *ngIf="isInfoAgentInQuote()">
      <ng-container>Creado por: </ng-container>
      <ng-container>{{getQuoteApplicationCreatedBy()}}</ng-container>
    </h5>
    <span class="space"></span>
    <h5 class="created-by" *ngIf="isInfoAgentInQuote()">
      <ng-container>Última modificación por: </ng-container>
      <ng-container>{{getQuoteApplicationLastModifiedBy()}}</ng-container>
    </h5>
  </div>


  <div class="steps-container">
    <button
      *ngFor="let step of steps; let i = index"
      class="step"
      [class.disabled]="!canOpenStep(step.value)"
      [class.current]="currentStep === step.value"
      (click)="changeStep(step.value)"
    >
      <ng-container [ngSwitch]="getStepStatus(step.value)">
        <span *ngSwitchCase="'complete'" class="icon icon-ok-circle complete"></span>
        <span *ngSwitchCase="'warning'" class="icon icon-error warning"></span>
        <span *ngSwitchDefault class="badge">{{i + 1}}</span>
      </ng-container>
      <span class="text">{{step.label}}</span>
    </button>
  </div>

  <ng-container *ngSwitchCase="AutoRaterSteps.contact" [ngTemplateOutlet]="contactView"></ng-container>

  <ng-container *ngSwitchCase="AutoRaterSteps.drivers" [ngTemplateOutlet]="driversView"></ng-container>

  <ng-container *ngSwitchCase="AutoRaterSteps.vehicles" [ngTemplateOutlet]="vehiclesView"></ng-container>

  <ng-container *ngSwitchCase="AutoRaterSteps.coverages" [ngTemplateOutlet]="coveragesView"></ng-container>

  <auto-rater-carrier-config
    *ngSwitchCase="AutoRaterSteps.carrier"
    (error)="openCarrierErrorModal()"
    (newDriver)="changeStep(AutoRaterSteps.drivers)"
    (saveAndExitClick)="saveAndExit()"
    (backToCoverages)="currentStep = AutoRaterSteps.coverages"
  ></auto-rater-carrier-config>

</ng-container>

<!--Modals-->

<listo-modal *ngIf="fieldWarningsModalOpen" (close)="fieldWarningsModalOpen = false" class="errors-modal">
  <app-error-messages
    (driverClick)="changeDriverIndex($event)"
    (vehicleClick)="changeVehicleIndex($event)"
    (contactClick)="changeStep(AutoRaterSteps.contact)"
  ></app-error-messages>
</listo-modal>

<listo-modal class="bridge-modal" *ngIf="isSavingChangesLoading">
  <listo-spinner></listo-spinner>
  <h2>Guardando</h2>
</listo-modal>

<app-coverages-modal
  *ngIf="isCoveragesModalOpen"
  (close)="isCoveragesModalOpen = false"
></app-coverages-modal>

<listo-modal *ngIf="maritalWarningModalOpen" (close)="saveAndContinue()" class="marital-warning-modal">
  <img src="../../assets/images/warning.svg"/>
  <!-- TODO i18n -->
  <p>Es necesario incluir los datos del cónyuge como conductor o excluido.</p>
  <listo-button (click)="addDriverSpouse()">
    Agregar ahora
  </listo-button>
  <listo-button (click)="saveAndContinue()" [invertColor]="true">
    Continuar y completar después
  </listo-button>
</listo-modal>

<!--Templates -->

<ng-template #contactView>
  <div class="container small">
    <ng-container [ngTemplateOutlet]="requiredLabel"></ng-container>
    <listo-card header="Contact information" i18n-header>
      <app-contact-form body></app-contact-form>
    </listo-card>
    <ng-container [ngTemplateOutlet]="saveAndContinueButton"></ng-container>
  </div>
</ng-template>

<ng-template #driversView>
  <div class="container small">
    <listo-select
      *ngIf="dataService.drivers.length > 1"
      class="tab-select"
      [options]="getDriverSelectOptions()"
      [value]="currentDriverIndex"
      (change)="changeDriverIndex($event)"
    ></listo-select>
    <ng-container [ngTemplateOutlet]="requiredLabel"></ng-container>
    <listo-card header="Drivers" i18n-header>
      <ng-container body *ngFor="let driver of dataService.drivers.controls; let i = index">
        <app-driver-form
          *ngIf="currentDriverIndex === i"
          [form]="driver"
          [canDelete]="i !== 0"
          (delete)="deleteDriver(i)"
        ></app-driver-form>
      </ng-container>
    </listo-card>
    <div class="tab-buttons-container" *ngIf="dataService.drivers.length > 1">
      <button
        *ngFor="let driver of dataService.drivers.controls; let i = index"
        [class.selected]="i === currentDriverIndex"
        (click)="changeDriverIndex(i)"
        class="tab"
        [class.excluded]="driver.excluded.value"
      >
        <ng-container [ngSwitch]="getDriverFormStatus(i)">
          <span *ngSwitchCase="'complete'" class="icon icon-ok-circle complete"></span>
          <span *ngSwitchCase="'warning'" class="icon icon-error warning"></span>
          <listo-svg-icon *ngSwitchDefault class="icon default" icon="circle"></listo-svg-icon>
        </ng-container>
        <ng-container *ngIf="driver.excluded.value">{{EXCLUDED_STRING}}</ng-container>
        <ng-container *ngIf="!driver.excluded.value">{{DRIVER_STRING}}</ng-container>
        {{getDriverDisplayIndex(driver)}}
      </button>
    </div>
    <listo-button
      class="bottom-button"
      (click)="addDriver()"
      [invertColor]="true"
      [disabled]="dataService.nonOwner.value"
    >
      <ng-container i18n>Add another driver</ng-container>
    </listo-button>
    <ng-container [ngTemplateOutlet]="saveAndContinueButton"></ng-container>
  </div>
</ng-template>

<ng-template #vehiclesView>
  <div class="container small">
    <listo-select
      *ngIf="dataService.vehicles.length > 1"
      class="tab-select"
      [options]="getVehicleSelectOptions()"
      [value]="currentVehicleIndex"
      (change)="changeVehicleIndex($event)"
    ></listo-select>
    <ng-container [ngTemplateOutlet]="requiredLabel"></ng-container>
    <listo-card header="Vehicles" i18n-header>
      <ng-container body *ngFor="let vehicle of dataService.vehicles.controls; let i = index">
        <app-vehicle-form
          *ngIf="currentVehicleIndex === i"
          [form]="vehicle"
          [canDelete]="canDeleteVehicle(vehicle)"
          (delete)="deleteVehicle(i)"
        ></app-vehicle-form>
      </ng-container>
    </listo-card>
    <div class="tab-buttons-container" *ngIf="dataService.drivers.length > 1">
      <button
        *ngFor="let vehicle of dataService.vehicles.controls; let i = index"
        [class.selected]="i === currentVehicleIndex"
        (click)="changeVehicleIndex(i)"
        class="tab"
      >
        <ng-container [ngSwitch]="getVehicleFormStatus(i)">
          <span *ngSwitchCase="'complete'" class="icon icon-ok-circle complete"></span>
          <span *ngSwitchCase="'warning'" class="icon icon-error warning"></span>
          <listo-svg-icon *ngSwitchDefault class="icon default" icon="circle"></listo-svg-icon>
        </ng-container>
        {{VEHICLE_STRING}} {{i + 1}}
      </button>
    </div>
    <listo-button
      class="bottom-button"
      (click)="addVehicle()"
      [invertColor]="true"
    >
      <ng-container i18n>Add another vehicle</ng-container>
    </listo-button>
    <ng-container [ngTemplateOutlet]=" saveAndContinueButton"></ng-container>
  </div>
</ng-template>

<ng-template #coveragesView>
  <div class="container">
    <listo-date-picker
      class="effective-date"
      [control]="dataService.effectiveDate"
      [label]="STRINGS.effectiveDate"
    ></listo-date-picker>
    <listo-card
      [collapsible]="true"
      [collapsed]="false"
    >
      <span header class="coverages-header">
        <span i18n>Modify coverages</span>
        <button class="see-coverages-button" (click)="openCoveragesModal($event)" i18n>View types of coverage</button>
      </span>
      <div body>
        <app-coverages-form></app-coverages-form>
        <div class="drivers-edit-container">
          <app-driver-small-form
            *ngFor="let driver of dataService.drivers.controls; let i = index;"
            [form]="driver"
            [canDelete]="i !== 0"
            (delete)="deleteDriver(i)"
            (edit)="changeDriverIndex(i)"
          ></app-driver-small-form>
        </div>
        <listo-button
          *ngIf="!dataService.nonOwner.value"
          class="items-edit-add-button"
          (click)="addDriver()"
          [flat]="true"
        >
          <ng-container i18n>Add another driver</ng-container>
        </listo-button>
        <hr class="big-separator">
        <ng-container
          *ngFor="let vehicle of dataService.vehicles.controls; let i = index;"
        >
          <app-vehicle-small-form
            [form]="vehicle"
            [canDelete]="canDeleteVehicle(vehicle)"
            (delete)="deleteVehicle(i)"
            (edit)="changeVehicleIndex(i)"
            (openCoverages)="isCoveragesModalOpen = true"
          ></app-vehicle-small-form>
          <hr class="big-separator" *ngIf="i < dataService.vehicles.length - 1">
        </ng-container>
        <listo-button class="items-edit-add-button" (click)="addVehicle()" [flat]="true">
          <ng-container i18n>Add another vehicle</ng-container>
        </listo-button>
        <p *ngIf="dataService.nonOwner.value" class="non-owner-label">
          <ng-container i18n>*Note. Adding a vehicle to this quote will remove the non-owner property</ng-container>
        </p>
        <listo-button
          [invertColor]="true"
          (click)="onRateInsuranceClick()"
        >
          <ng-container i18n>Rate insurance</ng-container>
        </listo-button>
      </div>
    </listo-card>
    <div class="rates-container">
      <div *ngIf="ratesLoading" class="rates-loading-message">
        <listo-spinner size="20"></listo-spinner>
        <ng-container>{{ratesProgress | percent}} </ng-container>
        <ng-container i18n>of loaded rates</ng-container>
      </div>
      <div class="rates-filters">
        <listo-checkbox
          *ngIf="rates.length"
          (change)="monthToMonthFilter = $event"
          [value]="monthToMonthFilter"
          [label]="STRINGS.monthToMonthFilter"
        ></listo-checkbox>
        <listo-select
          *ngIf="rates.length"
          class="term-filter"
          label="Term"
          i18n-label
          [value]="currentTerm"
          [options]="terms"
          (change)="currentTerm = $event"
        ></listo-select>
      </div>
      <app-rates-table
        *ngIf="rates.length"
        [rates]="getRates()"
        (bridge)="selectRate($event)"
      ></app-rates-table>
      <div *ngIf="!rates.length && !ratesLoading" class="rates-error-container">
        <p i18n>We're sorry</p>
        <p i18n>There are no available quotes at the moment</p>
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="saveAndExitButton"
    ></ng-container>
  </div>
</ng-template>

<ng-template #saveAndContinueButton>
  <listo-button
    class="bottom-button"
    (click)="saveAndContinue()"
    [disabled]="saveQuoteLoading"
  >
    <ng-container *ngIf="saveQuoteLoading" i18n>Saving...</ng-container>
    <ng-container *ngIf="!saveQuoteLoading" i18n>Save and continue</ng-container>
  </listo-button>
</ng-template>

<ng-template #saveAndExitButton>
  <listo-button
    class="bottom-button"
    (click)="saveAndExit()"
    [disabled]="saveQuoteLoading"
  >
    <ng-container *ngIf="saveQuoteLoading" i18n>Saving...</ng-container>
    <ng-container *ngIf="!saveQuoteLoading" i18n>Save and exit</ng-container>
  </listo-button>
</ng-template>

<ng-template #requiredLabel>
  <p class="required-label" *ngIf="currentStep !== 'COVERAGES'">
    <ng-container i18n>Required fields</ng-container>
    <img src="../../../assets/images/required.svg">
  </p>
</ng-template>
