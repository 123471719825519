import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoInsuranceRate } from '../../../utils/types';
import { AUTO_INSURANCE_TOOLTIPS } from '../../../utils/constants';

@Component({
  selector: 'app-rates-table',
  templateUrl: './rates-table.component.html',
  styleUrls: ['./rates-table.component.scss'],
})
export class RatesTableComponent {
  @Input() rates: AutoInsuranceRate[];
  @Output() bridge = new EventEmitter<AutoInsuranceRate>();
  TOOLTIPS = AUTO_INSURANCE_TOOLTIPS;
}
