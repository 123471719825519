import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'listo-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() title = '';
  @Input() body = '';
  @Input() confirmText = 'Aceptar';
  @Input() cancelText = 'Cancelar';
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() exit = new EventEmitter();
}
