<span
  *ngIf="label && (open || getError() || getWarning())"
  class="icon-focus"
  [class.error]="getError()"
  [class.warning]="getWarning()"
></span>
<span class="label" *ngIf="label">
  {{label}}
  <img *ngIf="isRequired()" class="required" src="../../../assets/images/required.svg">
  <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip"></listo-tooltip>
  <listo-tooltip *ngIf="hasTemplateTooltip()">
    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </listo-tooltip>
</span>
<div
  #select
  class="select"
  [class.open]="open"
  [class.error]="getError()"
  [class.warning]="getWarning()"
  tabindex="0"
  (focus)="onOpen()"
  (blur)="onClose()"
  [class.placeholder]="!getSelectedLabel()"
  [class.disabled]="isDisabled()"
  (mousedown)="onMouseDown($event)"
>
  {{getSelectedLabel() || placeholder}}
  <img *ngIf="!isPending()" class="select-icon" src="../../assets/images/dropdown-{{open ? 'open' : 'closed'}}.svg">
  <listo-spinner *ngIf="isPending()" class="select-icon" [size]="20"></listo-spinner>
  <div *ngIf="open" class="options" (mousedown)="$event.stopPropagation()">
    <div *ngFor="let option of options" (mousedown)="onOptionMouseDown(getOptionValue(option))">
      {{getOptionLabel(option)}}
    </div>
  </div>
</div>
<listo-error-label [error]="getError()"></listo-error-label>
<listo-error-label *ngIf="getWarning() && !getError()" [error]="getWarning()" class="warning-label"></listo-error-label>
