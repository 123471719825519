import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

type PromiseFunc = (...args: any[]) => Promise<any>;
type ObservableFunc = (...args: any[]) => Observable<any>;

export const cachePromise = <T extends PromiseFunc>(promise: T): T => {
  const cache = {};
  return <T>((...args) => {
    const key = JSON.stringify(args);
    if (cache[key]) {
      return Promise.resolve(cache[key]);
    }

    return promise(...args).then(
      result => cache[key] = result,
    );
  });
};

export const cacheObservable = <T extends ObservableFunc>(observable: T): T => {
  const cache = {};
  return <T>((...args) => {
    const key = JSON.stringify(args);
    if (cache[key]) {
      return of(cache[key]);
    }

    return observable(...args).pipe(
      tap(
        result => cache[key] = result,
      ),
    );
  });
};
