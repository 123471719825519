import { Component, EventEmitter, OnInit } from '@angular/core';
import { WebServicesService } from '../web-services.service';
import { formatPhone } from '../../utils/utils';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { AUTO_RATER_URL } from '../../utils/constants';

@Component({
  selector: 'app-auto-insurance-dashboard',
  templateUrl: './auto-insurance-dashboard.component.html',
  styleUrls: ['./auto-insurance-dashboard.component.scss'],
})
export class AutoInsuranceDashboardComponent implements OnInit {
  hasSearched = false;
  showLoading = false;
  latestQuotes = [];
  currentPage = 1;
  quotes = [];
  term = '';
  totalQuotes = 0;
  formatPhone = formatPhone;
  onSearchChange = new EventEmitter<any>();

  constructor(private webServices: WebServicesService) {}

  ngOnInit() {
    this.showLoading = true;
    this.webServices.latestAutoQuotes(6).subscribe(
      ({ results }) => {
        this.showLoading = false;
        this.latestQuotes = results;
      },
    );

    this.onSearchChange.pipe(
      map(event => event.target.value),
      distinctUntilChanged(),
      debounceTime(300),
      tap(value => {
        this.term = value;
        if (!value) {
          this.hasSearched = false;
          this.quotes = [];
          this.totalQuotes = 0;
        }
      }),
      filter(value => !!value),
      switchMap(() => {
        this.currentPage = 1;
        return this.onSearch(1);
      }),
    ).subscribe();
  }

  onSearch(page = 1) {
    this.showLoading = true;

    return this.webServices.searchAutoQuotes(this.term, page).pipe(tap(
      ({ results, metadata }) => {
        this.quotes = results;
        this.totalQuotes = metadata.total;
        this.showLoading = false;
        this.hasSearched = true;
      },
      (e) => {
        this.totalQuotes = 0;
        this.showLoading = false;
        this.hasSearched = true;
        throw e;
      },
    ));
  }

  getLastPage() {
    return Math.ceil(this.totalQuotes / 10);
  }

  getPages() {
    const lastPage = this.getLastPage();
    const offset = 2;
    const startPage = Math.max(this.currentPage - offset, 1);
    const endPage = Math.min(this.currentPage + offset, lastPage);
    const length = endPage - startPage + 1;

    return Array.from(
      { length },
      (_, i) => i + startPage,
    );
  }

  queryPage(page) {
    this.currentPage = page;
    this.onSearch(this.currentPage).subscribe();
  }

  prevPage() {
    const newPage = Math.max(this.currentPage - 1, 1);
    if (this.currentPage !== newPage) {
      this.currentPage = newPage;
      this.onSearch(this.currentPage).subscribe();
    }
  }

  nextPage() {
    const newPage = Math.min(this.currentPage + 1, this.getLastPage());
    if (this.currentPage !== newPage) {
      this.currentPage = newPage;
      this.onSearch(this.currentPage).subscribe();
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    return String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getDate() + '/' + date.getFullYear();
  }

  getAutoRaterLink() {
    return `${AUTO_RATER_URL}/vehicle?name=${this.term}`;
  }
}
