import { Component } from '@angular/core';
import {
  ADMIN_TOOLS_LABEL,
  AUTO_RATER_LABEL,
  AUTO_RATER_URL,
  AUTO_TOOLS_LABEL,
  EXPANDED_RATER_LABEL,
  LIFE_RATER_URL,
  LIFE_TOOL_LABEL,
  LIFE_TOOLS_LABEL,
  LOAN_RATER_URL,
  LOAN_TOOL_LABEL,
  LOANS_TOOLS_LABEL,
  MY_LINKS_LABEL, OTHER_INSURANCES_TOOLS_LABEL, PAYMENTS_TOOLS_LABEL, SALES_TOOLS_LABEL,
} from '../../utils/constants';
import { environment } from '../../environments/environment';

const ENV = environment.env;


interface Tool {
  title: string;
  icon: string;
  url: string;
  external?: boolean;
}

interface ToolsSection {
  sectionTitle: string;
  tools: Tool[];
}

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
  sections: ToolsSection[] = [
    {
      sectionTitle: ADMIN_TOOLS_LABEL,
      tools: [
        {
          title: 'listo! Mail',
          icon: './assets/images/gmail.png',
          url: 'https://mail.google.com/a/listofin.com',
          external: true,
        },
        {
          title: 'Google Drive',
          icon: './assets/images/drive.png',
          url: 'https://drive.google.com/a/listofin.com',
          external: true,
        },
        {
          title: 'Ring Central',
          icon: './assets/images/ring.png',
          url: 'https://login.ringcentral.com/?responseType=code&clientId=cZPfEqZkQxKa9dUEu9RkCA&brandId=1210&state=%2Frc&localeId=en_US&endpointId=&session=1926481050203686743&display=touch&prompt=login%20sso&scope=&appUrlScheme=https%3A%2F%2Fapp.glip.com%2Fapi%2Fno-auth%2Frc-signon&ui_options=external_popup%20remember_me_on%20show_back_to_app%20hide_consent&code_challenge=&code_challenge_method=&hideNavigationBar=true&glip_auth=true&response_hint=remember_me%20login_type&title_bar=true#/enterCredential',
          external: true,
        },
        {
          title: MY_LINKS_LABEL,
          icon: './assets/images/link.svg',
          url: '/tools/links',
        },
        {
          title: PAYMENTS_TOOLS_LABEL,
          icon: './assets/images/payments.svg',
          url: 'https://docs.google.com/spreadsheets/d/12qlk_6wfK_cZFHAb02_lOsG7o_RT_730fbgs3SyQ2G4/edit#gid=78225108',
          external: true,
        },
        {
          title: SALES_TOOLS_LABEL,
          icon: './assets/images/registro-ventas.svg',
          url: 'https://listofin.typeform.com/to/DR7uw0',
          external: true,
        },
        {
          title: 'Sign Now',
          icon: './assets/images/signnow.png',
          url: 'https://app.signnow.com',
          external: true,
        },
      ],
    },
    {
      sectionTitle: AUTO_TOOLS_LABEL,
      tools: [
        {
          title: 'ITC',
          icon: './assets/images/itc.png',
          url: 'https://www.turborater.com/login/login.aspx',
          external: true,
        },
        {
          title: EXPANDED_RATER_LABEL,
          icon: './assets/images/listo.png',
          url: '/autorater',
        },
        {
          title: AUTO_RATER_LABEL,
          icon: './assets/images/listo.png',
          url: `${AUTO_RATER_URL}/vehicle`,
          external: true,
        },
        {
          title: 'QQ',
          icon: './assets/images/qq.png',
          url: 'https://app.qqcatalyst.com',
          external: true,
        },
        {
          title: 'Infinity',
          icon: './assets/images/infinity.png',
          url: 'https://www.infinityagents.com/auth/login',
          external: true,
        },
        {
          title: 'Alliance',
          icon: './assets/images/alliance.png',
          url: 'https://ois.allianceunited.com/oisweb/login.aspx',
          external: true,
        },
        {
          title: 'Aspire',
          icon: './assets/images/aspire.png',
          url: 'https://login.agicins.com',
          external: true,
        },
        {
          title: 'Workmens',
          icon: './assets/images/workmens.png',
          url: 'https://www.waic.com/Home/Login',
          external: true,
        },
        {
          title: 'Safeway',
          icon: './assets/images/safeway.png',
          url: 'https://www.safewayinsurance.com/AgentPortal/Account/Login.aspx',
          external: true,
        },
        {
          title: 'Progressive',
          icon: './assets/images/progressive.png',
          url: 'https://www.foragentsonly.com/login/',
          external: true,
        },
        {
          title: 'Mapfre',
          icon: './assets/images/mapfre.png',
          url: 'https://www.mapfreconnect.com/agent/login?session_expired=true',
          external: true,
        },
        {
          title: 'National General',
          icon: './assets/images/national-general.png',
          url: 'https://natgenagency.com/',
          external: true,
        },
      ],
    },
    {
      sectionTitle: LOANS_TOOLS_LABEL,
      tools: [
        {
          title: LOAN_TOOL_LABEL,
          icon: './assets/images/listo.png',
          url: `${LOAN_RATER_URL}`,
          external: true,
        },
        {
          title: 'Aura',
          icon: './assets/images/aura.png',
          url: {
            dev: 'http://retail.training.lendifyfin.com/',
            staging: 'http://retail.training.lendifyfin.com/',
            prod: 'https://retail.lendifyfin.com/#/',
          }[ENV],
          external: true,
        },
      ],
    },
    {
      sectionTitle: LIFE_TOOLS_LABEL,
      tools: [
        {
          title: LIFE_TOOL_LABEL,
          icon: './assets/images/listo.png',
          url: `${LIFE_RATER_URL}`,
          external: true,
        },
      ],
    },
    {
      sectionTitle: OTHER_INSURANCES_TOOLS_LABEL,
      tools: [
        {
          title: 'Aegis',
          icon: './assets/images/aegis.png',
          url: 'https://prod.aegisinsurance.com/GameChanger/Portal/Account/LogOn',
          external: true,
        },
      ],
    },
  ];
  collapsed = {};
}
