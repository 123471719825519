import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AutoInsuranceRate, Option } from '../../../utils/types';
import * as CONSTANTS from '../../../utils/constants';
import { AutoRaterDataService } from '../auto-rater-data.service';
import {
  EXCLUDED_STRING,
  DRIVER_STRING
} from '../../../utils/constants';
import { formHasWarning, markFormAsTouched } from '../../../utils/utils';
import { WebServicesService } from '../../web-services.service';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../modal.service';


@Component({
  selector: 'auto-rater-carrier-payment',
  templateUrl: './carrier-payment.component.html',
  styleUrls: ['./carrier-payment.component.scss'],
})
export class CarrierPaymentComponent implements OnInit {
  @Input() rate: AutoInsuranceRate;
  @Output() saveAndExit = new EventEmitter();
  @Output() continueInCarrier = new EventEmitter();
  CONSTANTS = CONSTANTS;
  exitLoading = false;
  fieldExcludedDriversModal = false;
  driver;
  currentDriverIndex: number;
  hasError: boolean;
  saveQuoteLoading = false;
  constructor(public dataService: AutoRaterDataService,
              private webServices: WebServicesService,
              private location: Location,
              private modalService: ModalService
  ) {
  }


  ngOnInit() {
    this.checkForErrors();
    this.saveAndExit.subscribe(() => {
      this.exitLoading = true;
    });
  }

  getDriverSelectOptions(): Option[] {
    return this.dataService.drivers.controls.map((_, i) => ({
      label: `${DRIVER_STRING} ${i + 1}`,
      value: i,
    }));
  }

  getDriverDisplayIndex(driverControl) {
    const index = this.dataService.drivers.controls.filter(
      control => control.excluded.value === driverControl.excluded.value,
    ).findIndex(
      control => control === driverControl,
    );
    return index + 1;
  }

  getDriverAlias(driverControl) {
    return driverControl.get('excluded').value ? EXCLUDED_STRING : DRIVER_STRING;
  }

  getDriverFormStatus(index: number) {
    if (this.currentDriverIndex === index) {
      return 'current';
    }
    if (formHasWarning(this.dataService.drivers.controls[index])) {
      return 'warning';
    }
    return 'complete';
  }


  changeDriverIndex(index) {
    this.currentDriverIndex = index;
    this.fieldExcludedDriversModal = true;
    this.driver = this.dataService.drivers.controls[index];
  }

  getPrimaryFees(): {key: string, amount: number}[] {
    return Object.keys(CONSTANTS.PRIMARY_FEES)
      .filter(key => this.rate.hasOwnProperty(key))
      .filter(key => Number(this.rate[key]) > 0)
      .map(key => ({
        key,
        amount: Number(this.rate[key])
      }));
  }

  getOtherFees() {
    if (!this.rate.otherFees) {
      return null;
    }

    return Object.values(this.rate.otherFees).reduce(
      (total, fee) => total + fee,
      0,
    );
  }

  saveAndContinue() {
    if (this.dataService.drivers.invalid) {
      markFormAsTouched(this.dataService.drivers);
      this.currentDriverIndex = this.dataService.drivers.controls.findIndex(
        driverForm => driverForm.invalid,
      );
      return;
    }
    this.saveQuoteApplication().subscribe(() => {
      this.checkForErrors();
      this.fieldExcludedDriversModal = false;
    });
  }

  checkForErrors() {
    this.hasError = this.dataService.drivers.invalid;
  }

  saveQuoteApplication() {
    const data = this.dataService.getValue();
    this.saveQuoteLoading = true;
    return this.webServices.saveQuoteApplication(data).pipe(tap({
      next: (id) => {
        this.dataService.id = id;
        this.dataService.markAsPristine();
        this.location.replaceState(`/autorater/quote/${id}`);
      },
      error: (e) => {
        this.modalService.error();
        throw e;
      },
      complete: () => {
        this.saveQuoteLoading = false;
      },
    }));
  }

}
