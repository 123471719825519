<span class="label">
  {{label}}
  <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip"></listo-tooltip>
  <listo-tooltip *ngIf="hasTemplateTooltip()">
    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </listo-tooltip>
</span>
<img *ngIf="label && isRequired()" class="required" src="../../../assets/images/required.svg">
<div class="container" [class.error]="getError()" [class.warning]="getWarning()">
  <listo-select [value]="getMonth()" [options]="months" (change)="onMonthChange($event)" [required]="true" placeholder="Month" i18n-placeholder></listo-select>
  <listo-select [value]="getDay()" [options]="getDays()" (change)="onDayChange($event)" [required]="true" placeholder="Day" i18n-placeholder></listo-select>
  <listo-select [value]="getYear()" [options]="years" (change)="onYearChange($event)" [required]="true" placeholder="Year" i18n-placeholder></listo-select>
</div>
<listo-error-label [error]="getError()"></listo-error-label>
<listo-error-label *ngIf="getWarning() && !getError()" [error]="getWarning()" class="warning-label"></listo-error-label>

