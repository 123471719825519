import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import FormFieldComponent from '../form-field.component';
import { FormControlError } from '../../../utils/types';
import CustomFormControl from '../../auto-rater/CustomFormControl';

@Component({
  selector: 'listo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends FormFieldComponent implements OnInit {
  @Input() label?: string;
  @Input() value?: boolean;
  @Input() control: CustomFormControl;
  @Input() name?: string;
  @Input() disabled = false;
  @Input() pending?: boolean;
  @Input() required?: boolean;
  @Input() error?: FormControlError;
  @Input() tooltip?: string | TemplateRef<any>;
  @Output() change = new EventEmitter<boolean>();

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.listenControlChanges();
  }
}
