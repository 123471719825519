import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { zip } from 'rxjs';


interface CustomFormControlOptions extends AbstractControlOptions {
  warningValidators?: ValidatorFn | ValidatorFn[] | null;
}

class CustomFormControl extends FormControl {
  warningsControl: FormControl;

  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | CustomFormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);

    this.warningsControl = new FormControl(formState);

    if (
      validatorOrOpts !== null
      && !Array.isArray(validatorOrOpts)
      && typeof validatorOrOpts === 'object'
      && validatorOrOpts.warningValidators
    ) {
      this.warningsControl.setValidators(validatorOrOpts.warningValidators);
      this.warningsControl.updateValueAndValidity();
    }
    this.cloneMethods();

  }

  private cloneMethods() {
    const methods = [
      'setValue',
      'patchValue',
      'reset',
      'markAsTouched',
      'markAsUntouched',
      'markAsDirty',
      'markAsPristine',
      'markAsPending',
      'disable',
      'enable',
      'updateValueAndValidity',
    ];

    methods.forEach(method => {
      this[method] = (...args) => {
        this.warningsControl[method](...args);
        return super[method](...args);
      };
    });
  }

  get warnings() {
    return this.warningsControl.errors;
  }

  setWarnings(warnings: ValidationErrors | null, opts?: { emitEvent?: boolean }): void {
    this.warningsControl.setErrors(warnings, opts);
  }

  getWarning(errorCode: string, path?: Array<string | number> | string): any {
    return this.warningsControl.getError(errorCode, path);
  }

  hasWarning(errorCode: string, path?: Array<string | number> | string): boolean {
    return this.warningsControl.hasError(errorCode, path);
  }

  enableWithControl(control: CustomFormControl) {
    return zip(
      control.valueChanges,
      control.statusChanges,
    ).subscribe(([value, status]) => {
      if (value && status !== 'DISABLED') {
        this.enable();
      } else {
        this.disable();
      }
    });
  }
}

export default CustomFormControl;
