import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'listo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() invertColor = false;
  @Input() flat = false;
  @Input() disabled = false;
  @Input() type = '';
  @Output() click = new EventEmitter();

  onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled) {
      this.click.emit();
    }
  }
}
