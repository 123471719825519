import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  errorModalOpen = false;
  unAuthErrorModalOpen = false;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  login() {
    this.auth.login();
  }

  async ngOnInit() {
    if (window.location.hash) {
      try {
        this.loading = true;
        await this.auth.parseHash();
        this.router.navigate(['/']);
      } catch (e) {
        this.loading = false;
        if (e.message === 'unauthorized') {
          this.unAuthErrorModalOpen = true;
        } else {
          this.errorModalOpen = true;
        }
      }
    }
  }
}
