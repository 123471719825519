export const phoneMask = (value) => {
  const phone = value.replace(/\D/g, '');
  const mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  if (phone.length >= 13) {
    return ['+', /\d/, /\d/, '-', /\d/, ' ', ...mask];
  }
  if (phone.length >= 12) {
    return ['+', /\d/, /\d/, ' ', ...mask];
  }
  if (phone.length >= 11) {
    return ['+', /\d/, ' ', ...mask];
  }
  return mask;
};
export const dateMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const itinMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const alphaNumericMask = Array(100).fill(/[0-9A-Za-z ]/);
