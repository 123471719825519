<a *ngIf="!hasChildren()" routerLink="/{{route.path}}" class="tab" routerLinkActive="active">
  <listo-svg-icon class="icon" [icon]="route.icon"></listo-svg-icon>
  {{route.label}}
</a>
<ng-container *ngIf="hasChildren()">
  <div class="tab" [class.active]="isSomeChildActive()" (click)="toggleOpen()">
    <listo-svg-icon class="icon" [icon]="route.icon"></listo-svg-icon>
    {{route.label}}
    <span class="icon-dropdown-closed" [class.open]="open"></span>
  </div>
  <ng-container *ngFor="let childRoute of getChildren()">
    <a
      *ngIf="open"
      [routerLink]="getChildPath(childRoute)"
      class="child-tab"
      routerLinkActive="active"
      (click)="$event.stopPropagation()"
      [routerLinkActiveOptions]="{ exact:true }"
      @fade
    >
      {{childRoute.label}}
    </a>
  </ng-container>
</ng-container>
