import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth.service';

Sentry.init({
  dsn: 'https://5858a48e93c24c91a5fd4875ac0c025b@sentry.io/1462281',
});

@Injectable({
  providedIn: 'root',
})
export class ListoErrorHandlerService implements ErrorHandler {
  constructor(private auth: AuthService) {}

  handleError(error) {
    let customError = error;
    if (error instanceof HttpErrorResponse) {
      if (error.status === 403) {
        this.auth.expire();
      } else if (error.status === 0) {
        customError = Error(`CORS error in ${error.url}`);
      }
    }

    // TODO clean this condition up
    if (!(error.message.includes('ViewDestroyedError'))) {
      console.log(error);
      this.sendErrorToSentry(customError);
    }
  }

  sendErrorToSentry(error) {
    if (environment.production) {
      const eventId = Sentry.captureException(error.originalError || error);
      console.log(`Sentry eventId: ${eventId}`);
    }
  }
}
