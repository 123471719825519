import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import FormFieldComponent from '../form-field.component';
import { getOptionLabel, getOptionValue } from 'src/utils/options';
import { FormControlError } from '../../../utils/types';
import CustomFormControl from '../../auto-rater/CustomFormControl';

interface OptionObject {
  label: string;
  value: any;
}

type Option = OptionObject | string;

@Component({
  selector: 'listo-radios',
  templateUrl: './radios.component.html',
  styleUrls: ['./radios.component.scss'],
})
export class RadiosComponent extends FormFieldComponent implements OnInit {
  @Input() control: CustomFormControl;
  @Input() value: any;
  @Input() required = false;
  @Input() pending = false;
  @Input() options: Option[];
  @Input() label!: string;
  @Input() cols = 2;
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() disabled?: boolean;
  @Input() error?: FormControlError;
  @Output() change = new EventEmitter();
  @Output() tooltipClick = new EventEmitter();

  getOptionLabel = getOptionLabel;
  getOptionValue = getOptionValue;

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.listenControlChanges();
  }

  isOptionSelected(option) {
    return getOptionValue(option) === this.getValue();
  }

  onOptionClick(value) {
    this.onChange(value);
  }
}
