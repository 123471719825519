import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'listo-not-found-modal',
  templateUrl: './not-found-modal.component.html',
  styleUrls: ['./not-found-modal.component.scss']
})
export class NotFoundModalComponent {
  @Output() close = new EventEmitter();
}
