<span
  *ngIf="label && (open || getError() || getWarning())"
  class="icon-focus"
  [class.warning]="getWarning()"
  [class.error]="getError()"
></span>

<span class="label">
  {{label}}
  <img *ngIf="isRequired()" class="required" src="../../../assets/images/required.svg">
  <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip"></listo-tooltip>
  <listo-tooltip *ngIf="hasTemplateTooltip()">
    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </listo-tooltip>
</span>

<div class="dropdown-container" [class.open]="open">
  <input
    #queryInput
    [class.open]="open"
    [class.disabled]="isDisabled()"
    [class.warning]="getWarning()"
    [class.error]="getError()"
    [value]="query"
    [placeholder]="getPlaceholder()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (keyup)="onInputKeyUp($event, queryInput.value)"
    tabindex="0"
  />

  <img *ngIf="!isPending()" class="select-icon" src="../../assets/images/dropdown-{{open ? 'open' : 'closed'}}.svg">

  <ng-template [ngIf]="open">
    <div *ngIf="noOptions()" class="options no-options">
      <span>{{DROPDOWN_NO_OPTIONS}}</span>
    </div>
    <ul *ngIf="!noOptions()" class="options" (mousedown)="$event.stopPropagation()">
      <li
        *ngFor="let option of getShownOptions(); index as i"
        [class.focused]="focusedOption === i"
        (mousedown)="onOptionMouseDown(option)"
        (mouseenter)="setFocusedOption(i)"
      >
        {{ getOptionLabel(option) }}
      </li>
    </ul>
  </ng-template>
</div>

<listo-error-label [error]="getError()"></listo-error-label>
<listo-error-label *ngIf="getWarning() && !getError()" [error]="getWarning()" class="warning-label"></listo-error-label>
