<div class="header">
  {{getName()}}
  <listo-button class="action-button" (click)="edit.emit()" [flat]="true" i18n>Edit</listo-button>
  <listo-button class="action-button" (click)="delete.emit()" [flat]="true" *ngIf="canDelete" i18n>Delete</listo-button>
  <listo-checkbox
    class="check"
    [label]="STRINGS.excludeVehicle"
    [control]="form.excluded"
    [disabled]="!canDelete"
  ></listo-checkbox>
</div>
<listo-radios
  class="coverage-types"
  [label]="STRINGS.coverageType"
  [options]="coverageTypes"
  [control]="form.coverageType"
  [cols]="4"
  [disabled]="form.excluded.value"
  tooltip=" "
  (tooltipClick)="openCoverages.emit()"
></listo-radios>
<listo-dropdown
  [label]="STRINGS.compDed"
  [control]="form.compDed"
  [options]="compCoverages"
  [disabled]="form.excluded.value"
  [tooltip]="TOOLTIPS.compDed"
></listo-dropdown>
<listo-dropdown
  [label]="STRINGS.collDed"
  [control]="form.collDed"
  [options]="collCoverages"
  [disabled]="form.excluded.value"
  [tooltip]="TOOLTIPS.collDed"
></listo-dropdown>
<listo-dropdown
  [label]="STRINGS.rentalDed"
  [control]="form.rentalDed"
  [options]="rentalCoverages"
  [disabled]="form.excluded.value"
  [tooltip]="TOOLTIPS.rentalDed"
></listo-dropdown>
<listo-radios
  class="towing"
  [label]="STRINGS.towingDed"
  [control]="form.towingDed"
  [options]="YESNO"
  [disabled]="form.excluded.value"
></listo-radios>
