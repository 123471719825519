import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AUTO_INSURANCE_STRINGS,
  AUTO_INSURANCE_TOOLTIPS,
  COLLISSION_COVERAGES,
  COMPREHENSIVE_COVERAGES,
  RENTAL_COVERAGES,
  VEHICLE_COVERAGE_TYPES,
  YESNO,
} from '../../../utils/constants';
import { VehicleForm } from '../forms';

@Component({
  selector: 'app-vehicle-small-form',
  templateUrl: './vehicle-small-form.component.html',
  styleUrls: ['./vehicle-small-form.component.scss'],
})
export class VehicleSmallFormComponent {
  @Input() form: VehicleForm;
  @Input() canDelete = false;
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() openCoverages = new EventEmitter();

  coverageTypes = VEHICLE_COVERAGE_TYPES;
  compCoverages = COMPREHENSIVE_COVERAGES;
  collCoverages = COLLISSION_COVERAGES;
  rentalCoverages = RENTAL_COVERAGES;
  STRINGS = AUTO_INSURANCE_STRINGS;
  TOOLTIPS = AUTO_INSURANCE_TOOLTIPS;
  YESNO = YESNO;

  getName() {
    const year = this.form.year.value;
    const maker = this.form.maker.value;
    const model = this.form.model.value;

    return `${year} ${maker} ${model}`;
  }
}
