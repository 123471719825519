<listo-dropdown
  [control]="form.liabBILimit"
  [options]="coverageLimits"
  [label]="STRINGS.liabBILimit"
  [tooltip]="TOOLTIPS.liabBILimit"
></listo-dropdown>
<listo-dropdown
  [control]="form.liabPDLimit"
  [options]="liabPDCoverages"
  [label]="STRINGS.liabPDLimit"
  [tooltip]="TOOLTIPS.liabPDLimit"
></listo-dropdown>
<listo-dropdown
  [label]="STRINGS.medPayLimit"
  [control]="form.medPayLimit"
  [options]="medCoverages"
  [tooltip]="TOOLTIPS.medPayLimit"
></listo-dropdown>
<listo-dropdown
  [label]="STRINGS.uninsBILimit"
  [control]="form.uninsBILimit"
  [options]="getUninsLimits()"
  [tooltip]="TOOLTIPS.uninsBILimit"
></listo-dropdown>
<listo-radios
  [label]="STRINGS.uninsPD"
  [control]="form.uninsPD"
  [options]="yesno"
  tooltip="Exonerates the deductible and pays for the damages of the vehicle caused by an uninsured driver. UMPD pays for the damages of the vehicle caused by an uninsured driver usually upto ($3500) when insured has no colliision coverage. The driver / owner of the uninsured vehicle must be identified to make this coverage applicable."
  i18n-tooltip
></listo-radios>
