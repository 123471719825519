import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import FormFieldComponent from '../form-field.component';
import { FormControlError } from '../../../utils/types';
import CustomFormControl from '../../auto-rater/CustomFormControl';

@Component({
  selector: 'listo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FormFieldComponent implements OnInit {
  @Input() label: string;
  @Input() autofocus = false;
  @Input() required = false;
  @Input() pending = false;
  @Input() value: any;
  @Input() type?: string;
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() placeholder = '';
  @Input() disabled?: boolean;
  @Input() control: CustomFormControl;
  @Input() mask?: any = false;
  @Input() error?: FormControlError;
  @Output() change = new EventEmitter();

  focus = false;

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.listenControlChanges();
  }
}
