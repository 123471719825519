<listo-card>
    <!-- TODO I18N -->
    <ng-container header>
        <span class="icon icon-error"></span>
        <ng-container>Faltan datos para poder cerrar la venta</ng-container>
      </ng-container>
  <ng-container body>
    <ng-container *ngFor="let driver of excludedDrivers; let i = index">
      <ng-container *ngIf="getDriverKeysWithErrors(driver).length">
        <button class="form-name" (click)="driverClick.emit(getDriverIndex(driver))">
          {{EXCLUDED_STRING}} {{i + 1}}: {{driver.get('firstName').value}} {{driver.get('lastName').value}}
        </button>
        <ul>
          <li *ngFor="let key of getDriverKeysWithErrors(driver)">
            {{STRINGS[key] || key}}
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>

</listo-card>