import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import FormFieldComponent from '../form-field.component';
import CustomFormControl from '../../auto-rater/CustomFormControl';
import {FormControlError, Option} from '../../../utils/types';
import {getOptionLabel, getOptionValue} from '../../../utils/options';
import {DROPDOWN_NO_OPTIONS} from '../../../utils/constants';

@Component({
  selector: 'listo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends FormFieldComponent implements OnInit {

  @Input() control: CustomFormControl;
  @Input() required?: boolean;
  @Input() value: any;
  @Input() pending?: boolean;
  @Input() disabled?: boolean;
  @Input() label: string;
  @Input() options: Option[] = [];
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() placeholder?: string;
  @Input() error?: FormControlError;
  @Output() change = new EventEmitter();
  @ViewChild('queryInput') queryInputElement;

  query = null;
  open = false;
  focusedOption = 0;

  getOptionLabel = getOptionLabel;

  DROPDOWN_NO_OPTIONS = DROPDOWN_NO_OPTIONS;

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.listenControlChanges();
    this.setQueryToValue();
    this.control.valueChanges.subscribe(() => {
      this.setQueryToValue();
    });
  }

  getShownOptions(): Option[] {
    return this.query
      ? this.options.filter(option => getOptionLabel(option).toLowerCase().includes(this.query.toLowerCase()))
      : this.options;
  }

  getSelectedOptionLabel(): string {
    return getOptionLabel(
      this.options.find(option => getOptionValue(option) === this.getValue()) || ''
    );
  }

  getPlaceholder() {
    return this.placeholder || this.getSelectedOptionLabel() || '';
  }

  noOptions(): boolean {
    return this.getShownOptions().length === 0;
  }

  setFocusedOption(option: number) {
    const shownOptions = this.getShownOptions();
    if (option < 0) {
      option = 0;
    }
    if (option >= shownOptions.length - 1) {
      option = shownOptions.length - 1;
    }
    this.focusedOption = option;
  }

  setQueryToValue() {
    this.query = this.getSelectedOptionLabel();
  }

  onInputFocus() {
    this.open = true;
    this.query = null;
  }

  onInputBlur() {
    this.open = false;
    this.setQueryToValue();
  }

  onOptionMouseDown(option: Option) {
    this.onChange(getOptionValue(option));
    this.query = getOptionLabel(option);
    this.open = false;
    this.queryInputElement.nativeElement.blur();
  }

  onInputKeyUp(event, currentValue: string) {
    if (event.code === 'ArrowDown') {
      this.setFocusedOption(this.focusedOption + 1);
    } else if (event.code === 'ArrowUp') {
      this.setFocusedOption(this.focusedOption - 1);
    } else if (event.code === 'Enter' && !this.noOptions()) {
      this.onOptionMouseDown(this.getShownOptions()[this.focusedOption]);
    } else {
      this.query = currentValue;
      this.setFocusedOption(0);
    }
  }

}
