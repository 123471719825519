import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AUTO_INSURANCE_PLACEHOLDERS,
  AUTO_INSURANCE_STRINGS,
  AUTO_INSURANCE_TOOLTIPS,
  CIVIL_STATUSES,
  DMV_CERTIFICATION_REASONS,
  DMV_CERTIFICATIONS,
  DRIVER_RELATIONSHIPS,
  GENDERS,
  INDUSTRIES,
  LICENSE_STATUSES,
  LICENSE_TYPES,
  OCCUPATIONS,
  OTHER_CIVIL_STATUSES,
  RACES,
  STATES,
  YESNO,
} from '../../../utils/constants';
import { sortOptionsAlph } from '../../../utils/utils';
import { DriverForm } from '../forms';
import { AutoRaterDataService } from '../auto-rater-data.service';

@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss'],
})
export class DriverFormComponent implements OnInit {
  @Input() form: DriverForm;
  @Input() canDelete: boolean;
  @Output() delete = new EventEmitter();

  genders = GENDERS;
  civilStatuses = CIVIL_STATUSES;
  otherCivilStatuses = OTHER_CIVIL_STATUSES;
  industries = INDUSTRIES.sort(sortOptionsAlph);
  occupations = OCCUPATIONS.sort(sortOptionsAlph);
  yesno = YESNO;
  dmvCertifications = DMV_CERTIFICATIONS;
  states = STATES;
  dmvCertificationReasons = DMV_CERTIFICATION_REASONS;
  STRINGS = AUTO_INSURANCE_STRINGS;
  PLACEHOLDERS = AUTO_INSURANCE_PLACEHOLDERS;
  TOOLTIPS = AUTO_INSURANCE_TOOLTIPS;
  types = LICENSE_TYPES;
  statuses = LICENSE_STATUSES;
  relationships = DRIVER_RELATIONSHIPS.sort(sortOptionsAlph);
  races = RACES.sort(sortOptionsAlph);

  constructor(public dataService: AutoRaterDataService) {}

  ngOnInit() {
    this.form.industry.valueChanges.subscribe(() => {
      const occupations = this.getOccupations();
      if (occupations.length === 1) {
        this.form.occupation.setValue(occupations[0].value);
      }
    });
  }

  shouldShowOtherCivilStatuses() {
    const value = this.form.marital.value;
    return value !== 'M' && value !== 'S' && value !== null;
  }

  getOccupations() {
    const industry = this.form.industry.value;
    if (!industry) {
      return this.occupations;
    }
    return this.occupations.filter(
      occupation => occupation.value.includes(industry),
    );
  }

  hasPriorInsurance() {
    return this.form.priorInsurance.value;
  }

  hasDmv() {
    return this.form.dmvCertification.value;
  }

  shouldShowNumber() {
    return this.form.licenseType.value === 'us_license';
  }
}
