import { Component, Input } from '@angular/core';
import { AutoInsuranceRate } from '../../../utils/types';
import * as constants from '../../../utils/constants';
import { AutoRaterDataService } from '../auto-rater-data.service';

@Component({
  selector: 'auto-rater-carrier-questions',
  templateUrl: './carrier-questions.component.html',
  styleUrls: ['./carrier-questions.component.scss'],
})
export class CarrierQuestionsComponent {
  @Input() rate: AutoInsuranceRate;
  constants = constants;

  constructor(
    public dataService: AutoRaterDataService,
  ) { }
}
