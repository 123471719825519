import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'listo-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() title: string;
  @Input() description: string;
  element: HTMLElement;
  open = false;
  @ViewChild('window') windowRef;

  constructor(private ref: ElementRef) {
    this.element = this.ref.nativeElement;
  }

  onOpen() {
    this.open = true;
    setTimeout(() => {
      this.windowRef.nativeElement.focus();
    });
  }

  onClose() {
    this.open = false;
  }
}
