import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AUTO_INSURANCE_STRINGS,
  CONTACT_STRING,
  DRIVER_STRING,
  EXCLUDED_STRING,
  VEHICLE_STRING,
} from '../../../utils/constants';
import { AutoRaterDataService } from '../auto-rater-data.service';
import { getKeysWithErrors } from '../../../utils/utils';

@Component({
  selector: 'app-error-rate-messages',
  templateUrl: './error-rate-messages.component.html',
  styleUrls: ['./error-rate-messages.component.scss']
})
export class ErrorRateMessagesComponent implements OnInit {

  @Output() driverClick = new EventEmitter<number>();
  STRINGS = AUTO_INSURANCE_STRINGS;
  CONTACT_STRING = CONTACT_STRING;
  DRIVER_STRING = DRIVER_STRING;
  VEHICLE_STRING = VEHICLE_STRING;
  EXCLUDED_STRING = EXCLUDED_STRING;

  constructor(private dataService: AutoRaterDataService) {
   }

  get excludedDrivers() {
    return this.dataService.drivers.controls.filter(
      control => control.excluded.value,
    );
  }


  getDriverKeysWithErrors(driver) {
    return getKeysWithErrors(driver);
  }

  getDriverIndex(driverControl) {
    return this.dataService.drivers.controls.findIndex(
      control => control === driverControl,
    );
  }

  ngOnInit(): void {

  }


  hasError(event) {
  }

}
