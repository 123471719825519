import { Component, EventEmitter, Output } from '@angular/core';
import * as STRINGS from '../../../utils/constants';
import { AutoRaterDataService } from '../auto-rater-data.service';
import {
  CIVIL_STATUSES, DMV_CERTIFICATIONS,
  DRIVER_RELATIONSHIPS,
  LICENSE_STATUSES,
  LICENSE_TYPES,
  OTHER_CIVIL_STATUSES, VEHICLE_USAGES,
} from '../../../utils/constants';

@Component({
  selector: 'auto-rater-carrier-confirm',
  templateUrl: './carrier-confirm.component.html',
  styleUrls: ['./carrier-confirm.component.scss'],
})
export class CarrierConfirmComponent {
  @Output() saveAndContinueClick = new EventEmitter();
  @Output() abortClick = new EventEmitter();
  STRINGS = STRINGS;
  civilStatuses = [...CIVIL_STATUSES, ...OTHER_CIVIL_STATUSES];
  drivers = [...this.dataService.drivers.controls].sort((a, b) => {
    if (a.excluded.value && !b.excluded.value) {
      return 1;
    }
    if (!a.excluded.value && b.excluded.value) {
      return -1;
    }
    return 0;
  });

  constructor(public dataService: AutoRaterDataService) {}

  getMaritalString(value) {
    const option = this.civilStatuses.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }

  getRelationshipString(value) {
    const option = DRIVER_RELATIONSHIPS.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }

  getLicenseTypeString(value) {
    const option = LICENSE_TYPES.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }

  getLicenseStatusesString(value) {
    const option = LICENSE_STATUSES.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }

  getDMVString(value) {
    const option = DMV_CERTIFICATIONS.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }

  getUsageString(value) {
    const option = VEHICLE_USAGES.find(
      o => o.value === value,
    );

    return option ? option.label : '';
  }
}
