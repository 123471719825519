import { Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ContactForm, CoveragesForm, DriverForm, VehicleForm, RelatedDriverForm } from './forms';
import { WebServicesService } from '../web-services.service';
import CustomValidators from '../../utils/validators';
import { formHasWarning, markFormAsTouched } from '../../utils/utils';
import { distinctUntilChanged, first, skip, startWith, tap } from 'rxjs/operators';
import { AutoRaterModule } from './auto-rater.module';
import CustomFormControl from './CustomFormControl';
import { AutoInsuranceDriver, AutoInsuranceRate, AgentInQuote } from '../../utils/types';
import { UnderwritingQuestionsForm } from './UnderwritingQuestionsForm';

@Injectable({
  providedIn: AutoRaterModule,
})
export class AutoRaterDataService {
  private data = new FormGroup({
    contact: new ContactForm(this.webServices),
    drivers: new FormArray(
      [new DriverForm()],
      Validators.required,
    ),
    vehicles: new FormArray(
      [new VehicleForm(this.webServices)],
      Validators.required,
    ),
    coverages: new CoveragesForm(),
    nonOwner: new CustomFormControl(false),
    effectiveDate: new CustomFormControl(new Date(), {
      warningValidators: CustomValidators.afterToday,
    }),
    underwritingQuestions: new UnderwritingQuestionsForm(),
  });
  carrierId: string;
  rateId: string;
  rateHasAbortInstructions: boolean;
  lastSelectedRate: AutoInsuranceRate;
  relatedDrivers: any[] = [];
  id: string;
  createdAt = Math.floor(Date.now() / 1000);
  createdBy: AgentInQuote;
  lastModifiedBy: AgentInQuote;

  get contact() {
    return this.data.get('contact') as ContactForm;
  }


  get drivers() {
    interface Drivers extends FormArray {
      controls: DriverForm[];
    }

    return this.data.get('drivers') as Drivers;
  }

  get vehicles() {
    interface Vehicles extends FormArray {
      controls: VehicleForm[];
    }

    return this.data.get('vehicles') as Vehicles;
  }

  get coverages() {
    return this.data.get('coverages') as CoveragesForm;
  }

  get nonOwner(): CustomFormControl {
    return this.data.get('nonOwner') as CustomFormControl;
  }

  get effectiveDate(): CustomFormControl {
    return this.data.get('effectiveDate') as CustomFormControl;
  }

  get underwritingQuestions() {
    return this.data.get('underwritingQuestions') as UnderwritingQuestionsForm;
  }

  get dirty() {
    return this.data.dirty;
  }

  get pristine() {
    return this.data.pristine;
  }

  get warnings() {
    return formHasWarning(this.data);
  }

  constructor(private webServices: WebServicesService) {
    this.setUpSubscriptions();
    this.drivers.controls[0].primaryDriverRelationship.setValue('SELF');
  }

  private setUpSubscriptions() {
    this.contact.firstName.valueChanges.subscribe(firstName => {
      const [driver] = this.drivers.controls;
      if (driver) {
        driver.firstName.setValue(
          firstName,
          {
            emitEvent: false,
          },
        );
      }
    });

    this.contact.lastName.valueChanges.subscribe(lastName => {
      const [driver] = this.drivers.controls;
      if (driver) {
        driver.lastName.setValue(
          lastName,
          {
            emitEvent: false,
          },
        );
      }
    });

    this.drivers.valueChanges.subscribe(([driver]) => {
      if (driver) {
        this.contact.firstName.setValue(
          driver.firstName,
          {
            emitEvent: false,
          },
        );

        this.contact.lastName.setValue(
          driver.lastName,
          {
            emitEvent: false,
          },
        );

        const primaryDriverRelationControl = this.drivers.controls[0].primaryDriverRelationship;
        if (!primaryDriverRelationControl.value) {
          primaryDriverRelationControl.setValue('SELF');
        }
      }
    });

    this.nonOwner.valueChanges.pipe(
      startWith(!!this.nonOwner.value),
      distinctUntilChanged(),
      skip(1),
    ).subscribe((nonOwner) => {
      if (nonOwner) {
        this.drivers.controls.slice(1).forEach(
          (_, i) => this.drivers.removeAt(i + 1),
        );

        this.vehicles.controls.forEach(
          (_, i) => this.vehicles.removeAt(i),
        );
        this.vehicles.disable();
        this.coverages.markAsUntouched();
      } else {
        this.vehicles.enable();
        if (this.vehicles.controls.length === 0) {
          this.addVehicle();
        }
      }
    });
  }

  getValue() {
    return {
      ...this.data.getRawValue(),
      carrierId: this.carrierId,
      rateId: this.rateId,
      id: this.id,
      rateHasAbortInstructions: this.rateHasAbortInstructions,
      createdAt: this.createdAt,
      relatedDrivers: this.relatedDrivers || [],
      createdBy: this.createdBy || null,
      lastModifiedBy: this.lastModifiedBy || null
    };
  }

  setValue(data) {
    data.vehicles.forEach((_, i) => {
      this.vehicles.setControl(i, new VehicleForm(this.webServices));
    });

    if (this.vehicles.length === 0) {
      this.addVehicle();
    }

    data.drivers.forEach((driver, i) => {
      this.drivers.setControl(i, new DriverForm());
    });

    if (this.drivers.length === 0) {
      this.addDriver();
    }

    this.data.patchValue(data);
    this.carrierId = data.carrierId;
    this.rateId = data.rateId;
    this.rateHasAbortInstructions = data.rateHasAbortInstructions;
    this.relatedDrivers = data.relatedDrivers;
    this.createdBy = data.createdBy;
    this.lastModifiedBy = data.lastModifiedBy;

    return this.data.statusChanges.pipe(
      startWith(this.data.status),
      first(status => status !== 'PENDING'),
      tap(() => {
        this.data.updateValueAndValidity();

        markFormAsTouched(this.contact);
        if (this.contact.valid) {
          markFormAsTouched(this.drivers);
          if (this.drivers.valid) {
            markFormAsTouched(this.vehicles);
            if (this.vehicles.valid) {
              markFormAsTouched(this.coverages);
            }
          }
        }
      }),
    );
  }

  markAsPristine() {
    this.data.markAsPristine();
  }

  setLastSelectedRate(rate: AutoInsuranceRate) {
    this.lastSelectedRate = rate;
    this.rateHasAbortInstructions = rate.hasAbortInstructions;
  }

  addDriver(initialData: AutoInsuranceDriver = {}) {
    this.drivers.push(new DriverForm(initialData));
    this.drivers.markAsDirty();
    this.drivers.markAsTouched();
  }

  deleteDriver(index) {
    this.drivers.removeAt(index);
    this.drivers.markAsDirty();
  }


  addVehicle() {
    this.vehicles.push(new VehicleForm(this.webServices));
    this.vehicles.markAsDirty();
    this.vehicles.markAsTouched();
  }

  deleteVehicle(index) {
    this.vehicles.removeAt(index);
    this.vehicles.markAsDirty();
  }
}
