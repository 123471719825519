import { Component } from '@angular/core';
import {
  AUTO_INSURANCE_PLACEHOLDERS,
  AUTO_INSURANCE_SOURCES,
  AUTO_INSURANCE_STRINGS,
  STATES,
} from '../../../utils/constants';
import { sortOptionsAlph } from '../../../utils/utils';
import { phoneMask } from 'src/utils/maks';
import { ContactForm } from '../forms';
import { Address } from '../../listo-common/address-auto-complete.directive';
import { AutoRaterDataService } from '../auto-rater-data.service';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  form: ContactForm = this.dataService.contact;
  STRINGS = AUTO_INSURANCE_STRINGS;
  PLACEHOLDERS = AUTO_INSURANCE_PLACEHOLDERS;
  sources = AUTO_INSURANCE_SOURCES.sort(sortOptionsAlph);
  states = STATES.sort(sortOptionsAlph);
  phoneMask = phoneMask;

  constructor(private dataService: AutoRaterDataService) {}

  onAddressChange(addressData: Address) {
    const {
      street,
      number,
      zipCode,
      state,
      city,
      county,
    } = addressData;

    const newData: any = {
      zipCode,
      city,
      county,
      state,
      streetName: street,
      streetNumber: number,
    };

    if (street && number) {
      newData.address = `${number} ${street}`;
    }
    this.form.patchValue(newData);
  }
}
