import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { startOfDay } from './utils';

function observable(obs: (any) => Observable<any>) {
  return ({ value }: AbstractControl): Observable<ValidationErrors | null> => {
    return obs(value).pipe(
      map(() => null),
      catchError((): Observable<ValidationErrors> => of({
          invalid: value,
        }),
      ),
    );
  };
}

function promise(_promise: (any) => Promise<any>) {
  return async ({ value }: AbstractControl): Promise<ValidationErrors | null> => {
    try {
      await _promise(value);
      return null;
    } catch (e) {
      return {
        invalid: value,
      };
    }
  };
}

function number({ value }: AbstractControl): ValidationErrors | null {
  return Number.isInteger(Number(value)) ? null : {
    number: value,
  };
}

function notUndefined({ value }: AbstractControl): ValidationErrors | null {
  return value !== undefined ? null : {
    required: value,
  };
}

function notNull({ value }: AbstractControl): ValidationErrors | null {
  return value !== null ? null : {
    required: value,
  };
}

function boolean({ value }: AbstractControl): ValidationErrors | null {
  return value !== true && value !== false ? {
    boolean: value,
  } : null;
}

function bridgeRequired(control: AbstractControl): ValidationErrors | null {
  return Validators.required(control) ? {
    bridgeRequired: control.value,
  } : null;
}

function bridgeNotNull(control: AbstractControl): ValidationErrors | null {
  return notNull(control) ? {
    bridgeRequired: control.value,
  } : null;
}

function bridgeBoolean(control: AbstractControl): ValidationErrors | null {
  return boolean(control) ? {
    bridgeRequired: control.value,
  } : null;
}

function sameAs(controlName: string) {
  let valueSubs = new Subscription();

  return (control: AbstractControl): ValidationErrors | null => {
    valueSubs.unsubscribe();
    let valid = false;
    const parent = control.parent;
    const otherControl = parent && parent.get(controlName);

    if (otherControl) {
      if (otherControl.value === control.value) {
        valid = true;
      }
      valueSubs = otherControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    return valid ? null : {
      nonEqual: control.value,
    };
  };
}

function phone(control: AbstractControl): ValidationErrors | null {
  const value = String(control.value);
  const formattedPhone = value.replace(/\D/g, '');
  if (formattedPhone.length && (formattedPhone.length < 10 || formattedPhone.length > 13)) {
    return {
      phone: value,
    };
  }
  return null;
}

function afterToday(control: AbstractControl): ValidationErrors | null {
  if (
    control.value
    && control.value instanceof Date
    && startOfDay(control.value) >= startOfDay()
  ) {
    return null;
  }
  return {
    afterToday: control.value,
  };
}

function truthy(control: AbstractControl): ValidationErrors | null {
  return !Boolean(control.value) ? {
    truthy: control.value,
  } : null;
}

function falsy(control: AbstractControl): ValidationErrors | null {
  return Boolean(control.value) ? {
    truthy: control.value,
  } : null;
}

export default {
  observable,
  promise,
  number,
  notUndefined,
  notNull,
  bridgeRequired,
  bridgeNotNull,
  sameAs,
  phone,
  bridgeBoolean,
  afterToday,
  truthy,
  falsy,
};
