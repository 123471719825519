import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { WebServicesService } from '../../web-services.service';
import { AutoRaterDataService } from '../auto-rater-data.service';
import { ModalService } from '../../modal.service';
import { switchMapTo } from 'rxjs/operators';
import * as CONSTANTS from '../../../utils/constants';
import { markFormAsTouched } from '../../../utils/utils';
import { AutoInsuranceRate, AutoRaterCarrierSteps} from '../../../utils/types';
import { AbortModalComponent } from '../abort-modal/abort-modal.component';

@Component({
  selector: 'auto-rater-carrier-config',
  templateUrl: './carrier-config.component.html',
  styleUrls: ['./carrier-config.component.scss'],
})
export class CarrierConfigComponent implements OnInit, OnDestroy {
  loading = false;
  CONSTANTS = CONSTANTS;
  rate: AutoInsuranceRate;
  AutoRaterCarrierSteps = AutoRaterCarrierSteps;
  currentStep: AutoRaterCarrierSteps = AutoRaterCarrierSteps.confirm;
  showRateChangeModal = false;
  showRelatedDriversModal = false;

  @Output() error = new EventEmitter();
  @Output() saveAndExitClick = new EventEmitter();
  @Output() abortClick = new EventEmitter();
  @Output() backToCoverages = new EventEmitter();
  @Output() newDriver = new EventEmitter();
  newDrivers = false;

  constructor(
    private webServices: WebServicesService,
    private dataService: AutoRaterDataService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
    this.fetchRate();
  }

  ngOnDestroy() {
    this.disableQuestionsControls();
  }

  changeStep(step: AutoRaterCarrierSteps) {
    this.currentStep = step;
  }

  fetchRate() {
    this.loading = true;

    this.webServices.selectRate(this.dataService.rateId).subscribe(
      ({ rate }) => {
        this.rate = rate;
        this.dataService.setLastSelectedRate(rate);

        if (this.rate.incidents && this.rate.incidents.length > 0) {
          this.showRateChangeModal = true;
        }
        if (this.rate.underwritingQuestions && this.rate.underwritingQuestions.length) {
          this.enableQuestionsControls();
          this.changeStep(AutoRaterCarrierSteps.questions);
        }
        if (this.rate.relatedDrivers && this.rate.relatedDrivers.length) {
          this.changeStep(AutoRaterCarrierSteps.drivers);
        }
      },
      (e) => {
        this.loading = false;
        this.error.emit(e);
        throw e;
      },
      () => {
        this.loading = false;
      },
    );
  }

  enableQuestionsControls() {
    this.rate.underwritingQuestions.forEach((question) => {
      const questionControl = this.dataService.underwritingQuestions.get(question);
      if (questionControl) {
        questionControl.enable();
      }
    });
  }

  disableQuestionsControls() {
    Object.values(
      this.dataService.underwritingQuestions.controls,
    ).forEach((control) => {
      control.disable();
    });
  }

  verifyNewDrivers(event) {
    this.newDrivers = event;
  }

  saveAndContinue() {
    if (this.currentStep === AutoRaterCarrierSteps.drivers
      && this.newDrivers) {
        this.showRelatedDriversModal = true;
        return;
      }
    if (
      this.currentStep === AutoRaterCarrierSteps.questions
      && this.dataService.underwritingQuestions.invalid
    ) {
      markFormAsTouched(this.dataService.underwritingQuestions);
      return;
    }
    this.loading = true;
    this.webServices.saveQuoteApplication(
      this.dataService.getValue(),
    ).subscribe(
      () => {
        this.loading = false;
        switch (this.currentStep) {
          case AutoRaterCarrierSteps.confirm:
            this.changeStep(this.AutoRaterCarrierSteps.payment);
            break;
          case AutoRaterCarrierSteps.drivers:
            if (this.newDrivers) {
              this.newDriver.emit();
            } else {
              this.changeStep(this.AutoRaterCarrierSteps.confirm);
            }
            break;
        }
      },
      (e) => {
        this.loading = false;
        this.error.emit(e);
        throw e;
      },
    );
  }

  continueInCarrier(selectRate: boolean = true) {
    this.loading = true;

    const observable = this.webServices.saveQuoteApplication(this.dataService.getValue());

    if (selectRate) {
      observable.pipe(
        switchMapTo(
          selectRate ? this.webServices.selectRate(this.dataService.rateId) : null
        )
      );
    }

    observable.subscribe(
      () => {
        this.loading = false;

        this.modalService.custom(AbortModalComponent, {
          hasAbortInstructions: this.dataService.rateHasAbortInstructions,
          quoteApplicationId: this.dataService.id,
          carrierId: this.dataService.carrierId,
          rateId: this.dataService.rateId,
        });
      },
      (e) => {
        this.error.emit(e);
        throw e;
      },
    );
  }

  goToDriversStep() {
    this.newDriver.emit();
    this.showRelatedDriversModal = false;
  }
}
