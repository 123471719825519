import { Component, Input } from '@angular/core';
import { AppRoute } from '../../utils/types';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class TabComponent {
  @Input() route: AppRoute;
  open = false;

  constructor(private router: Router) {}

  toggleOpen() {
    this.open = !this.open;
  }

  getChildPath(childRoute: AppRoute) {
    return `/${this.route.path}/${childRoute.path}`;
  }

  hasChildren() {
    return this.route.children && this.route.children.some(
      child => child.showInSidebar,
    );
  }

  getChildren() {
    return this.route.children && this.route.children.filter(
      child => child.showInSidebar,
    );
  }

  isSomeChildActive() {
    return this.route.children.some((route) => this.router.isActive(
      this.router.createUrlTree([this.getChildPath(route)]),
      true,
    ));
  }
}
