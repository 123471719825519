import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import FormFieldComponent from '../form-field.component';
import { getOptionLabel, getOptionValue } from '../../../utils/options';
import { FormControlError, Option } from '../../../utils/types';
import CustomFormControl from '../../auto-rater/CustomFormControl';

@Component({
  selector: 'listo-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends FormFieldComponent implements OnInit {
  @Input() options: Option[] = [];
  @Input() control: CustomFormControl;
  @Input() name: string;
  @Input() label!: string;
  @Input() required = false;
  @Input() placeholder?: string;
  @Input() pending?: boolean;
  @Input() value: any;
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() disabled?: boolean;
  @Input() error?: FormControlError;
  @Output() change = new EventEmitter();
  @ViewChild('select') selectElement;

  open = false;

  getOptionLabel = getOptionLabel;
  getOptionValue = getOptionValue;

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.listenControlChanges();
  }

  getSelectedLabel() {
    const value = this.getValue();
    const selected = this.options.find(
      option => getOptionValue(option) === value,
    );
    return selected ? getOptionLabel(selected) : '';
  }

  onOpen() {
    this.open = true;
  }

  onClose() {
    if (this.control) {
      this.control.markAsTouched();
    }
    this.open = false;
  }

  onOptionMouseDown(value) {
    this.onClose();
    this.selectElement.nativeElement.blur();
    this.onChange(value);
  }

  onMouseDown(event) {
    if (this.open) {
      event.preventDefault();
      this.selectElement.nativeElement.blur();
    }
  }
}
