<ng-container *ngIf="rate" [ngSwitch]="currentStep">
  <nav class="nav-buttons">
    <span class="nav-carrier-name">{{rate.carrier.name}}:</span>
    <button
      class="nav-button"
      [class.selected]="currentStep === AutoRaterCarrierSteps.drivers"
      (click)="changeStep(AutoRaterCarrierSteps.drivers)"
      *ngIf="rate?.relatedDrivers?.length > 0"
    >
      {{CONSTANTS.CARRIER_DRIVERS_STEP}}
    </button>
    <button
      class="nav-button"
      [class.selected]="currentStep === AutoRaterCarrierSteps.questions"
      (click)="changeStep(AutoRaterCarrierSteps.questions)"
      *ngIf="rate?.underwritingQuestions?.length > 0"
    >
      {{CONSTANTS.CARRIER_QUESTIONS_STEP}}
    </button>
    <button
      class="nav-button"
      [class.selected]="currentStep === AutoRaterCarrierSteps.confirm"
      (click)="changeStep(AutoRaterCarrierSteps.confirm)"
    >
      {{CONSTANTS.CARRIER_CONFIRM_STEP}}
    </button>
    <button
      class="nav-button"
      [class.selected]="currentStep === AutoRaterCarrierSteps.payment"
      [class.disabled]="true"
      (click)="changeStep(AutoRaterCarrierSteps.payment)"
    >
      {{CONSTANTS.CARRIER_SALE_STEP}}
    </button>
  </nav>

  <auto-rater-carrier-drivers
    *ngSwitchCase="AutoRaterCarrierSteps.drivers"
    (newDrivers)="verifyNewDrivers($event)"
  >
  </auto-rater-carrier-drivers>
  <auto-rater-carrier-questions
    *ngSwitchCase="AutoRaterCarrierSteps.questions"
  ></auto-rater-carrier-questions>


  <auto-rater-carrier-confirm
    *ngSwitchCase="AutoRaterCarrierSteps.confirm"
  ></auto-rater-carrier-confirm>

  <auto-rater-carrier-payment
    *ngSwitchCase="AutoRaterCarrierSteps.payment"
    [rate]="rate"
    (saveAndExit)="saveAndExitClick.emit()"
    (continueInCarrier)="continueInCarrier()"
  ></auto-rater-carrier-payment>

  <ng-container *ngIf="currentStep !== AutoRaterCarrierSteps.payment">
    <listo-button
      class="action-button"
      (click)="saveAndContinue()"
      [disabled]="loading"
    >
      {{CONSTANTS.SAVE_AND_CONTINUE}}
    </listo-button>
    <listo-button
      class="action-button"
      (click)="continueInCarrier(false)"
      [disabled]="loading"
      [flat]="true"
    >
      {{CONSTANTS.CARRIER_ABORT_BUTTON}}
    </listo-button>
  </ng-container>
</ng-container>

<listo-modal *ngIf="loading" class="loading-modal">
  <listo-spinner size="80"></listo-spinner>
  <h1 class="header">{{CONSTANTS.MOMENT_PLEASE}}</h1>
  <p class="body">{{CONSTANTS.CARRIER_CONNECTION}}</p>
</listo-modal>

<listo-modal *ngIf="showRelatedDriversModal" (close)="saveAndContinue()" class="marital-warning-modal">
  <img src="../../assets/images/warning.svg"/>
  <h1 class="header">{{CONSTANTS.CARRIER_DRIVERS_MODAL_TITLE}}</h1>
  <p class="body">{{CONSTANTS.CARRIER_DRIVERS_MODAL_DESC}}</p>

  <listo-button (click)="goToDriversStep()">{{CONSTANTS.CARRIER_DRIVERS_MODAL_BUTTON_OK}}</listo-button>
  <listo-button (click)="showRelatedDriversModal = false" [invertColor]="true">{{CONSTANTS.CARRIER_DRIVERS_MODAL_BUTTON_CANCEL}}</listo-button>
</listo-modal>

<auto-rater-rate-change-modal
  *ngIf="showRateChangeModal"
  [newRate]="rate"
  (close)="showRateChangeModal = false"
  (continueInCarrier)="continueInCarrier()"
  (backToCoverages)="backToCoverages.emit()"
></auto-rater-rate-change-modal>
