<listo-modal (close)="close.emit()">
  <ng-container *ngIf="loading">
    <listo-spinner size="80"></listo-spinner>
    <h2 class="loading-heading">{{CONSTANTS.MOMENT_PLEASE}}</h2>
    <p>{{CONSTANTS.CARRIER_CONNECTION}}</p>
  </ng-container>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="hasAbortInstructions ; else abortReady">
      <h2>{{CONSTANTS.CARRIER_ABORT_INSTRUCTIONS}}</h2>
      <p>{{abortData.instructions}}</p>
    </ng-container>
    <ng-template #abortReady>
      <h2>{{CONSTANTS.CARRIER_ABORT_READY}}</h2>
      <p>{{CONSTANTS.CARRIER_ABORT_READY_DESC}}</p>
    </ng-template>
    <listo-button (click)="openAbortUrl()">{{CONSTANTS.CARRIER_ABORT_BUTTON}}</listo-button>
  </ng-container>
</listo-modal>
