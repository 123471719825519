<p class="required-label">
  <ng-container i18n>Required fields</ng-container>
  <img src="../../../assets/images/required.svg">
</p>
<listo-card
  [header]="constants.QUESTIONS_LABEL"
  [collapsible]="true"
  [collapsed]="false"
  class="card"
>
  <div class="body" body>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.isAddressPrincipal.enabled"
      [control]="dataService.underwritingQuestions.isAddressPrincipal"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.isAddressPrincipal"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areAllDriversReported.enabled"
      [control]="dataService.underwritingQuestions.areAllDriversReported"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areAllDriversReported"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasCommittedWorkmensFraud.enabled"
      [control]="dataService.underwritingQuestions.hasCommittedWorkmensFraud"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasCommittedWorkmensFraud"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasRentedVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasRentedVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasRentedVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasRideShareVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasRideShareVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasRideShareVehicle"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.rideShareVehicleReason.enabled"
      [control]="dataService.underwritingQuestions.rideShareVehicleReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasServicesVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasServicesVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasServicesVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasDifferentAddressVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasDifferentAddressVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasDifferentAddressVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasAnotherVehicles.enabled"
      [control]="dataService.underwritingQuestions.hasAnotherVehicles"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasAnotherVehicles"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.anotherVehiclesReason.enabled"
      [control]="dataService.underwritingQuestions.anotherVehiclesReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasPriorInsurance.enabled"
      [control]="dataService.underwritingQuestions.hasPriorInsurance"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasPriorInsurance"
      class="question"
    ></listo-radios>
    <listo-select
      *ngIf="dataService.underwritingQuestions.priorInsuranceCompany.enabled"
      [control]="dataService.underwritingQuestions.priorInsuranceCompany"
      [options]="constants.INSURANCE_COMPANIES"
      [label]="constants.QUESTIONS.priorInsuranceCompany"
      class="question"
    ></listo-select>
    <listo-select
      *ngIf="dataService.underwritingQuestions.priorInsuranceBILimit.enabled"
      [control]="dataService.underwritingQuestions.priorInsuranceBILimit"
      [options]="constants.INSURANCE_LIMITS"
      [label]="constants.QUESTIONS.priorInsuranceBILimit"
      class="question"
    ></listo-select>
    <listo-date-picker
      *ngIf="dataService.underwritingQuestions.priorInsuranceExpDate.enabled"
      [control]="dataService.underwritingQuestions.priorInsuranceExpDate"
      [label]="constants.QUESTIONS.priorInsuranceExpDate"
      class="question"
    ></listo-date-picker>
    <listo-select
      *ngIf="dataService.underwritingQuestions.qualifiesForAffinityProgram.enabled"
      [control]="dataService.underwritingQuestions.qualifiesForAffinityProgram"
      [options]="constants.AFFILIATE_PROGRAMS"
      [label]="constants.QUESTIONS.qualifiesForAffinityProgram"
      class="question"
    ></listo-select>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.wantsDocumentsViaEmail.enabled"
      [control]="dataService.underwritingQuestions.wantsDocumentsViaEmail"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.wantsDocumentsViaEmail"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasAnotherNonVehiclePolicy.enabled"
      [control]="dataService.underwritingQuestions.hasAnotherNonVehiclePolicy"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasAnotherNonVehiclePolicy"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areAllDriversOlderThan15Reported.enabled"
      [control]="dataService.underwritingQuestions.areAllDriversOlderThan15Reported"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areAllDriversOlderThan15Reported"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.allDriversOlderThan15ReportedReason.enabled"
      [control]="dataService.underwritingQuestions.allDriversOlderThan15ReportedReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasNonIncludedDriver.enabled"
      [control]="dataService.underwritingQuestions.hasNonIncludedDriver"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasNonIncludedDriver"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.nonIncludedDriverReason.enabled"
      [control]="dataService.underwritingQuestions.nonIncludedDriverReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasVehicleInDifferentAddress.enabled"
      [control]="dataService.underwritingQuestions.hasVehicleInDifferentAddress"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasVehicleInDifferentAddress"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.vehicleInDifferentAddressReason.enabled"
      [control]="dataService.underwritingQuestions.vehicleInDifferentAddressReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasVehicleInDifferentRegister.enabled"
      [control]="dataService.underwritingQuestions.hasVehicleInDifferentRegister"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasVehicleInDifferentRegister"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.vehicleInDifferentRegisterReason.enabled"
      [control]="dataService.underwritingQuestions.vehicleInDifferentRegisterReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasModifiedVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasModifiedVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasModifiedVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasDamagedVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasDamagedVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasDamagedVehicle"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.damagedVehicleReason.enabled"
      [control]="dataService.underwritingQuestions.damagedVehicleReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasWorkVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasWorkVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasWorkVehicle"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.workVehicleReason.enabled"
      [control]="dataService.underwritingQuestions.workVehicleReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areQuestionsConfirmed.enabled"
      [control]="dataService.underwritingQuestions.areQuestionsConfirmed"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areQuestionsConfirmed"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areAllVehiclesInSameAddress.enabled"
      [control]="dataService.underwritingQuestions.areAllVehiclesInSameAddress"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areAllVehiclesInSameAddress"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.agreesToIntLicenseConfirm.enabled"
      [control]="dataService.underwritingQuestions.agreesToIntLicenseConfirm"
      [options]="constants.INT_LICENSE_OPTIONS"
      [label]="constants.QUESTIONS.agreesToIntLicenseConfirm"
      cols="3"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasProhibitedRisks.enabled"
      [control]="dataService.underwritingQuestions.hasProhibitedRisks"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasProhibitedRisks"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areAllFrecuentDriversReported.enabled"
      [control]="dataService.underwritingQuestions.areAllFrecuentDriversReported"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areAllFrecuentDriversReported"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.areAllVehicleRegisterDriversReported.enabled"
      [control]="dataService.underwritingQuestions.areAllVehicleRegisterDriversReported"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.areAllVehicleRegisterDriversReported"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasPreviouslyRentedVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasPreviouslyRentedVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasPreviouslyRentedVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasExtendedServicesVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasExtendedServicesVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasExtendedServicesVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasCommercialVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasCommercialVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasCommercialVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasExtremeSportsVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasExtremeSportsVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasExtremeSportsVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasHeavyLiftingVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasHeavyLiftingVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasHeavyLiftingVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasSalvagedVehicle.enabled"
      [control]="dataService.underwritingQuestions.hasSalvagedVehicle"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasSalvagedVehicle"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasCalifornianData.enabled"
      [control]="dataService.underwritingQuestions.hasCalifornianData"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasCalifornianData"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.ownsAllVehicles.enabled"
      [control]="dataService.underwritingQuestions.ownsAllVehicles"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.ownsAllVehicles"
      class="question"
    ></listo-radios>
    <listo-input
      *ngIf="dataService.underwritingQuestions.ownsAllVehiclesReason.enabled"
      [control]="dataService.underwritingQuestions.ownsAllVehiclesReason"
      [label]="constants.QUESTIONS.reason"
      class="question"
    ></listo-input>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasCommittedFraud.enabled"
      [control]="dataService.underwritingQuestions.hasCommittedFraud"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasCommittedFraud"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasAnotherPolicy.enabled"
      [control]="dataService.underwritingQuestions.hasAnotherPolicy"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasAnotherPolicy"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.anotherPolicyNumber.enabled"
      [control]="dataService.underwritingQuestions.anotherPolicyNumber"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.anotherPolicyNumber"
      class="question"
    ></listo-radios>
    <listo-radios
      *ngIf="dataService.underwritingQuestions.hasUnpaidOrDeclinedPolicy.enabled"
      [control]="dataService.underwritingQuestions.hasUnpaidOrDeclinedPolicy"
      [options]="constants.YESNO"
      [label]="constants.QUESTIONS.hasUnpaidOrDeclinedPolicy"
    ></listo-radios>
    <listo-select
      *ngIf="dataService.underwritingQuestions.isPrimaryResidenceOwned.enabled"
      [control]="dataService.underwritingQuestions.isPrimaryResidenceOwned"
      [options]="constants.RESIDENCE_OWNERSHIP"
      [label]="constants.QUESTIONS.isPrimaryResidenceOwned"
      class="question"
    ></listo-select>
  </div>
</listo-card>
