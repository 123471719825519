import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebServicesService } from '../web-services.service';
import CustomValidators from '../../utils/validators';
import { getLimitFirstValue, getMonthsDiff, getYearsDiff } from '../../utils/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { merge, zip } from 'rxjs';
import CustomFormControl from './CustomFormControl';
import { AutoInsuranceDriver } from '../../utils/types';


export class ContactForm extends FormGroup {
  constructor(private webServices: WebServicesService) {
    super({
      firstName: new CustomFormControl(null, {
        validators: Validators.required,
      }),
      lastName: new CustomFormControl(null, {
        validators: Validators.required,
      }),
      phoneNumber: new CustomFormControl(null, {
        validators: [Validators.required, CustomValidators.phone],
      }),
      email: new CustomFormControl(null),
      address: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      zipCode: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(\d{5}|\d{5}-\d{4})$/),
      ]),
      state: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      city: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      county: new CustomFormControl(null),
      source: new CustomFormControl(null),
      streetName: new CustomFormControl(null),
      streetNumber: new CustomFormControl(null),
      hasMailingAddress: new CustomFormControl(false),
      mailingAddress: new CustomFormControl(
        { value: null, disabled: true },
        { validators: Validators.required },
      ),
      mailingState: new CustomFormControl(
        { value: null, disabled: true },
        { validators: Validators.required },
      ),
      mailingZipCode: new CustomFormControl(
        { value: null, disabled: true },
        {
          validators: [
            Validators.required,
            Validators.pattern(/^(\d{5}|\d{5}-\d{4})$/),
          ],
        },
      ),
      mailingCity: new CustomFormControl(
        { value: null, disabled: true },
        { validators: Validators.required },
      ),
    });

    this.zipCode.setAsyncValidators(
      CustomValidators.promise(this.validateZipCode),
    );

    this.mailingZipCode.setAsyncValidators(
      CustomValidators.promise(this.validateMailingZipCode),
    );
    this.subscribeToChanges();
  }

  get firstName() {
    return this.get('firstName') as CustomFormControl;
  }

  get lastName() {
    return this.get('lastName') as CustomFormControl;
  }

  get phoneNumber() {
    return this.get('phoneNumber') as CustomFormControl;
  }

  get email() {
    return this.get('email') as CustomFormControl;
  }

  get address() {
    return this.get('address') as CustomFormControl;
  }

  get zipCode() {
    return this.get('zipCode') as CustomFormControl;
  }

  get state() {
    return this.get('state') as CustomFormControl;
  }

  get city() {
    return this.get('city') as CustomFormControl;
  }

  get county() {
    return this.get('county') as CustomFormControl;
  }

  get source() {
    return this.get('source') as CustomFormControl;
  }

  get streetName() {
    return this.get('streetName') as CustomFormControl;
  }

  get streetNumber() {
    return this.get('streetNumber') as CustomFormControl;
  }

  get hasMailingAddress() {
    return this.get('hasMailingAddress') as CustomFormControl;
  }

  get mailingAddress() {
    return this.get('mailingAddress') as CustomFormControl;
  }

  get mailingState() {
    return this.get('mailingState') as CustomFormControl;
  }

  get mailingZipCode() {
    return this.get('mailingZipCode') as CustomFormControl;
  }

  get mailingCity() {
    return this.get('mailingCity') as CustomFormControl;
  }

  private validateZipCode = async (zipCode) => {
    const { state, city, county } = await this.webServices.getCities(zipCode);
    this.patchValue({ state, city, county });
  }

  private validateMailingZipCode = async (zipCode) => {
    const { state, city } = await this.webServices.getCities(zipCode);
    this.patchValue({
      mailingState: state,
      mailingCity: city,
    });
  }

  private subscribeToChanges() {
    zip(
      this.hasMailingAddress.valueChanges,
      this.hasMailingAddress.statusChanges,
    ).subscribe(this.onHasMailingAddressChange);
  }

  private onHasMailingAddressChange = ([hasMailingAddress, status]) => {
    if (hasMailingAddress && status !== 'DISABLED') {
      this.mailingAddress.enable();
      this.mailingZipCode.enable();
      this.mailingState.enable();
      this.mailingCity.enable();
    } else {
      this.mailingAddress.disable();
      this.mailingZipCode.disable();
      this.mailingState.disable();
      this.mailingCity.disable();
    }
  }
}

export class DriverForm extends FormGroup {
  constructor(initialData: AutoInsuranceDriver = {}) {
    super({
      excluded: new CustomFormControl(initialData.excluded || false),
      firstName: new CustomFormControl(initialData.firstName || null, {
        validators: Validators.required,
      }),
      lastName: new CustomFormControl(initialData.lastName || null, {
        validators: Validators.required,
      }),
      birthdate: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      age: new CustomFormControl(null, [
        Validators.required,
        CustomValidators.number,
        Validators.min(18),
      ]),
      sex: new CustomFormControl(null, Validators.required),
      marital: new CustomFormControl(initialData.marital || null, Validators.required),
      otherMarital: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      industry: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      occupation: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      priorInsurance: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeBoolean,
      }),
      priorInsuranceYears: new CustomFormControl(
        { value: null, disabled: true },
        { warningValidators: CustomValidators.bridgeRequired },
      ),
      priorInsuranceMonths: new CustomFormControl(
        { value: null, disabled: true },
        { warningValidators: CustomValidators.bridgeRequired },
      ),
      priorInsuranceExpDate: new CustomFormControl(
        { value: null, disabled: true },
        { warningValidators: CustomValidators.bridgeRequired },
      ),
      dmvCertification: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeNotNull,
      }),
      dmvCertificationState: new CustomFormControl(
        { value: null, disabled: true },
        { warningValidators: CustomValidators.bridgeRequired },
      ),
      dmvCertificationReason: new CustomFormControl(
        { value: null, disabled: true },
        { warningValidators: CustomValidators.bridgeRequired },
      ),
      licenseType: new CustomFormControl(null, {
        validators: Validators.required,
      }),
      licenseStatus: new CustomFormControl(null, {
        validators: Validators.required,
      }),
      licenseNumber: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      licenseState: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      licenseYears: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      licenseMonths: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      totalLicenseYears: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      totalLicenseMonths: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      race: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      primaryDriverRelationship: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      hasMatureDriverCourse: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeBoolean,
      }),
      matureDriverCourseDate: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      isGoodStudent: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeBoolean,
      }),
    });
    this.subscribeToChanges();
  }

  get excluded() {
    return this.get('excluded') as CustomFormControl;
  }

  get firstName() {
    return this.get('firstName') as CustomFormControl;
  }

  get lastName() {
    return this.get('lastName') as CustomFormControl;
  }

  get birthdate() {
    return this.get('birthdate') as CustomFormControl;
  }

  get age() {
    return this.get('age') as CustomFormControl;
  }

  get sex() {
    return this.get('sex') as CustomFormControl;
  }

  get marital() {
    return this.get('marital') as CustomFormControl;
  }

  get otherMarital() {
    return this.get('otherMarital') as CustomFormControl;
  }

  get industry() {
    return this.get('industry') as CustomFormControl;
  }

  get occupation() {
    return this.get('occupation') as CustomFormControl;
  }

  get priorInsurance() {
    return this.get('priorInsurance') as CustomFormControl;
  }

  get priorInsuranceYears() {
    return this.get('priorInsuranceYears') as CustomFormControl;
  }

  get priorInsuranceMonths() {
    return this.get('priorInsuranceMonths') as CustomFormControl;
  }

  get priorInsuranceExpDate() {
    return this.get('priorInsuranceExpDate') as CustomFormControl;
  }

  get dmvCertification() {
    return this.get('dmvCertification') as CustomFormControl;
  }

  get dmvCertificationState() {
    return this.get('dmvCertificationState') as CustomFormControl;
  }

  get dmvCertificationReason() {
    return this.get('dmvCertificationReason') as CustomFormControl;
  }

  get licenseType() {
    return this.get('licenseType') as CustomFormControl;
  }

  get licenseStatus() {
    return this.get('licenseStatus') as CustomFormControl;
  }

  get licenseNumber() {
    return this.get('licenseNumber') as CustomFormControl;
  }

  get licenseState() {
    return this.get('licenseState') as CustomFormControl;
  }

  get licenseYears() {
    return this.get('licenseYears') as CustomFormControl;
  }

  get licenseMonths() {
    return this.get('licenseMonths') as CustomFormControl;
  }

  get totalLicenseYears() {
    return this.get('totalLicenseYears') as CustomFormControl;
  }

  get totalLicenseMonths() {
    return this.get('totalLicenseMonths') as CustomFormControl;
  }

  get race() {
    return this.get('race') as CustomFormControl;
  }

  get primaryDriverRelationship() {
    return this.get('primaryDriverRelationship') as CustomFormControl;
  }

  get hasMatureDriverCourse() {
    return this.get('hasMatureDriverCourse') as CustomFormControl;
  }

  get matureDriverCourseDate() {
    return this.get('matureDriverCourseDate') as CustomFormControl;
  }

  get isGoodStudent() {
    return this.get('isGoodStudent') as CustomFormControl;
  }

  private subscribeToChanges() {
    this.birthdate.valueChanges.subscribe(this.onBirthdateChange);
    this.age.valueChanges.subscribe(this.onAgeChange);
    zip(
      this.priorInsurance.valueChanges,
      this.priorInsurance.statusChanges,
    ).subscribe(this.onPriorInsuranceChange);
    zip(
      this.dmvCertification.valueChanges,
      this.dmvCertification.statusChanges,
    ).subscribe(this.onDmvCertificationChange);
    zip(
      this.marital.valueChanges,
      this.marital.statusChanges,
    ).subscribe(this.onMaritalChange);
    zip(
      this.hasMatureDriverCourse.valueChanges,
      this.hasMatureDriverCourse.statusChanges,
    ).subscribe(this.onHasMatureDriverCourseChange);
    zip(
      this.licenseType.valueChanges,
      this.licenseType.statusChanges,
    ).subscribe(this.onLicenseTypeChange);
    this.excluded.valueChanges.subscribe(this.onExcludedChange);
  }

  private onBirthdateChange = (birthdate) => {
    if (birthdate && birthdate instanceof Date) {
      const age = getYearsDiff(birthdate);
      this.age.setValue(age, { emitEvent: false });
      this.calculateFromAge(age);
    }
  }

  private onAgeChange = (age) => {
    this.birthdate.setValue(null, { emitEvent: false });
    this.calculateFromAge(age);
  }

  private calculateFromAge = (age) => {
    // When age changes, because direct input or calculated from birthdate modication
    // trigger calculations on page
    const birthdate = this.birthdate.value;
    const expYears = Math.max(age - 16, 0);
    const expMonths = birthdate ? getMonthsDiff(birthdate) : 0;

    this.licenseYears.setValue(expYears);
    this.licenseMonths.setValue(expMonths);
    this.totalLicenseYears.setValue(expYears);
    this.totalLicenseMonths.setValue(expMonths);
  }

  private onPriorInsuranceChange = ([priorInsurance, status]) => {
    const priorInsuranceYears = this.priorInsuranceYears;
    const priorInsuranceMonths = this.priorInsuranceMonths;
    const priorInsuranceExpDate = this.priorInsuranceExpDate;

    if (priorInsurance && status !== 'DISABLED') {
      priorInsuranceYears.enable();
      priorInsuranceMonths.enable();
      priorInsuranceExpDate.enable();
    } else {
      priorInsuranceYears.disable();
      priorInsuranceMonths.disable();
      priorInsuranceExpDate.disable();
    }
  }

  private onDmvCertificationChange = ([dmvCertification, status]) => {
    const dmvCertificationState = this.dmvCertificationState;
    const dmvCertificationReason = this.dmvCertificationReason;

    if (dmvCertification && status !== 'DISABLED') {
      dmvCertificationState.enable();
      dmvCertificationReason.enable();
    } else {
      dmvCertificationState.disable();
      dmvCertificationReason.disable();
    }
  }

  private onMaritalChange = ([marital, status]) => {
    const maritalControl = this.marital;
    const otherMaritalControl = this.otherMarital;

    if (marital && status !== 'DISABLED') {
      otherMaritalControl.disable();
    } else {
      otherMaritalControl.enable();
    }
    maritalControl.updateValueAndValidity({ emitEvent: false });
  }

  private onHasMatureDriverCourseChange = ([hasMatureDriverCourse, status]) => {
    const matureDriverCourseDate = this.matureDriverCourseDate;

    if (hasMatureDriverCourse && status !== 'DISABLED') {
      matureDriverCourseDate.enable();
    } else {
      matureDriverCourseDate.disable();
    }
  }

  private onLicenseTypeChange = ([licenseType, status]) => {
    const licenseNumberControl = this.licenseNumber;
    const licenseStateControl = this.licenseState;

    if (licenseType === 'us_license' && status !== 'DISABLED') {
      licenseNumberControl.enable();
      licenseStateControl.enable();
    } else {
      licenseNumberControl.disable();
      licenseStateControl.disable();
    }
  }

  private onExcludedChange = (excluded) => {
    const method = excluded ? 'disable' : 'enable';

    this.industry[method]();
    this.occupation[method]();
    this.priorInsurance[method]();
    this.dmvCertification[method]();
    this.licenseType[method]();
    this.licenseStatus[method]();
    this.licenseYears[method]();
    this.licenseMonths[method]();
    this.totalLicenseYears[method]();
    this.totalLicenseMonths[method]();
    this.race[method]();
    this.hasMatureDriverCourse[method]();
    this.isGoodStudent[method]();
  }
}

export class RelatedDriverForm extends FormGroup {
  constructor() {
  super({
    name: new CustomFormControl(null, Validators.required),
    relationshipType: new CustomFormControl(null, Validators.required)
  });
}
}

export class VehicleForm extends FormGroup {
  constructor(private webServices: WebServicesService) {
    super({
      excluded: new CustomFormControl(false),
      vin: new CustomFormControl(null, Validators.required),
      year: new CustomFormControl(null, Validators.required),
      maker: new CustomFormControl(null, Validators.required),
      model: new CustomFormControl(null, Validators.required),
      annualMiles: new CustomFormControl(null, {
        validators: Validators.required,
      }),
      workMiles: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      usage: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      homingDevice: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeBoolean,
      }),
      salvaged: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeBoolean,
      }),
      rideShare: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeNotNull,
      }),
      primaryOperator: new CustomFormControl(0, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      coverageType: new CustomFormControl('basic'),
      compDed: new CustomFormControl(null),
      collDed: new CustomFormControl(null),
      towingDed: new CustomFormControl(null),
      rentalDed: new CustomFormControl(null),
      antiTheftDevice: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeNotNull,
      }),
      odometer: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      purchaseDate: new CustomFormControl(null, {
        warningValidators: CustomValidators.bridgeRequired,
      }),
      hasLossPayee: new CustomFormControl(false),
      lossPayeeType: new CustomFormControl(
        { value: null, disabled: true },
        { validators: Validators.required },
      ),
      institutionName: new CustomFormControl(null),
      institutionAddress: new CustomFormControl(null),
      institutionZipCode: new CustomFormControl(null, {
        validators: Validators.pattern(/^(\d{5}|\d{5}-\d{4})$/),
      }),
      institutionState: new CustomFormControl(null),
      institutionCity: new CustomFormControl(null),
    });
    this.subscribeToChanges();

    this.vin.setAsyncValidators(
      CustomValidators.promise(this.validateVin),
    );

    this.institutionZipCode.setAsyncValidators(
      CustomValidators.promise(this.validateInstitutionZipCode),
    );
  }

  get excluded() {
    return this.get('excluded') as CustomFormControl;
  }

  get vin() {
    return this.get('vin') as CustomFormControl;
  }

  get year() {
    return this.get('year') as CustomFormControl;
  }

  get maker() {
    return this.get('maker') as CustomFormControl;
  }

  get model() {
    return this.get('model') as CustomFormControl;
  }

  get annualMiles() {
    return this.get('annualMiles') as CustomFormControl;
  }

  get workMiles() {
    return this.get('workMiles') as CustomFormControl;
  }

  get usage() {
    return this.get('usage') as CustomFormControl;
  }

  get homingDevice() {
    return this.get('homingDevice') as CustomFormControl;
  }

  get salvaged() {
    return this.get('salvaged') as CustomFormControl;
  }

  get rideShare() {
    return this.get('rideShare') as CustomFormControl;
  }

  get primaryOperator() {
    return this.get('primaryOperator') as CustomFormControl;
  }

  get coverageType() {
    return this.get('coverageType') as CustomFormControl;
  }

  get compDed() {
    return this.get('compDed') as CustomFormControl;
  }

  get collDed() {
    return this.get('collDed') as CustomFormControl;
  }

  get towingDed() {
    return this.get('towingDed') as CustomFormControl;
  }

  get rentalDed() {
    return this.get('rentalDed') as CustomFormControl;
  }

  get antiTheftDevice() {
    return this.get('antiTheftDevice') as CustomFormControl;
  }

  get odometer() {
    return this.get('odometer') as CustomFormControl;
  }

  get purchaseDate() {
    return this.get('purchaseDate') as CustomFormControl;
  }

  get hasLossPayee() {
    return this.get('hasLossPayee') as CustomFormControl;
  }

  get lossPayeeType() {
    return this.get('lossPayeeType') as CustomFormControl;
  }

  get institutionName() {
    return this.get('institutionName') as CustomFormControl;
  }

  get institutionAddress() {
    return this.get('institutionAddress') as CustomFormControl;
  }

  get institutionZipCode() {
    return this.get('institutionZipCode') as CustomFormControl;
  }

  get institutionState() {
    return this.get('institutionState') as CustomFormControl;
  }

  get institutionCity() {
    return this.get('institutionCity') as CustomFormControl;
  }

  private validateVin = async (vin) => {
    const { year, maker, model } = await this.webServices.validateVin(vin);
    this.year.setValue(year);
    this.maker.setValue(maker);
    this.model.setValue(model);
  }

  private validateInstitutionZipCode = async (zipCode) => {
    if (!zipCode) {
      return;
    }
    const { state, city } = await this.webServices.getCities(zipCode);
    this.patchValue({
      institutionState: state,
      institutionCity: city,
    });
  }

  private subscribeToChanges() {
    this.year.valueChanges.subscribe(
      () => this.maker.setValue(null),
    );

    this.maker.valueChanges.subscribe(
      () => this.model.setValue(null),
    );

    this.compDed.valueChanges.pipe(distinctUntilChanged()).subscribe(this.clearCoverageType);
    this.collDed.valueChanges.pipe(distinctUntilChanged()).subscribe(this.clearCoverageType);
    this.coverageType.valueChanges.pipe(distinctUntilChanged()).subscribe(this.onCoverageTypeChange);
    merge(
      this.compDed.valueChanges,
      this.collDed.valueChanges,
    ).subscribe(this.onCoveragesChange);

    zip(
      this.hasLossPayee.valueChanges,
      this.hasLossPayee.statusChanges,
    ).subscribe(this.onHasLossPayeeChange);
  }

  private onCoverageTypeChange = coverageType => {
    const compDedControl = this.compDed;
    const collDedControl = this.collDed;

    if (coverageType === 'basic') {
      compDedControl.setValue(null, { emitEvent: false });
      collDedControl.setValue(null, { emitEvent: false });
    } else if (coverageType === 'medium') {
      compDedControl.setValue(1000, { emitEvent: false });
      collDedControl.setValue(1000, { emitEvent: false });
    } else if (coverageType === 'max') {
      compDedControl.setValue(500, { emitEvent: false });
      collDedControl.setValue(500, { emitEvent: false });
    }
  }

  private clearCoverageType = () => {
    this.coverageType.setValue(null, { emitEvent: false });
  }

  private onCoveragesChange = () => {
    const compDed = this.compDed.value;
    const collDed = this.collDed.value;
    const coverageTypeControl = this.coverageType;

    if (compDed === 500 && collDed === 500) {
      coverageTypeControl.setValue('max', { emitEvent: false });
    } else if (compDed === 1000 && collDed === 1000) {
      coverageTypeControl.setValue('medium', { emitEvent: false });
    } else if (compDed === null && collDed === null) {
      coverageTypeControl.setValue('basic', { emitEvent: false });
    } else {
      coverageTypeControl.setValue(null, { emitEvent: false });
    }
  }

  private onHasLossPayeeChange = ([hasLossPayee, status]) => {
    if (hasLossPayee && status !== 'DISABLED') {
      this.lossPayeeType.enable();
    } else {
      this.lossPayeeType.disable();
    }
  }
}

export class CoveragesForm extends FormGroup {
  constructor() {
    super({
      liabBILimit: new CustomFormControl('15/30'),
      liabPDLimit: new CustomFormControl(5),
      uninsBILimit: new CustomFormControl(null),
      uninsPD: new CustomFormControl({ value: false, disabled: true }),
      accDeathLimit: new CustomFormControl(null),
      medPayLimit: new CustomFormControl(null),
    });

    this.uninsBILimit.valueChanges.subscribe((uninsBILimit) => {
      const uninsPDControl = this.uninsPD;
      if (uninsBILimit) {
        uninsPDControl.enable();
      } else {
        uninsPDControl.disable();
        uninsPDControl.setValue(false);
      }
    });

    this.liabBILimit.valueChanges.subscribe((liabBILimit) => {
      const uninsBILimitControl = this.uninsBILimit;
      if (getLimitFirstValue(uninsBILimitControl.value) > getLimitFirstValue(liabBILimit)) {
        uninsBILimitControl.setValue(liabBILimit);
      }
    });
  }

  get liabBILimit() {
    return this.get('liabBILimit') as CustomFormControl;
  }

  get liabPDLimit() {
    return this.get('liabPDLimit') as CustomFormControl;
  }

  get uninsBILimit() {
    return this.get('uninsBILimit') as CustomFormControl;
  }

  get uninsPD() {
    return this.get('uninsPD') as CustomFormControl;
  }

  get accDeathLimit() {
    return this.get('accDeathLimit') as CustomFormControl;
  }

  get medPayLimit() {
    return this.get('medPayLimit') as CustomFormControl;
  }
}
