<button
  class="button"
  (click)="onClick()"
>
  <listo-svg-icon icon="notification" class="icon"></listo-svg-icon>
  <span class="badge" *ngIf="notificationsLength > 0">{{notificationsLength}}</span>
</button>
<div class="overlay" [class.hidden]="!open">
  <div class="header" i18n>Notifications</div>
  <div class="body">
    <ng-container *ngIf="notificationsLength === 0" i18n>
      No new notifications
    </ng-container>
    <ng-container *ngIf="showAuraNotification()">
      <div i18n>You need to update your profile with your AURA password.</div>
      <a routerLink="/profile" i18n>Go to my profile</a>
    </ng-container>
  </div>
</div>
