import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { WebServicesService } from '../web-services.service';
import { Option } from '../../utils/types';
import { ModalService } from '../modal.service';
import { AURA_PASSWORD_NOTIFICATION } from '../../utils/constants';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  auraForm = new FormGroup({
    user: new FormControl({
      value: this.agent.email,
      disabled: true,
    }),
    password: new FormControl(''),
    passwordConfirm: new FormControl(''),
  });
  auraPasswordLoading = false;
  auraPasswordUpdated = false;
  stores: Option[] = [];
  storeId = this.auth.currentStore.id;
  hasChanges = false;
  profileUpdating = false;
  profileUpdated = false;
  isAuraPasswordInvalid = false;

  constructor(
    private auth: AuthService,
    private webServices: WebServicesService,
    private modals: ModalService,
  ) {}

  ngOnInit() {
    this.fetchStores();
    const passwordControl = this.auraForm.get('password');
    const passwordConfirmControl = this.auraForm.get('passwordConfirm');

    passwordControl.setValidators(this.invalidAuraPasswordValidator.bind(this));
    passwordConfirmControl.setValidators([
      this.invalidAuraPasswordValidator.bind(this),
      this.passwordConfirmValidator.bind(this),
    ]);

    passwordControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.isAuraPasswordInvalid = false;
      });
    passwordConfirmControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.isAuraPasswordInvalid = false;
      });
  }

  get agent() {
    return this.auth.currentAgent;
  }

  async fetchStores() {
    const stores = await this.webServices.getStores();
    this.stores = stores.map(store => ({
      value: store.id,
      label: store.name,
    }));
  }

  onStoreChange(storeId) {
    this.storeId = storeId;
    this.hasChanges = true;
  }

  async saveProfile() {
    this.profileUpdating = true;
    try {
      await this.webServices.updateAgentStore(this.storeId);
      await this.auth.fetchAgentInfo();
      this.hasChanges = false;
      this.profileUpdated = true;
      setTimeout(() => {
        this.profileUpdated = false;
      }, 2000);
    } catch (e) {
      this.modals.error();
      throw e;
    } finally {
      this.profileUpdating = false;
    }
  }

  isAuraButtonDisabled() {
    const password = this.auraForm.get('password').value;
    const passwordConfirm = this.auraForm.get('passwordConfirm').value;

    return !password || password !== passwordConfirm || this.auraPasswordLoading;
  }

  async updateAuraPassword() {
    const password = this.auraForm.get('password').value;

    this.auraPasswordLoading = true;
    try {
      await this.webServices.updateAuraPassword(password);
      const valid = await this.auth.validateAuraCredentials();

      if (valid) {
        this.auraForm.patchValue({
          password: '',
          passwordConfirm: '',
        });
        this.auraPasswordUpdated = true;
        setTimeout(() => {
          this.auraPasswordUpdated = false;
        }, 2000);
      } else {
        this.isAuraPasswordInvalid = true;
        this.auraForm.get('password').updateValueAndValidity();
        this.auraForm.get('passwordConfirm').updateValueAndValidity();
      }
    } catch (e) {
      this.modals.error();
      throw e;
    } finally {
      this.auraPasswordLoading = false;
    }
  }

  invalidAuraPasswordValidator(control): ValidationErrors {
    if (this.isAuraPasswordInvalid) {
      return { invalidAuraPassword: control.value };
    }
    return null;
  }

  passwordConfirmValidator(control: FormControl): ValidationErrors {
    const password = this.auraForm.get('password').value;
    if (password !== control.value) {
      return { passwordsDontMatch: control.value };
    }

    return null;
  }

  showAuraNotification() {
    return this.auth.notifications.includes(AURA_PASSWORD_NOTIFICATION);
  }
}
