import { Route } from '@angular/router';
import { EventEmitter } from '@angular/core';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface OptionObject {
  label: string;
  value: string | number;
}

export type Option = OptionObject | string | number;

export interface AppRoute extends Route {
  icon?: string;
  label?: string;
  showInSidebar?: boolean;
  children?: AppRoute[];
}

export interface AutoInsuranceRate {
  id: string;
  carrier: {
    id: string;
    name: string;
    logoUrl: string;
  };
  hasAbortInstructions: boolean;
  plan: string;
  program: string;
  term: number;
  total: number;
  annualTotal: number;
  numberOfPayments: number;
  downPayment: number;
  totalPolicyPremium: number;
  brokerFee?: number;
  antiFraudFee?: number;
  monthlyPayment: number;
  paymentAmount: number;
  otherFees?: {
    [key: string]: number;
  };
  relatedDrivers?: string[];
  underwritingQuestions?: string[];
  incidents?: AutoInsuranceIncident[];
}

export interface AutoInsuranceIncident {
  driverName: string;
  type: string;
  date: Date;
}

export interface Agent {
  id: string;
  sid: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  storeId: string;
}

export interface AgentInQuote {
    lendify_account?: any;
     email?: string;
    store_id?: number;
    agent_uid?: string;
    last_name?: string;
    first_name?: string;
    image?: string;
}

export interface AuthResult {
  accessToken: string;
  idToken: string;
  expiresIn: number;
  idTokenPayload: {
    name: string;
    email: string;
    picture: string;
  };
}

export interface Store {
  id: string;
  name: string;
  address: string;
}

export interface Location {
  city: string;
  state: string;
  county: string;
}

export interface AutoSearch {
  results: Array<AutoInsurance>;
  metadata: {
    total: number;
    next: string;
    query: string;
  };
  error: string | undefined;
}

export interface AutoLatest {
  results: Array<{ contact_name: string, id: string }>;
  error: string | undefined;
}

export interface AutoInsurance {
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone: string;
  address1: string;
  updated_at: string;
}

export interface MonthlyProgressValue {
  current: number;
  monthlyGoal?: number;
  dayGoal?: number;
  incentivesGoal?: number;
}

export interface UserStatistic {
  autoInsurance: {
    reactivations: MonthlyProgressValue,
    new: MonthlyProgressValue,
    total: MonthlyProgressValue,
    quotes: number,
    successRate: number
  };
  lifeInsurance: {
    quotes: number,
    completeApplications: number,
    incompleteApplications: number
  };
  loans: {
    total: MonthlyProgressValue,
    applications: number,
    successRate: number
  };
}

export interface UserLeaderBoard {
  agent: Agent;
  progress: number;
}

export interface UserData {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  preferred_time_to_call?: string;
  company_name?: string;
  company_price?: number;
  receive_documents_authorized?: string;
  contact_information_authorized?: string;
  call_date?: string;
  sid?: string;
  cid?: string;
  vehicles?: AutoInsuranceVehicle[];
  drivers?: AutoInsuranceDriver[];
  referrer?: string;
  url_params?: any;
  policyId?: string;
  driversConfigVisited?: boolean;
  quotesConfigVisited?: boolean;
  createdAt?: number;
}

export interface AutoInsuranceDriver {
  firstName?: string;
  lastName?: string;
  birthdate?: Date;
  age?: number;
  sex?: string;
  marital?: string;
  otherMarital?: string;
  industry?: string;
  occupation?: string;
  hasTraining?: boolean;
  priorInsurance?: boolean;
  priorInsuranceYears?: number;
  priorInsuranceMonths?: number;
  priorInsuranceExpDate?: Date;
  dmvCertification?: string;
  dmvCertificationState?: string;
  dmvCertificationReason?: string;
  licenseType?: string;
  licenseStatus?: string;
  licenseNumber?: number;
  licenseState?: number;
  licenseYears?: number;
  licenseMonths?: number;
  totalLicenseYears?: number;
  totalLicenseMonths?: number;
  excluded?: boolean;
  relatedDriver?: string;
  race?: string;
  primaryDriverRelationship?: string;
  hasMatureDriverCourse?: boolean;
  matureDriverCourseDate?: Date;
  isGoodStudent?: boolean;
}

export interface AutoInsuranceRelatedDriver {
  name?: string;
  relationshipType?: string;
}

export interface AutoInsuranceVehicle {
  vin?: string;
  year?: string;
  maker?: string;
  model?: string;
  purchaseDate?: Date;
  annualMiles?: number;
  workMiles?: number;
  usage?: string;
  homingDevice?: boolean;
  salvaged?: boolean;
  rideShare?: string;
  primaryOperator?: number;
  coverageType?: string;
  compDed?: number | null;
  collDed?: number | null;
  towingDed?: boolean | null;
  rentalDed?: number | null;
  excluded?: boolean;
  antiTheftDevice?: string;
  odometer?: string;
  hasLossPayee?: boolean;
  lossPayeeType?: 'leased' | 'funded';
  institutionName?: string;
  institutionAddress?: string;
  institutionZipCode?: string;
  institutionState?: string;
  institutionCity?: string;
}

export interface AutoInsuranceCoverages {
  liabPDLimit?: number | null;
  uninsPD?: number | null;
  accDeathLimit?: number | null;
  medPayLimit?: number | null;
  liabBILimit0?: number | null;
  liabBILimit1?: number | null;
  uninsBILimit0?: number | null;
  uninsBILimit1?: number | null;
}

export interface AutoInsuranceQuoteApplication {
  id?: string;
  contact: {
    firstName?: string,
    lastName?: string,
    phoneNumber?: string;
    mobileNumber?: string;
    email?: string;
    address?: string;
    zipCode?: string;
    state?: string;
    county?: string;
    city?: string;
    source?: string;
    hasMailingAddress?: boolean;
    mailingAddress?: string;
    mailingCity?: string;
    mailingState?: string;
    mailingZipCode?: string;
  };
  drivers: AutoInsuranceDriver[];
  vehicles: AutoInsuranceVehicle[];
  coverages: AutoInsuranceCoverages;
  nonOwner?: boolean;
  effectiveDate?: Date;
  carrierId?: string;
  rateId?: string;
  rateHasAbortInstructions?: boolean;
  relatedDrivers?: AutoInsuranceRelatedDriver[];
  createdAt?: string;
  createdBy?: AgentInQuote;
  lastModifiedBy?: AgentInQuote;
  underwritingQuestions: {
    isAddressPrincipal: any;
    areAllDriversReported: any;
    hasCommittedWorkmensFraud: any;
    hasRentedVehicle: any;
    hasRideShareVehicle: any;
    rideShareVehicleReason: any;
    hasServicesVehicle: any;
    hasDifferentAddressVehicle: any;
    hasAnotherVehicles: any;
    anotherVehiclesReason: any;
    hasPriorInsurance: any;
    priorInsuranceCompany: any;
    isPrimaryResidenceOwned: any;
    priorInsuranceBILimit: any;
    priorInsuranceExpDate: any;
    qualifiesForAffinityProgram: any;
    wantsDocumentsViaEmail: any;
    hasAnotherNonVehiclePolicy: any;
    areAllDriversOlderThan15Reported: any;
    allDriversOlderThan15ReportedReason: any;
    hasNonIncludedDriver: any;
    nonIncludedDriverReason: any;
    hasVehicleInDifferentAddress: any;
    vehicleInDifferentAddressReason: any;
    hasVehicleInDifferentRegister: any;
    vehicleInDifferentRegisterReason: any;
    hasModifiedVehicle: any;
    hasDamagedVehicle: any;
    damagedVehicleReason: any;
    hasWorkVehicle: any;
    workVehicleReason: any;
    areQuestionsConfirmed: any;
    areAllVehiclesInSameAddress: any;
    agreesToIntLicenseConfirm: any;
    hasProhibitedRisks: any;
    areAllFrecuentDriversReported: any;
    areAllVehicleRegisterDriversReported: any;
    hasPreviouslyRentedVehicle: any;
    hasExtendedServicesVehicle: any;
    hasCommercialVehicle: any;
    hasExtremeSportsVehicle: any;
    hasHeavyLiftingVehicle: any;
    hasSalvagedVehicle: any;
    hasCalifornianData: any;
    ownsAllVehicles: any;
    ownsAllVehiclesReason: any;
    hasCommittedFraud: any;
    hasAnotherPolicy: any;
    anotherPolicyNumber: any;
    hasUnpaidOrDeclinedPolicy: any;
  };
}

export interface FormErrorMessage {
  name: string;
  fields: string[];
  click: EventEmitter<any>;
}

export interface LoadQuoteApplicationResponse {
  message?: string;
  policy?: AutoInsuranceQuoteApplication;
  agents?: AgentInQuote[];
}

export interface FormControlError {
  type: string;
  value?: any;
}

export enum AutoRaterSteps {
  contact = 'CONTACT',
  drivers = 'DRIVERS',
  vehicles = 'VEHICLES',
  coverages = 'COVERAGES',
  carrier = 'CARRIER',
}

export enum AutoRaterCarrierSteps {
  drivers = 'DRIVERS',
  questions = 'QUESTIONS',
  confirm = 'CONFIRM',
  payment = 'PAYMENT',
}

export enum AutoRaterRelatedDriver {
  unknown = 'unknown',
  duplicate = 'duplicated',
  excluded = 'excluded',
  driver = 'driver',
  nonDriver = 'no-driver',
  deceased = 'deceased'
}

export class SegmentAnalytics extends Array {
  SNIPPET_VERSION = '4.1.0';
  methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  constructor() {
    super();
    this.methods.forEach(method => {
      this[method] = this.factory(method);
    });
  }

  factory = (method) => (...args) => {
    this.push([method, ...args]);
    return this;
  };

  user = () => ({
    anonymousId: () => '',
  });

  // @ts-ignore
  trackSubmit(...args: any[]);

  // @ts-ignore
  trackClick(...args: any[]);

  // @ts-ignore
  trackLink(...args: any[]);

  // @ts-ignore
  trackForm(...args: any[]);

  // @ts-ignore
  pageview(...args: any[]);

  // @ts-ignore
  identify(...args: any[]);

  // @ts-ignore
  reset(...args: any[]);

  // @ts-ignore
  group(...args: any[]);

  // @ts-ignore
  track(...args: any[]);

  // @ts-ignore
  ready(...args: any[]);

  // @ts-ignore
  alias(...args: any[]);

  // @ts-ignore
  debug(...args: any[]);

  // @ts-ignore
  page(...args: any[]);

  // @ts-ignore
  once(...args: any[]);

  // @ts-ignore
  off(...args: any[]);

  // @ts-ignore
  on(...args: any[]);
}
