<p class="required-label">
    <ng-container i18n>Required fields</ng-container>
    <img src="../../../assets/images/required.svg">
  </p>
  <listo-card
    [header]="STRINGS.RELATED_DRIVERS"
    [collapsible]="false"
    [collapsed]="false"
    class="card"
  >
  <!--TODO i18n-->
    <div body>
      <div>Es necesario incuir a todos los posibles conductores relacionados al domicilio.</div>
      <div>Se han identificado los siguientes conductores relacionados:</div>
      <div class="grid" *ngFor="let driver of relatedDrivers; let i = index">
        <div class="name full-width">
          {{driver.name}}
        </div>
        <listo-select
          class="tab-select"
          placeholder="Seleccione la opción adecuada"
          [options]="STRINGS.RELATED_DRIVERS_OPTIONS"
          [value]="driver.type"
          (change)="changeRelatedDriverType($event, i)"
        >
        </listo-select>
      </div>
    </div>
  </listo-card>
