<listo-input [label]="STRINGS.firstName" [control]="form.firstName"></listo-input>
<listo-input [label]="STRINGS.lastName" [control]="form.lastName"></listo-input>
<div class="two-column">
  <listo-input [label]="STRINGS.phoneNumber" [control]="form.phoneNumber" [mask]="phoneMask" placeholder="(223) 123-1234"></listo-input>
  <listo-input
    [label]="STRINGS.email"
    [control]="form.email"
    [placeholder]="PLACEHOLDERS.email"
  ></listo-input>
</div>
<listo-input
  [label]="STRINGS.address"
  [control]="form.address"
  listoAddressAutoComplete
  (addressChanged)="onAddressChange($event)"
  [placeholder]="PLACEHOLDERS.address"
></listo-input>
<div class="four-column">
  <listo-input
    [label]="STRINGS.zipCode"
    [control]="form.zipCode"
    [placeholder]="PLACEHOLDERS.zipCode"
  ></listo-input>
  <listo-dropdown
    [label]="STRINGS.state"
    [control]="form.state"
    [options]="states"
    [placeholder]="PLACEHOLDERS.state"
  ></listo-dropdown>
  <listo-input
    [label]="STRINGS.city"
    class="city"
    [control]="form.city"
    [placeholder]="PLACEHOLDERS.city"
  ></listo-input>
</div>
<listo-select
  [label]="STRINGS.source"
  [control]="form.source"
  [options]="sources"
  [placeholder]="PLACEHOLDERS.option"
></listo-select>
