<label [class.error]="!!getError()" [class.warning]="getWarning()" [class.disabled]="disabled">
  <span *ngIf="label && (focus || getError() || getWarning())" class="icon-focus"></span>
  {{label}}
  <img *ngIf="isRequired()" class="required" src="../../../assets/images/required.svg">
  <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip"></listo-tooltip>
  <listo-tooltip *ngIf="hasTemplateTooltip()">
    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </listo-tooltip>
  <input
    [formControl]="control"
    (focus)="focus = true"
    (blur)="focus = false"
    [autofocus]="autofocus"
    [type]="type"
    [attr.disabled]="isDisabled()"
    [placeholder]="placeholder"
    [textMask]="{ mask: mask, guide: false }"
  >
  <listo-spinner *ngIf="isPending()" class="loading" [size]="20"></listo-spinner>
  <listo-error-label [error]="getError()"></listo-error-label>
  <listo-error-label *ngIf="getWarning() && !getError()" [error]="getWarning()" class="warning-label"></listo-error-label>
</label>
