import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoRaterComponent } from './auto-rater.component';
import { DriverFormComponent } from './driver-form/driver-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListoCommonModule } from '../listo-common/listo-common.module';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { CoveragesFormComponent } from './coverages-form/coverages-form.component';
import { DriverSmallFormComponent } from './driver-small-form/driver-small-form.component';
import { VehicleSmallFormComponent } from './vehicle-small-form/vehicle-small-form.component';
import { RatesTableComponent } from './rates-table/rates-table.component';
import { RatesTableRowComponent } from './rates-table-row/rates-table-row.component';
import { RatesTableRowSmallComponent } from './rates-table-row-small/rates-table-row-small.component';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { CoveragesModalComponent } from './coverages-modal/coverages-modal.component';
import { CarrierConfigComponent } from './carrier-config/carrier-config.component';
import { CarrierPaymentComponent } from './carrier-payment/carrier-payment.component';
import { CarrierConfirmComponent } from './carrier-confirm/carrier-confirm.component';
import { RateChangeModalComponent } from './rate-change-modal/rate-change-modal.component';
import { CarrierQuestionsComponent } from './carrier-questions/carrier-questions.component';
import { CarrierDriversComponent } from './carrier-drivers/carrier-drivers.component';
import { ErrorRateMessagesComponent } from './error-rate-messages/error-rate-messages.component';
import { AbortModalComponent } from './abort-modal/abort-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ListoCommonModule,
  ],
  declarations: [
    AutoRaterComponent,
    DriverFormComponent,
    ContactFormComponent,
    VehicleFormComponent,
    CoveragesFormComponent,
    DriverSmallFormComponent,
    VehicleSmallFormComponent,
    RatesTableComponent,
    RatesTableRowComponent,
    RatesTableRowSmallComponent,
    ErrorMessagesComponent,
    CoveragesModalComponent,
    CarrierConfigComponent,
    CarrierPaymentComponent,
    CarrierConfirmComponent,
    RateChangeModalComponent,
    CarrierQuestionsComponent,
    CarrierDriversComponent,
    ErrorRateMessagesComponent,
    AbortModalComponent,
  ],
  entryComponents: [
    AbortModalComponent,
  ],
  exports: [
    AutoRaterComponent,
  ],
})
export class AutoRaterModule {}
