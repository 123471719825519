import { NgModule } from '@angular/core';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TabsComponent } from './tabs/tabs.component';
import { RadiosComponent } from './radios/radios.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { DateSelectComponent } from './date-select/date-select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CardComponent } from './card/card.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { GoalBubbleComponent } from './goal-bubble/goal-bubble.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ErrorLabelComponent } from './error-label/error-label.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { NotFoundModalComponent } from './not-found-modal/not-found-modal.component';
import { AddressAutoCompleteDirective } from './address-auto-complete.directive';

@NgModule({
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    TextMaskModule,
  ],
  declarations: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DropdownComponent,
    TabsComponent,
    RadiosComponent,
    CheckboxComponent,
    ModalComponent,
    DateSelectComponent,
    SpinnerComponent,
    CardComponent,
    ProgressBarComponent,
    StatusLabelComponent,
    GoalBubbleComponent,
    DatePickerComponent,
    ErrorLabelComponent,
    TooltipComponent,
    ConfirmModalComponent,
    SvgIconComponent,
    ErrorModalComponent,
    NotFoundModalComponent,
    AddressAutoCompleteDirective,
  ],
  exports: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DropdownComponent,
    TabsComponent,
    RadiosComponent,
    CheckboxComponent,
    ModalComponent,
    DateSelectComponent,
    SpinnerComponent,
    CardComponent,
    ProgressBarComponent,
    StatusLabelComponent,
    GoalBubbleComponent,
    DatePickerComponent,
    ErrorLabelComponent,
    TooltipComponent,
    ConfirmModalComponent,
    SvgIconComponent,
    AddressAutoCompleteDirective,
  ],
})
export class ListoCommonModule {}
