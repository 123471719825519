<button class="back-button" routerLink="tools">< Regresar a herramientas</button>
<h1 class="header">Mis Links</h1>
<listo-card header="Links para compartir" class="card">
  <div class="body">
    <div *ngFor="let link of links; index as i" class="link">
      <div class="label">{{link.label}}</div>
      <div class="url">{{link.url}}</div>
      <div>
        <listo-button (click)="copyLink(i)">Copiar</listo-button>
        <div class="copied-label" [class.hidden]="copiedLink !== i">Link copiado</div>
      </div>
    </div>
  </div>
</listo-card>

