import { Component } from '@angular/core';
import { AGENT_LINKS } from 'src/utils/constants';
import { AuthService } from '../auth.service';
import { Subscription, timer } from 'rxjs';
import { copyToClipboard } from '../../utils/utils';

@Component({
  selector: 'app-agent-links',
  templateUrl: './agent-links.component.html',
  styleUrls: ['./agent-links.component.scss'],
})
export class AgentLinksComponent {
  links = AGENT_LINKS.map(link => ({
    ...link,
    url: `${link.url}?sid=${this.auth.currentAgent.sid}`,
  }));
  copiedLink = null;
  copiedTimer = new Subscription();

  constructor(private auth: AuthService) {}

  copyLink(i) {
    this.copiedTimer.unsubscribe();
    copyToClipboard(this.links[i].url);
    this.copiedLink = i;
    this.copiedTimer = timer(2000).subscribe(() => {
      this.copiedLink = null;
    });
  }
}
