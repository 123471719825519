<td colspan="2">
  <table>
    <tr>
      <td (click)="open = !open" valign="center">
        <img class="icon" src="../../../assets/images/dropdown-closed.svg" [class.open]="open">
        {{rate.carrier.name}}
      </td>
      <td>${{rate.annualTotal | currency:'':'':'1.0-0':'en-US'}}</td>
    </tr>
    <ng-container *ngIf="open">
      <tr>
        <td>Plan</td>
        <td>{{rate.plan}}</td>
      </tr>
      <tr>
        <td i18n>Term</td>
        <td>{{rate.term}} <span i18n>months</span></td>
      </tr>
      <tr>
        <td i18n>Down payment</td>
        <td>${{rate.downPayment | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
      <tr>
        <td i18n>Quote</td>
        <td>${{rate.paymentAmount | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
      <tr>
        <td i18n>#Quotes</td>
        <td>{{rate.numberOfPayments}}</td>
      </tr>
      <tr>
        <td>Total</td>
        <td>${{rate.total | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
      <tr>
        <td i18n>Total annual</td>
        <td>${{rate.annualTotal | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
      <tr>
        <td colspan="2">
          <listo-button [invertColor]="true" (click)="bridge.emit(rate.carrier.id)" i18n>Continue</listo-button>
        </td>
      </tr>
    </ng-container>
  </table>
</td>
