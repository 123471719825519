import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  ANTI_THEFT_DEVICES,
  AUTO_INSURANCE_PLACEHOLDERS,
  AUTO_INSURANCE_STRINGS,
  AUTO_INSURANCE_TOOLTIPS,
  RIDE_SHARE_OPTIONS,
  VEHICLE_USAGES,
  YESNO,
} from '../../../utils/constants';
import { WebServicesService } from '../../web-services.service';
import { sortOptionsAlph } from '../../../utils/utils';
import { VehicleForm } from '../forms';
import { AutoRaterDataService } from '../auto-rater-data.service';


@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit {
  @Input() form: VehicleForm;
  @Input() drivers: FormArray;
  @Input() canDelete = false;
  @Output() delete = new EventEmitter();
  usages = VEHICLE_USAGES.sort(sortOptionsAlph);
  years: string[] = [];
  makers: string[] = [];
  models: string[] = [];
  yearsLoading = false;
  makersLoading = false;
  modelsLoading = false;
  yesno = YESNO;
  searchVinDialogOpen = false;
  searchVinLoading = false;
  availableVins = [];
  rideShareOptions = RIDE_SHARE_OPTIONS;
  antiTheftDevices = ANTI_THEFT_DEVICES.sort(sortOptionsAlph);
  STRINGS = AUTO_INSURANCE_STRINGS;
  PLACEHOLDERS = AUTO_INSURANCE_PLACEHOLDERS;
  TOOLTIPS = AUTO_INSURANCE_TOOLTIPS;

  constructor(
    private webServices: WebServicesService,
    public dataService: AutoRaterDataService,
  ) { }

  ngOnInit() {
    this.subscribeToChanges();
    this.getYears();
    this.getMakers();
    this.getModels();
  }

  subscribeToChanges() {
    this.form.year.valueChanges.subscribe(
      () => this.getMakers(),
    );

    this.form.maker.valueChanges.subscribe(
      () => this.getModels(),
    );

    this.form.model.valueChanges.subscribe(
      (model) => {
        if (model) {
          this.getVins();
        }
      },
    );
  }

  async getVins() {
    const year = this.form.year.value;
    const maker = this.form.maker.value;
    const model = this.form.model.value;

    this.searchVinLoading = true;
    this.availableVins = await this.webServices.getVins(
      year, maker, model,
    );
    this.searchVinLoading = false;
  }

  async getYears() {
    this.yearsLoading = true;
    const { years } = await this.webServices.getVehicleYears();
    this.yearsLoading = false;
    this.years = years;
  }

  async getMakers() {
    const year = this.form.year.value;
    if (year) {
      this.makersLoading = true;
      const { makers } = await this.webServices.getVehicleMakers(year);
      this.makersLoading = false;
      this.makers = makers;
    }
  }

  async getModels() {
    const year = this.form.year.value;
    const maker = this.form.maker.value;
    if (year && maker) {
      this.modelsLoading = true;
      const { models } = await this.webServices.getVehicleModels(year, maker);
      this.modelsLoading = false;
      this.models = models;
    }
  }

  getDrivers() {
    return this.dataService.drivers.controls.map((control, i) => ({
      value: i,
      label: `${control.firstName.value} ${control.lastName.value}`,
    }));
  }

  openSearchVinDialog() {
    this.searchVinDialogOpen = true;
  }

  closeSearchVinDialogOpen() {
    this.searchVinDialogOpen = false;
  }

  selectVin(vin) {
    this.form.vin.setValue(vin);
    this.closeSearchVinDialogOpen();
  }
}
