<div class="container" [class.disabled]="disabled" (click)="!disabled && onChange(!getValue())">
  <img src="../../assets/images/checkbox{{getValue() ? '-selected' : ''}}{{disabled ? '-disabled' : ''}}.svg">
  <span>
    {{label}}
    <listo-tooltip *ngIf="hasStringTooltip()" [description]="tooltip"></listo-tooltip>
    <listo-tooltip *ngIf="hasTemplateTooltip()">
      <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
    </listo-tooltip>
  </span>
</div>
<listo-error-label [error]="getError()"></listo-error-label>
