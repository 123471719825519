import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AUTO_INSURANCE_STRINGS,
  CONTACT_STRING,
  DRIVER_STRING,
  EXCLUDED_STRING,
  VEHICLE_STRING,
} from '../../../utils/constants';
import { FormErrorMessage } from '../../../utils/types';
import { AutoRaterDataService } from '../auto-rater-data.service';
import { getKeysWithWarnings } from '../../../utils/utils';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.scss'],
})
export class ErrorMessagesComponent {
  @Input() formErrors: FormErrorMessage[];
  @Output() contactClick = new EventEmitter<void>();
  @Output() driverClick = new EventEmitter<number>();
  @Output() vehicleClick = new EventEmitter<number>();
  STRINGS = AUTO_INSURANCE_STRINGS;
  CONTACT_STRING = CONTACT_STRING;
  DRIVER_STRING = DRIVER_STRING;
  VEHICLE_STRING = VEHICLE_STRING;
  EXCLUDED_STRING = EXCLUDED_STRING;

  get drivers() {
    return this.dataService.drivers.controls.filter(
      control => !control.excluded.value,
    );
  }

  get excludedDrivers() {
    return this.dataService.drivers.controls.filter(
      control => control.excluded.value,
    );
  }

  get vehicles() {
    return this.dataService.vehicles.controls;
  }

  constructor(private dataService: AutoRaterDataService) {}

  getContactKeysWithWarnings() {
    return getKeysWithWarnings(this.dataService.contact);
  }

  getDriverKeysWithWarnings(driver) {
    return getKeysWithWarnings(driver);
  }

  getVehicleKeysWithWarnings(vehicle) {
    return getKeysWithWarnings(vehicle);
  }

  getDriverIndex(driverControl) {
    return this.dataService.drivers.controls.findIndex(
      control => control === driverControl,
    );
  }
}
