<h1 class="header" i18n>My profile</h1>

<div *ngIf="showAuraNotification()" class="notification-card">
  <h2 class="header">
    <listo-svg-icon class="icon" icon="notification"></listo-svg-icon>
    <ng-container i18n>Your AURA password has changed</ng-container>
  </h2>
  <div class="body">
    <ng-container i18n>Update your profile with your current AURA password.</ng-container>
  </div>
</div>

<listo-card
  header="Personal details"
  i18n-header
  class="card"
>
  <div class="personal-data-container">
    <img class="image" [src]="agent.imageUrl">
    <div>
      <div class="name">{{agent.firstName}} {{agent.lastName}}</div>
      <div class="email">{{agent.email}}</div>
      <listo-select
        [options]="stores"
        [value]="storeId"
        (change)="onStoreChange($event)"
        class="store"
        label="Assigned store"
        i18n-label
      ></listo-select>
    </div>
    <div class="changes-button">
      <listo-button
        [disabled]="!hasChanges || profileUpdating"
        [invertColor]="true"
        (click)="saveProfile()"
        i18n
      >
        Save changes
      </listo-button>
      <div class="saved-label" [class.hidden]="!profileUpdated" i18n>
        Changes saved
      </div>
    </div>
  </div>
</listo-card>

<listo-card header="Aura credentials" i18n-header class="card">
  <div class="aura-credentials-container">
    <listo-input
      label="User"
      i18n-label
      [control]="auraForm.get('user')"
    ></listo-input>
    <listo-input
      label="Current AURA password"
      i18n-label
      placeholder="Current password you use to enter AURA"
      i18n-placeholder
      type="password"
      [control]="auraForm.get('password')"
    ></listo-input>
    <listo-input
      label="Confirm password"
      i18n-label
      placeholder="Confirm the password you use to enter AURA"
      i18n-placeholder
      type="password"
      [control]="auraForm.get('passwordConfirm')"
    ></listo-input>
    <listo-button (click)="updateAuraPassword()" [disabled]="isAuraButtonDisabled()">
      <ng-container *ngIf="!auraPasswordLoading" i18n>Save password</ng-container>
      <ng-container *ngIf="auraPasswordLoading" i18n>Saving...</ng-container>
    </listo-button>
    <div class="saved-label" [class.hidden]="!auraPasswordUpdated">Password saved</div>

    <p>
      <ng-container i18n>Note. If you need to change your AURA password you can communicate to</ng-container>
      &nbsp;
      <a>(866) 521-9471</a>
      &nbsp;
      <ng-container i18n>or send an email to</ng-container>
      &nbsp;
      <a>support@lendifyfin.com</a>
      &nbsp;
      <ng-container i18n>and updating it in your profile.</ng-container>
    </p>
  </div>
</listo-card>
