import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { DataStorageService } from './data-storage.service';
import { UserData, SegmentAnalytics } from '../utils/types';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SEGMENT_API_KEY } from '../utils/constants';
import { getSegmentPage } from '../utils/utils';
import { AuthService } from './auth.service';

declare const analytics: SegmentAnalytics;

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  constructor(
    private router: Router,
    private storage: DataStorageService,
    private auth: AuthService,
  ) {
    if (typeof analytics === 'undefined') {
      // @ts-ignore
      window.analytics = new SegmentAnalytics();
    }
    this.router.events.subscribe(this.onRouteChange);
    this.storage.changes.subscribe(SegmentService.onStorageChange);

    if (environment.production) {
      SegmentService.loadScript();
    }
  }

  static loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${SEGMENT_API_KEY}/analytics.min.js`;

    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
  }

  static onStorageChange(userData: UserData) {
    if (!userData) {
      return;
    }

    const { first_name, last_name, phone, email, drivers } = userData;

    if (!first_name && !last_name && !phone && !email && !drivers) {
      return;
    }

    const data: any = {};

    if (first_name) {
      data.first_name = first_name;
    }
    if (last_name) {
      data.last_name = last_name;
    }
    if (email) {
      data.email = email;
    }
    if (phone) {
      data.phone = phone;
    }
    if (drivers && drivers[0]) {
      data.age = drivers[0].age;
      data.gender = drivers[0].sex;
    }
    analytics.identify(data);
  }

  static getId() {
    return typeof analytics !== 'undefined' && analytics.user().anonymousId();
  }

  onRouteChange = (event: RouterEvent) => {
    if (event instanceof NavigationEnd) {
      this.page(`${getSegmentPage()} PageLoad`);
    }
  }

  page(eventName?: string, properties?: any) {
    const isAgent = this.auth.isAuthenticated;
    analytics.page(eventName, {
      ...properties,
      ...this.storage.getData(),
      isLoggedInUser: isAgent,
      isAgent,
    });
  }

  track(eventName: string, properties?: any) {
    const isAgent = this.auth.isAuthenticated;
    analytics.track(eventName, {
      ...properties,
      ...this.storage.getData(),
      isLoggedInUser: isAgent,
      isAgent,
    });
  }
}
