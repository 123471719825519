<p class="required-label">
  <ng-container i18n>Required fields</ng-container>
  <img src="../../../assets/images/required.svg">
</p>
<listo-card
  [header]="STRINGS.CONTACT_STRING"
  [collapsible]="true"
  [collapsed]="false"
  class="card"
>
  <div class="grid" body>
    <div class="name full-width">
      <listo-svg-icon icon="profile" class="icon"></listo-svg-icon>
      {{dataService.contact.firstName.value}}
      {{dataService.contact.lastName.value}}
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.address}}</div>
      <div>{{dataService.contact.address.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.zipCode}}</div>
      <div>{{dataService.contact.zipCode.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.state}}</div>
      <div>{{dataService.contact.state.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.city}}</div>
      <div>{{dataService.contact.city.value}}</div>
    </div>
    <listo-checkbox
      [control]="dataService.contact.hasMailingAddress"
      [label]="STRINGS.AUTO_INSURANCE_STRINGS.hasMailingAddress"
      class="full-width"
    ></listo-checkbox>
    <ng-container *ngIf="dataService.contact.hasMailingAddress.value">
      <listo-input
        [control]="dataService.contact.mailingAddress"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.mailingAddress"
        class="full-width"
      ></listo-input>
      <listo-input
        [control]="dataService.contact.mailingZipCode"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.mailingZipCode"
      ></listo-input>
      <listo-select
        [control]="dataService.contact.mailingState"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.mailingState"
        [options]="STRINGS.STATES"
      ></listo-select>
      <listo-input
        [control]="dataService.contact.mailingCity"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.mailingCity"
        class="double-span"
      ></listo-input>
    </ng-container>
  </div>
</listo-card>

<listo-card
  [header]="STRINGS.DRIVERS_STRING"
  [collapsible]="true"
  [collapsed]="false"
  class="card"
>
  <div class="grid" body *ngFor="let driver of drivers">
    <div class="name full-width">
      <listo-svg-icon icon="profile" class="icon"></listo-svg-icon>
      {{driver.firstName.value}}
      {{driver.lastName.value}}
      <span class="excluded-label" *ngIf="driver.excluded.value">
          ({{STRINGS.EXCLUDED_STRING}})
        </span>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.age}}</div>
      <div>{{driver.age.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.marital}}</div>
      <div>{{getMaritalString(driver.marital.value)}}</div>
    </div>
    <div class="double-span">
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.primaryDriverRelationship}}</div>
      <div>{{getRelationshipString(driver.primaryDriverRelationship.value)}}</div>
    </div>
    <ng-container *ngIf="!driver.excluded.value">
      <div>
        <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.licenseType}}</div>
        <div>{{getLicenseTypeString(driver.licenseType.value)}}</div>
      </div>
      <div>
        <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.licenseStatus}}</div>
        <div>{{getLicenseStatusesString(driver.licenseStatus.value)}}</div>
      </div>
      <div class="double-span">
        <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.dmvCertification}}</div>
        <div>{{getDMVString(driver.dmvCertification.value)}}</div>
      </div>
    </ng-container>
  </div>
</listo-card>

<listo-card
  [header]="STRINGS.VEHICLES_STRING"
  [collapsible]="true"
  [collapsed]="false"
  class="card"
>
  <div *ngFor="let vehicle of dataService.vehicles.controls" class="grid" body>
    <div class="name full-width">
      <listo-svg-icon icon="car" class="icon"></listo-svg-icon>
      {{vehicle.year.value}}
      {{vehicle.maker.value}}
      {{vehicle.model.value}}
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.usage}}</div>
      <div>{{getUsageString(vehicle.usage.value)}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.annualMiles}}</div>
      <div>{{vehicle.annualMiles.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.workMiles}}</div>
      <div>{{vehicle.workMiles.value}}</div>
    </div>
    <div>
      <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.salvaged}}</div>
      <div>{{vehicle.salvaged.value ? 'Si' : 'No'}}</div>
    </div>
    <listo-checkbox
      [control]="vehicle.hasLossPayee"
      [label]="STRINGS.AUTO_INSURANCE_STRINGS.hasLossPayee"
      class="full-width"
    ></listo-checkbox>
    <ng-container *ngIf="vehicle.hasLossPayee.value">
      <listo-radios
        [control]="vehicle.lossPayeeType"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.lossPayeeType"
        [options]="STRINGS.LOSS_PAYEE_TYPES"
        class="full-width"
      ></listo-radios>
      <listo-input
        [control]="vehicle.institutionName"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.institutionName"
        class="full-width"
      ></listo-input>
      <listo-input
        [control]="vehicle.institutionAddress"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.institutionAddress"
        class="full-width"
      ></listo-input>
      <listo-input
        [control]="vehicle.institutionZipCode"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.institutionZipCode"
      ></listo-input>
      <listo-select
        [control]="vehicle.institutionState"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.institutionState"
        [options]="STRINGS.STATES"
      ></listo-select>
      <listo-input
        [control]="vehicle.institutionCity"
        [label]="STRINGS.AUTO_INSURANCE_STRINGS.institutionCity"
        class="double-span"
      ></listo-input>
    </ng-container>
  </div>
</listo-card>

<listo-card
  [header]="STRINGS.COVERAGES_STRING"
  [collapsible]="true"
  [collapsed]="false"
  class="card"
>
  <div class="grid three-column" body>
    <div>
      <div class="label">
        {{STRINGS.AUTO_INSURANCE_STRINGS.liabBILimit}}
        <listo-tooltip>
          {{STRINGS.AUTO_INSURANCE_TOOLTIPS.liabBILimit}}
        </listo-tooltip>
      </div>
      <div>{{dataService.coverages.liabBILimit.value || STRINGS.NO_COVERAGE}}</div>
    </div>
    <div>
      <div class="label">
        {{STRINGS.AUTO_INSURANCE_STRINGS.liabPDLimit}}
        <listo-tooltip>
          {{STRINGS.AUTO_INSURANCE_TOOLTIPS.liabPDLimit}}
        </listo-tooltip>
      </div>
      <div>{{dataService.coverages.liabPDLimit.value || STRINGS.NO_COVERAGE}}</div>
    </div>
    <div>
      <div class="label">
        {{STRINGS.AUTO_INSURANCE_STRINGS.medPayLimit}}
        <listo-tooltip>
          {{STRINGS.AUTO_INSURANCE_TOOLTIPS.medPayLimit}}
        </listo-tooltip>
      </div>
      <div>{{dataService.coverages.medPayLimit.value || STRINGS.NO_COVERAGE}}</div>
    </div>
    <div>
      <div class="label">
        {{STRINGS.AUTO_INSURANCE_STRINGS.uninsBILimit}}
        <listo-tooltip>
          {{STRINGS.AUTO_INSURANCE_TOOLTIPS.uninsBILimit}}
        </listo-tooltip>
      </div>
      <div>{{dataService.coverages.uninsBILimit.value || STRINGS.NO_COVERAGE}}</div>
    </div>
    <div>
      <div class="label">
        {{STRINGS.AUTO_INSURANCE_STRINGS.uninsPD}}
        <listo-tooltip>
          {{STRINGS.AUTO_INSURANCE_TOOLTIPS.uninsPD}}
        </listo-tooltip>
      </div>
      <div>{{dataService.coverages.uninsPD.value ? 'Si' : 'No'}}</div>
    </div>
    <div *ngFor="let vehicle of dataService.vehicles.controls" class="grid full-width">
      <div class="name full-width">
        <listo-svg-icon icon="car" class="icon"></listo-svg-icon>
        {{vehicle.year.value}}
        {{vehicle.maker.value}}
        {{vehicle.model.value}}
      </div>
      <div>
        <div class="label">
          {{STRINGS.AUTO_INSURANCE_STRINGS.compDed}}
          <listo-tooltip>
            {{STRINGS.AUTO_INSURANCE_TOOLTIPS.compDed}}
          </listo-tooltip>
        </div>
        <div>{{vehicle.compDed.value || STRINGS.NO_COVERAGE}}</div>
      </div>
      <div>
        <div class="label">
          {{STRINGS.AUTO_INSURANCE_STRINGS.collDed}}
          <listo-tooltip>
            {{STRINGS.AUTO_INSURANCE_TOOLTIPS.collDed}}
          </listo-tooltip>
        </div>
        <div>{{vehicle.collDed.value || STRINGS.NO_COVERAGE}}</div>
      </div>
      <div>
        <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.rentalDed}}</div>
        <div>{{vehicle.rentalDed.value || STRINGS.NO_COVERAGE}}</div>
      </div>
      <div>
        <div class="label">{{STRINGS.AUTO_INSURANCE_STRINGS.towingDed}}</div>
        <div>{{vehicle.towingDed.value ? 'Si' : 'No'}}</div>
      </div>
    </div>
  </div>
</listo-card>
