import { FormGroup, Validators } from '@angular/forms';
import CustomFormControl from './CustomFormControl';
import CustomValidators from '../../utils/validators';

export class UnderwritingQuestionsForm extends FormGroup {
  constructor() {
    super({
      isAddressPrincipal: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      areAllDriversReported: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      hasCommittedWorkmensFraud: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasRentedVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasRideShareVehicle: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      rideShareVehicleReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasServicesVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasDifferentAddressVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasAnotherVehicles: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      anotherVehiclesReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasPriorInsurance: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      priorInsuranceCompany: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      isPrimaryResidenceOwned: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      priorInsuranceBILimit: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      priorInsuranceExpDate: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      qualifiesForAffinityProgram: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      wantsDocumentsViaEmail: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasAnotherNonVehiclePolicy: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      areAllDriversOlderThan15Reported: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      allDriversOlderThan15ReportedReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasNonIncludedDriver: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      nonIncludedDriverReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasVehicleInDifferentAddress: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      vehicleInDifferentAddressReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasVehicleInDifferentRegister: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      vehicleInDifferentRegisterReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasModifiedVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasDamagedVehicle: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      damagedVehicleReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasWorkVehicle: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      workVehicleReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      areQuestionsConfirmed: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      areAllVehiclesInSameAddress: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      // TODO options
      agreesToIntLicenseConfirm: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      hasProhibitedRisks: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      areAllFrecuentDriversReported: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      areAllVehicleRegisterDriversReported: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      hasPreviouslyRentedVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasExtendedServicesVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasCommercialVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasExtremeSportsVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasHeavyLiftingVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasSalvagedVehicle: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasCalifornianData: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.truthy,
        ],
      ),
      ownsAllVehicles: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      ownsAllVehiclesReason: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasCommittedFraud: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
      hasAnotherPolicy: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      anotherPolicyNumber: new CustomFormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      hasUnpaidOrDeclinedPolicy: new CustomFormControl(
        { value: null, disabled: true },
        [
          Validators.required,
          CustomValidators.falsy,
        ],
      ),
    });
    this.subscribeToChanges();
  }

  get isAddressPrincipal() {
    return this.get('isAddressPrincipal') as CustomFormControl;
  }

  get areAllDriversReported() {
    return this.get('areAllDriversReported') as CustomFormControl;
  }

  get hasCommittedWorkmensFraud() {
    return this.get('hasCommittedWorkmensFraud') as CustomFormControl;
  }

  get hasRentedVehicle() {
    return this.get('hasRentedVehicle') as CustomFormControl;
  }

  get hasRideShareVehicle() {
    return this.get('hasRideShareVehicle') as CustomFormControl;
  }

  get rideShareVehicleReason() {
    return this.get('rideShareVehicleReason') as CustomFormControl;
  }

  get hasServicesVehicle() {
    return this.get('hasServicesVehicle') as CustomFormControl;
  }

  get hasDifferentAddressVehicle() {
    return this.get('hasDifferentAddressVehicle') as CustomFormControl;
  }

  get hasAnotherVehicles() {
    return this.get('hasAnotherVehicles') as CustomFormControl;
  }

  get anotherVehiclesReason() {
    return this.get('anotherVehiclesReason') as CustomFormControl;
  }

  get hasPriorInsurance() {
    return this.get('hasPriorInsurance') as CustomFormControl;
  }

  get priorInsuranceCompany() {
    return this.get('priorInsuranceCompany') as CustomFormControl;
  }

  get isPrimaryResidenceOwned() {
    return this.get('isPrimaryResidenceOwned') as CustomFormControl;
  }

  get priorInsuranceBILimit() {
    return this.get('priorInsuranceBILimit') as CustomFormControl;
  }

  get priorInsuranceExpDate() {
    return this.get('priorInsuranceExpDate') as CustomFormControl;
  }

  get qualifiesForAffinityProgram() {
    return this.get('qualifiesForAffinityProgram') as CustomFormControl;
  }

  get wantsDocumentsViaEmail() {
    return this.get('wantsDocumentsViaEmail') as CustomFormControl;
  }

  get hasAnotherNonVehiclePolicy() {
    return this.get('hasAnotherNonVehiclePolicy') as CustomFormControl;
  }

  get areAllDriversOlderThan15Reported() {
    return this.get('areAllDriversOlderThan15Reported') as CustomFormControl;
  }

  get allDriversOlderThan15ReportedReason() {
    return this.get('allDriversOlderThan15ReportedReason') as CustomFormControl;
  }

  get hasNonIncludedDriver() {
    return this.get('hasNonIncludedDriver') as CustomFormControl;
  }

  get nonIncludedDriverReason() {
    return this.get('nonIncludedDriverReason') as CustomFormControl;
  }

  get hasVehicleInDifferentAddress() {
    return this.get('hasVehicleInDifferentAddress') as CustomFormControl;
  }

  get vehicleInDifferentAddressReason() {
    return this.get('vehicleInDifferentAddressReason') as CustomFormControl;
  }

  get hasVehicleInDifferentRegister() {
    return this.get('hasVehicleInDifferentRegister') as CustomFormControl;
  }

  get vehicleInDifferentRegisterReason() {
    return this.get('vehicleInDifferentRegisterReason') as CustomFormControl;
  }

  get hasModifiedVehicle() {
    return this.get('hasModifiedVehicle') as CustomFormControl;
  }

  get hasDamagedVehicle() {
    return this.get('hasDamagedVehicle') as CustomFormControl;
  }

  get damagedVehicleReason() {
    return this.get('damagedVehicleReason') as CustomFormControl;
  }

  get hasWorkVehicle() {
    return this.get('hasWorkVehicle') as CustomFormControl;
  }

  get workVehicleReason() {
    return this.get('workVehicleReason') as CustomFormControl;
  }

  get areQuestionsConfirmed() {
    return this.get('areQuestionsConfirmed') as CustomFormControl;
  }

  get areAllVehiclesInSameAddress() {
    return this.get('areAllVehiclesInSameAddress') as CustomFormControl;
  }

  get agreesToIntLicenseConfirm() {
    return this.get('agreesToIntLicenseConfirm') as CustomFormControl;
  }

  get hasProhibitedRisks() {
    return this.get('hasProhibitedRisks') as CustomFormControl;
  }

  get areAllFrecuentDriversReported() {
    return this.get('areAllFrecuentDriversReported') as CustomFormControl;
  }

  get areAllVehicleRegisterDriversReported() {
    return this.get('areAllVehicleRegisterDriversReported') as CustomFormControl;
  }

  get hasPreviouslyRentedVehicle() {
    return this.get('hasPreviouslyRentedVehicle') as CustomFormControl;
  }

  get hasExtendedServicesVehicle() {
    return this.get('hasExtendedServicesVehicle') as CustomFormControl;
  }

  get hasCommercialVehicle() {
    return this.get('hasCommercialVehicle') as CustomFormControl;
  }

  get hasExtremeSportsVehicle() {
    return this.get('hasExtremeSportsVehicle') as CustomFormControl;
  }

  get hasHeavyLiftingVehicle() {
    return this.get('hasHeavyLiftingVehicle') as CustomFormControl;
  }

  get hasSalvagedVehicle() {
    return this.get('hasSalvagedVehicle') as CustomFormControl;
  }

  get hasCalifornianData() {
    return this.get('hasCalifornianData') as CustomFormControl;
  }

  get ownsAllVehicles() {
    return this.get('ownsAllVehicles') as CustomFormControl;
  }

  get ownsAllVehiclesReason() {
    return this.get('ownsAllVehiclesReason') as CustomFormControl;
  }

  get hasCommittedFraud() {
    return this.get('hasCommittedFraud') as CustomFormControl;
  }

  get hasAnotherPolicy() {
    return this.get('hasAnotherPolicy') as CustomFormControl;
  }

  get anotherPolicyNumber() {
    return this.get('anotherPolicyNumber') as CustomFormControl;
  }

  get hasUnpaidOrDeclinedPolicy() {
    return this.get('hasUnpaidOrDeclinedPolicy') as CustomFormControl;
  }

  private subscribeToChanges() {
    this.rideShareVehicleReason.enableWithControl(this.hasRideShareVehicle);
    this.anotherVehiclesReason.enableWithControl(this.hasAnotherVehicles);
    this.priorInsuranceCompany.enableWithControl(this.hasPriorInsurance);
    this.priorInsuranceBILimit.enableWithControl(this.hasPriorInsurance);
    this.priorInsuranceExpDate.enableWithControl(this.hasPriorInsurance);
    this.allDriversOlderThan15ReportedReason.enableWithControl(this.areAllDriversOlderThan15Reported);
    this.nonIncludedDriverReason.enableWithControl(this.hasNonIncludedDriver);
    this.vehicleInDifferentAddressReason.enableWithControl(this.hasVehicleInDifferentAddress);
    this.vehicleInDifferentRegisterReason.enableWithControl(this.hasVehicleInDifferentRegister);
    this.damagedVehicleReason.enableWithControl(this.hasDamagedVehicle);
    this.workVehicleReason.enableWithControl(this.hasWorkVehicle);
    this.ownsAllVehiclesReason.enableWithControl(this.ownsAllVehicles);
    this.anotherPolicyNumber.enableWithControl(this.hasAnotherPolicy);
  }
}
