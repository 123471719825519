import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { AURA_PASSWORD_NOTIFICATION } from '../../utils/constants';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.scss'],
})
export class NotificationsButtonComponent {
  open = false;

  constructor(
    private auth: AuthService,
  ) { }

  get notificationsLength() {
    return this.auth.notifications.length;
  }

  showAuraNotification() {
    return this.auth.notifications.includes(AURA_PASSWORD_NOTIFICATION);
  }

  onClick() {
    this.open = !this.open;
    if (this.open) {
      setTimeout(() => {
        const onClick = fromEvent(document, 'click').subscribe(() => {
          onClick.unsubscribe();
          this.open = false;
        });
      });
    }
  }
}
