import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-coverages-modal',
  templateUrl: './coverages-modal.component.html',
  styleUrls: ['./coverages-modal.component.scss'],
})
export class CoveragesModalComponent {
  @Output() close = new EventEmitter();
  current: string;
}
