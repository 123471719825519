import { Component, EventEmitter, Output } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';

const DURATION = 150;

@Component({
  selector: 'listo-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('animate', [
      transition(':enter', [
        style({ opacity: 0 }),
        query('.modal', style({ transform: 'translateY(-20px)' })),
        group([
          animate(DURATION, style({ opacity: 1 })),
          query('.modal', animate(DURATION, style({ transform: 'translateY(0)' }))),
        ]),
      ]),
      transition(':leave', [
        group([
          animate(DURATION, style({ opacity: 0 })),
          query('.modal', animate(DURATION, style({ transform: 'translateY(-20px)' }))),
        ]),
      ]),
    ]),
  ],
})
export class ModalComponent {
  @Output() close = new EventEmitter();
}
