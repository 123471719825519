import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MONTHS } from '../../../utils/constants';
import { FormControlError, Option } from '../../../utils/types';
import FormFieldComponent from '../form-field.component';
import CustomFormControl from '../../auto-rater/CustomFormControl';

const getYearsList = (offset: number = 0) => {
  const currentYear = Number(new Date().getFullYear());
  return Array.from({ length: 120 }, (_, i) => currentYear - i + offset);
};

const getMonthsList = () => MONTHS.map((month, i) => ({
  value: i,
  label: month,
}));

const getLastDayInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const getDaysList = (year, month) => {
  const lastDayInMonth = getLastDayInMonth(year, month);
  return Array.from({ length: lastDayInMonth }, (_, i) => i + 1);
};

@Component({
  selector: 'listo-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends FormFieldComponent implements OnInit {
  @Input() label?: string;
  @Input() required = false;
  @Input() value: Date;
  @Input() control: CustomFormControl;
  @Input() name: string;
  @Input() pending: boolean;
  @Input() disabled?: boolean;
  @Input() yearsOffset = 0;
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() error?: FormControlError;
  @Output() change: EventEmitter<Date> = new EventEmitter();

  years: Option[];
  months: Option[];

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.years = getYearsList(this.yearsOffset);
    this.months = getMonthsList();
    super.listenControlChanges();
  }

  getDays() {
    if (!this.getValue()) {
      return [];
    }
    return getDaysList(this.getYear(), this.getMonth());
  }

  getYear() {
    const value = this.getValue();
    return value && value.getFullYear();
  }

  getMonth() {
    const value = this.getValue();
    return value && value.getMonth();
  }

  getDay() {
    const value = this.getValue();
    return value && value.getDate();
  }

  onYearChange(year) {
    const lastDayInMonth = getLastDayInMonth(year, this.getMonth());
    this.doOnChange({
      year,
      day: Math.min(this.getDay(), lastDayInMonth) || 1,
    });
  }

  onMonthChange(month) {
    const lastDayInMonth = getLastDayInMonth(this.getYear(), month);
    this.doOnChange({
      month,
      day: Math.min(this.getDay(), lastDayInMonth) || 1,
    });
  }

  onDayChange(day) {
    this.doOnChange({ day });
  }

  doOnChange({ year = this.getYear(), month = this.getMonth(), day = this.getDay() }) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    if (year) {
      date.setFullYear(year);
    }
    if (month !== undefined) {
      date.setMonth(month);
    }
    if (day) {
      date.setDate(day);
    }

    this.onChange(date);
  }
}
