import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'listo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() header: string;
  @Input() collapsible: boolean;
  @Input() collapsed = true;
  @Output() toggleCollapseChange = new EventEmitter();

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.toggleCollapseChange.emit();
  }

}
