import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { AutoRaterComponent } from './auto-rater/auto-rater.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChangesGuard implements CanDeactivate<AutoRaterComponent> {

  canDeactivate(
    component: AutoRaterComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();
  }
}
