<div *ngIf="hasError">
  <app-error-rate-messages (driverClick)="changeDriverIndex($event)"></app-error-rate-messages>
</div>


<div *ngIf="!hasError">
  <table class="rate-table">
    <thead>
      <tr>
        <th i18n>Plan</th>
        <th i18n>Term</th>
        <th i18n>Down payment</th>
        <th i18n>Quote</th>
        <th i18n>#Quotes</th>
        <th>
          Total
          <listo-tooltip>
            {{CONSTANTS.AUTO_INSURANCE_TOOLTIPS.total}}
          </listo-tooltip>
        </th>
        <th>
          <ng-container i18n>Annual Total</ng-container>
          <listo-tooltip>
            {{CONSTANTS.AUTO_INSURANCE_TOOLTIPS.annualTotal}}
          </listo-tooltip>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{rate.plan}}
        </td>
        <td>{{rate.term}} <span i18n>months</span></td>
        <td>${{rate.downPayment | currency:'':'':'1.0-0':'en-US'}}</td>
        <td>${{rate.paymentAmount | currency:'':'':'1.0-0':'en-US'}}</td>
        <td>{{rate.numberOfPayments}}</td>
        <td>${{rate.total | currency:'':'':'1.0-0':'en-US'}}</td>
        <td>${{rate.annualTotal | currency:'':'':'1.0-0':'en-US'}}</td>
      </tr>
    </tbody>
  </table>
  <listo-card [header]="CONSTANTS.PAYMENT_DETAILS" class="rates-table-small">
    <ng-container body>
      <div class="detail-row ">
        <span i18n>Plan</span>
        <span>{{rate.plan}}</span>
      </div>
      <div class="detail-row">
        <span i18n>Term</span>
        <span>
          {{rate.term}}
          <ng-container i18n>months</ng-container>
        </span>
      </div>
      <div class="detail-row">
        <span i18n>Down payment</span>
        <span>${{rate.downPayment | currency:'':'':'1.0-0':'en-US'}}</span>
      </div>
      <div class="detail-row">
        <span i18n>Quote</span>
        <span>${{rate.paymentAmount | currency:'':'':'1.0-0':'en-US'}}</span>
      </div>
      <div class="detail-row">
        <span i18n>#Quotes</span>
        <span>{{rate.numberOfPayments}}</span>
      </div>
      <div class="detail-row">
        <span>Total</span>
        <span>${{rate.total | currency:'':'':'1.0-0':'en-US'}}</span>
      </div>
      <div class="detail-row">
        <span i18n>Total annual</span>
        <span>${{rate.annualTotal | currency:'':'':'1.0-0':'en-US'}}</span>
      </div>
    </ng-container>
  </listo-card>
  <div>
    <listo-card [header]="CONSTANTS.DOWN_PAYMENT_BREAKDOWN">
      <ng-container body>
        <div class="detail-row ">
          <span>{{CONSTANTS.DOWN_PAYMENT_TOTAL_PREMIUM}}</span>
          <span>${{rate.totalPolicyPremium | currency:'':'':'1.0-0':'en-US'}}</span>
        </div>
        <div class="detail-row" *ngFor="let fee of getPrimaryFees()">
          <span>{{CONSTANTS.PRIMARY_FEES[fee.key]}}</span>
          <span>${{fee.amount | currency:'':'':'1.0-0':'en-US'}}</span>
        </div>
        <div class="detail-row" *ngIf="getOtherFees()">
          <span>{{CONSTANTS.OTHER_FEES}}</span>
          <span>${{getOtherFees() | currency:'':'':'1.0-0':'en-US'}}</span>
        </div>
        <div class="detail-row big">
          <span>{{CONSTANTS.DOWN_PAYMENT_TOTAL}}</span>
          <span>${{rate.downPayment | currency:'':'':'1.0-0':'en-US'}}</span>
        </div>
      </ng-container>
    </listo-card>
    <listo-button class="action-button" (click)="continueInCarrier.emit()" [disabled]="exitLoading"
      [invertColor]="true">
      {{CONSTANTS.FINISH_IN_CARRIER}}
    </listo-button>
    <listo-button class="action-button" (click)="saveAndExit.emit()" [disabled]="exitLoading">
      {{CONSTANTS.SAVE_AND_EXIT}}
    </listo-button>
  </div>
</div>

<listo-modal *ngIf="fieldExcludedDriversModal" (close)="fieldExcludedDriversModal = false" class="errors-modal">
    <ng-container [ngTemplateOutlet]="driversView"></ng-container>
</listo-modal>

<ng-template #driversView>
    <div class="tab-buttons-container" *ngIf="dataService.drivers.length > 1">
        <button
          *ngFor="let driver of dataService.drivers.controls; let i = index"
          [class.selected]="i === currentDriverIndex"
          (click)="changeDriverIndex(i)"
          class="tab"
          [class.excluded]="driver.get('excluded').value"
        >
          <ng-container [ngSwitch]="getDriverFormStatus(i)">
            <span *ngSwitchCase="'complete'" class="icon icon-ok-circle complete"></span>
            <span *ngSwitchCase="'warning'" class="icon icon-error warning"></span>
            <listo-svg-icon *ngSwitchDefault class="icon default" icon="circle"></listo-svg-icon>
          </ng-container>
          <ng-container>{{getDriverAlias(driver)}}</ng-container>
          {{getDriverDisplayIndex(driver)}}
        </button>
      </div>
      <div class="driver-info">
      <ng-container [ngTemplateOutlet]="requiredLabel"></ng-container>
      <listo-card header="Drivers" i18n-header>
        <ng-container body *ngFor="let driver of dataService.drivers.controls; let i = index">
          <app-driver-form
            *ngIf="currentDriverIndex === i"
            [form]="driver"
            [canDelete]="i !== 0"
            (delete)="deleteDriver(i)"
          ></app-driver-form>
        </ng-container>
      </listo-card>
      <ng-container [ngTemplateOutlet]="saveAndContinueButton"></ng-container>
      </div>
      <!-- <div class="tab-buttons-container" *ngIf="dataService.drivers.controls.length > 1">
        <button
          *ngFor="let driver of dataService.drivers.controls; let i = index"
          [class.selected]="i === currentDriverIndex"
          (click)="changeDriverIndex(i)"
          class="tab"
          [class.excluded]="driver.excluded.value"
        >
          <ng-container [ngSwitch]="getDriverFormStatus(i)">
            <span *ngSwitchCase="'complete'" class="icon icon-ok-circle complete"></span>
            <span *ngSwitchCase="'warning'" class="icon icon-error warning"></span>
            <listo-svg-icon *ngSwitchDefault class="icon default" icon="circle"></listo-svg-icon>
          </ng-container>
          <ng-container *ngIf="driver.excluded.value">{{EXCLUDED_STRING}}</ng-container>
          <ng-container *ngIf="!driver.excluded.value">{{DRIVER_STRING}}</ng-container>
          {{getDriverDisplayIndex(driver)}}
        </button>
      </div> -->
  </ng-template>

<ng-template #requiredLabel>
  <p class="required-label" *ngIf="currentStep !== 'COVERAGES'">
    <ng-container i18n>Required fields</ng-container>
    <img src="../../../assets/images/required.svg">
  </p>
</ng-template>

<ng-template #saveAndContinueButton>
  <listo-button
    class="bottom-button"
    (click)="saveAndContinue()"
    [disabled]="saveQuoteLoading"
  >
    <ng-container *ngIf="saveQuoteLoading" i18n>Saving...</ng-container>
    <ng-container *ngIf="!saveQuoteLoading" i18n>Save and continue</ng-container>
  </listo-button>
</ng-template>
