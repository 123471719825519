import { ChangeDetectorRef, EventEmitter, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormControlError } from '../../utils/types';
import { merge } from 'rxjs';
import CustomFormControl from '../auto-rater/CustomFormControl';

export default abstract class FormFieldComponent {
  abstract control?: CustomFormControl;
  // tslint:disable-next-line:no-inferrable-types
  abstract required?: boolean = false;
  abstract value?: any;
  abstract pending?: boolean;
  abstract disabled?: boolean;
  abstract error?: FormControlError;
  abstract change: EventEmitter<any>;
  tooltip?: string | TemplateRef<any>;
  abstract changeDetectorRef?: ChangeDetectorRef;

  listenControlChanges() {
    if (this.control) {
      merge(
        this.control.valueChanges,
        this.control.statusChanges,
      ).subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  getValue() {
    if (this.control) {
      return this.control.value;
    }
    return this.value;
  }

  isPending() {
    if (!this.control) {
      return this.pending;
    }
    return this.control.pending || this.pending;
  }

  isDisabled() {
    if (!this.control) {
      return this.disabled;
    }
    return this.control.disabled || this.disabled;
  }

  isRequired() {
    if (this.required) {
      return true;
    }
    if (!this.control || !this.control.validator) {
      return false;
    }
    const validation = this.control.validator(<AbstractControl>{});
    return !!(validation && validation.required);
  }

  getError(): FormControlError {
    if (this.error && this.control.touched) {
      return this.error;
    }
    if (!this.control || this.control.untouched || !this.control.errors) {
      return;
    }
    const [[type, value]] = Object.entries(this.control.errors);
    return { type, value };
  }

  getWarning(): FormControlError {
    if (!this.control || this.control.untouched || !this.control.warnings) {
      return;
    }
    const [[type, value]] = Object.entries(this.control.warnings);
    return { type, value };
  }

  onChange(value) {
    if (this.control) {
      this.control.markAsTouched();
      this.control.setValue(value);
      this.control.markAsDirty();
    }
    this.change.emit(value);
  }

  hasStringTooltip() {
    return typeof this.tooltip === 'string';
  }

  hasTemplateTooltip() {
    return this.tooltip instanceof TemplateRef;
  }
}
